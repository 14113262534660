import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {

  constructor(private updates: SwUpdate) {
    // Log that the service has been initialized
    console.log('PwaUpdateService initialized');
  
    // Subscribe to version updates and check when a new version is ready
    this.updates.versionUpdates
      .pipe(filter(event => event.type === 'VERSION_READY'))
      .subscribe(event => {
        console.log('New version is ready:', event);
  
        // Prompt the user about the new version
        if (confirm('A new version of the application is available. Do you want to reload to update?')) {
          console.log('User confirmed the update');
  
          // Perform a hard reload
          window.location.href = window.location.href;
        } else {
          console.log('User declined the update');
        }
      }, error => {
        console.error('Error subscribing to version updates:', error);
      });
  }
}
