<div *ngIf="form">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h2 mat-dialog-title>Permissions for {{data.user.first_name}} {{data.user.last_name}}</h2>
    <p class="manage-access">Manage access, module and alert permission here</p>
    <div mat-dialog-content class="mat-typography" formGroupName="permissions" style="max-height: 70vh;">
      <div class="section" formGroupName="ACCESS">
        <p class="section-heading">Access</p>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="HOME">Home</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="ORDERS">Orders</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="MENU">Menu</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="MESSAGES">Messages</mat-checkbox>
        </div>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="GIFT_CARDS">Gift Cards</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="CAMPAIGNS">Campaigns</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="ATTENDANT">Attendants</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="SKILLS">Skills</mat-checkbox>
        </div>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="OFFERS">Offers</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="ACCOUNT">Account</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="USERS">Users</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="INSIGHTS">Insights</mat-checkbox>
        </div>
      </div>
      <div class="section" formGroupName="ORDERS">
        <p class="section-heading">Order Permissions</p>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="POS">POS</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="CLOSED_ORDERS">Closed Orders</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="REPORTING">Reporting</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="STATEMENTS">Statements</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="MASTER_PIN">Master Pin</mat-checkbox>
        </div>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="INSTANT_DISCOUNT">Instant Discount</mat-checkbox>
        </div>
      </div>
      <div class="section" formGroupName="POS_FUNCTIONS">
        <p class="section-heading">POS Functions</p>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="OPEN_DRAWER">Open Drawer / No Sale</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="VOID">Void</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="REFUND">Refunds</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="CANCEL">Cancel orders</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="UNDO">Undo</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="MANAGE_DRAWER">Manage Drawer</mat-checkbox>
        </div>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="ADJUST">Adjust order</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="MODIFY">Modify order</mat-checkbox>
        </div>
      </div>
      <div class="section" formGroupName="ALERT">
        <p class="section-heading">Alerts</p>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="ORDER_SMS">Order SMS</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="ORDER_EMAIL">Order Email</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="MESSAGE_SMS">Message SMS</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="MESSAGE_EMAIL">Message Email</mat-checkbox>
        </div>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="PRINTER_SMS">Printer SMS</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="PRINTER_EMAIL">Printer Email</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="OPEN_ORDER">Open Order</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="FEEDBACK_SMS">Feedback SMS</mat-checkbox>
        </div>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="FEEDBACK_EMAIL">Feedback Email</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="STATUS_SMS">Status SMS</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="STATUS_EMAIL">Status Email</mat-checkbox>
        </div>
      </div>
      <div class="section" formGroupName="REPORTING">
        <p class="section-heading">Reporting Permissions</p>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="SEARCH">Search</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="PERIOD_REPORT_MASTER">Period Report Master</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="VIEW_PERIOD_REPORT_MASTER">View Period Report</mat-checkbox>
        </div>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="VIEW_CATEGORY_REPORT_MASTER">View Category
            Report</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="VIEW_LABOR_REPORT_MASTER">View Labor Report</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="VIEW_CASH_DRAWER">View Cash Drawer</mat-checkbox>
        </div>
      </div>
      <div class="section" formGroupName="CLOCK">
        <p class="section-heading">Time Clock Permissions</p>
        <div class="row">
          <mat-checkbox class="checkbox" formControlName="EDIT">Edit</mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="DISABLE_AUTO_CLOCKOUT">Disable Auto Clockout</mat-checkbox>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button mat-button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" type="submit" class="apply-btn-colr" cdkFocusInitial>Apply</button>
    </mat-dialog-actions>
  </form>

</div>