import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {OrdersWithPayment} from 'src/app/_models/order';
import {OrderService} from 'src/app/_services/order.service';
import {POSService} from 'src/app/_services/pos-service';
import {POSStreamService} from 'src/app/_services/pos-stream.service';
import {RefreshService} from 'src/app/_services/refresh.service';
import {CardPaymentConsumerChoiceComponent} from '../card-payment-consumer-choice/card-payment-consumer-choice.component';
import {CardPaymentMqttComponent} from '../card-payment-mqtt/card-payment-mqtt.component';
import {CashPaymentComponent} from '../cash-payment/cash-payment.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import { CheckoutDialogComponent } from '../checkout-dialog/checkout-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from 'src/app/constants';
import { ManualCardDialogComponent } from '../manual-card-dialog/manual-card-dialog.component';


@Component({
  selector: 'app-split-payment',
  templateUrl: './split-payment.component.html',
  styleUrls: ['./split-payment.component.scss']
})
export class SplitPaymentComponent implements OnInit, OnDestroy {
  form;
  due;
  numSplits;
  showSplits = false;
  orderHash;
  paidSoFar = 0;
  orderWithPayment: OrdersWithPayment;
  terminalId;
  isComplete = false;
  isLoading = false;
  store;

  private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  debounceTime = 700;
  checks = [];
  itemChecks = [];
  splitPayload: any;
  displayedColumns: string[] = ['quantity', 'item', 'itemTotal'];
  split_by_items: any;
  private destroySubject: Subject<void> = new Subject();
  split_payment: boolean = false;
  saveSplitENter: any;
  isMobile: any;
  pizzaOptions$: Map<string, string> = Constants.pizzaOptionsFlag;
  pizzaOptions: Map<string, string> = Constants.pizzaOptions;


  constructor(private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SplitPaymentComponent>,
    public dialog: MatDialog,
    private orderService: OrderService,
    private posService: POSService,
    private refreshService: RefreshService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data,
    private posStreamService: POSStreamService) {
    console.log(data);
    this.terminalId = localStorage.getItem("selectedTerminal")
    this.orderWithPayment = this.data.orderWithPayment;
    this.store = this.orderWithPayment.store;
    this.orderHash = this.data.orderWithPayment.bot_order.order_hash;
    this.form = this.formBuilder.group({
      num_splits: 1,
      splits: this.formBuilder.array([])
    });
    this.getDue();
  }


  @HostListener('document:keydown.enter', ['$event'])
  handleEnterkey(event: KeyboardEvent) {
    if (this.saveSplitENter) {
      event.preventDefault();
      event.stopPropagation();
      this.calculateSplits(this.saveSplitENter);
    }
  }

  ngOnInit() {
    console.log(this.data);
    this.isMobile = window.innerWidth <= 470;

    for (let i = 0; i < this.data.orderWithPayment.split_items.length; i++) {
      const itemANdChekcs = {
        menu_item: this.orderWithPayment.split_items[i],
        split_checks: []
      }
      this.itemChecks.push(itemANdChekcs);
    }
  }

  ngOnDestroy() { }

  checkSelect(split_check, menu_item) {
    console.log('Split checkS Menu Item', split_check, menu_item);

    for (let i = 0; i < this.split_by_items.length; i++) {
      if (this.split_by_items[i].menu_item.menu_item && menu_item.menu_item.menu_item) {
        if (this.split_by_items[i].menu_item.menu_item.id == menu_item.menu_item.menu_item.id) {
          for (let j = 0; j < this.split_by_items[i].split_checks.length; j++) {
            if (this.split_by_items[i].split_checks[j].id == split_check.id) {
              if (!this.split_by_items[i].split_checks[j].is_checked) {
                this.split_by_items[i].split_checks[j].is_checked = true;
              } else {
                this.split_by_items[i].split_checks[j].is_checked = false;
              }
            }
          }
        }
      } else {
        if (this.split_by_items[i].menu_item.id == menu_item.menu_item.id) {
          for (let j = 0; j < this.split_by_items[i].split_checks.length; j++) {
            if (this.split_by_items[i].split_checks[j].id == split_check.id) {
              if (!this.split_by_items[i].split_checks[j].is_checked) {
                this.split_by_items[i].split_checks[j].is_checked = true;
              } else {
                this.split_by_items[i].split_checks[j].is_checked = false;
              }
            }
          }
        }
      }
    }
    this.split_payment = false;
    this.getSplits(1);
  }

  showCheckSelected(e_checks, m_item) {
    if (this.split_by_items) {
      for (let i = 0; i < this.split_by_items.length; i++) {
        if (this.split_by_items[i].menu_item.menu_item && m_item.menu_item.menu_item) {
          if (this.split_by_items[i].menu_item.menu_item.id == m_item.menu_item.menu_item.id) {
            const found = this.split_by_items[i].split_checks.find((element) => element.id == e_checks.id);
            if (found) {
              if (found.is_checked) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
        } else {
          if (this.split_by_items[i].menu_item.id == m_item.menu_item.id) {
            const found = this.split_by_items[i].split_checks.find((element) => element.id == e_checks.id);
            if (found) {
              if (found.is_checked) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
        }
      }
    }
  }

  getSplits(e) {
    this.isLoading = true;
    if (e == 1) {
      this.form = this.formBuilder.group({
        num_splits: this.numSplits,
        splits: this.formBuilder.array([])
      });
      let payload = {
        num_splits: this.numSplits,
        due: this.due,
        split_by_item: true,
        data: this.split_by_items
      }
      this.split_by_items = null;
      this.orderService.getSplits(this.orderHash, payload).subscribe(data => {
        const split_by_item = data.split_by_items.map(item => {
          return {
              check_value: item.split_checks.some(check =>
                  check.possplitpayment_details && check.possplitpayment_details.is_complete
              ),
              menu_item: item.menu_item,
              split_checks: item.split_checks
          };
        });
        this.split_by_items = split_by_item;
        data.split.forEach(row => {
          let formRow = this.getInitial(row);
          this.addSplit(formRow);
        });
        this.form.controls.num_splits.disable();
        this.showSplits = true;
        this.isLoading = false;
      });
    } else {
      this.orderService.resetSplits(this.orderHash).subscribe(data => {
        this.form = this.formBuilder.group({
          num_splits: this.numSplits,
          splits: this.formBuilder.array([])
        });

        let payload = {
          num_splits: this.numSplits,
          due: this.due,
          split_by_item: false
        }

        this.orderService.getSplits(this.orderHash, payload).subscribe(data => {
          // let completedSplits = data.filter(d => d.is_complete).length
          // this.numSplits = this.numSplits - completedSplits;
          console.log('Get SPlits RESPONSE CHNAGED', data);
          const split_by_item = data.split_by_items.map(item => {
            return {
                check_value: item.split_checks.some(check =>
                    check.possplitpayment_details && check.possplitpayment_details.is_complete && check.is_checked
                ),
                menu_item: item.menu_item,
                split_checks: item.split_checks
            };
          });
          this.split_by_items = split_by_item;
          data.split.forEach(row => {
            let formRow = this.getInitial(row);
            this.addSplit(formRow);
          });
          this.form.controls.num_splits.disable();
          this.showSplits = true;
          this.isLoading = false;
        });
      });
    }
  }

  reset() {
    this.isLoading = true;
    this.orderService.resetSplits(this.orderHash).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.form = this.formBuilder.group({
        num_splits: this.numSplits,
        splits: this.formBuilder.array([])
      });
      this.isLoading = false;
      this.showSplits = false;
      this.itemChecks = null;
    });
  }

  getDue() {
    this.orderService.getDue(this.orderHash).pipe(takeUntil(this.destroySubject)).subscribe((due: any) => {
      this.paidSoFar = due.paid_so_far;
      this.due = due.due;
      this.isComplete = due.is_complete;
    });
  }

  onInputFocus(split) {
    this.saveSplitENter = split;
  }

  calculateSplits(split) {
    if (split.value.split_amount != '' || split.value.split_amount > 0) {
      this.isLoading = true;
      this.orderService.updateSplit(this.orderHash, split.value).pipe(takeUntil(this.destroySubject)).subscribe(data => {
        split.controls.is_dirty.value = true;
        this.saveSplitENter = null;
        this.getSplits(1);
      });
    }
  }

  submit() {
    const payload = {
      ...this.form.value,
      cashier_id: localStorage.getItem('posLoggedUser'),
      terminal_name: localStorage.getItem('selectedTerminalName'),
      pin_user: localStorage.getItem('pinUser')
    };
    this.orderService.closeSplitPayment(this.orderHash, payload).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      if (data.is_complete) {
        this.dialogRef.close(data.is_complete);
        this.refreshService.refreshModule('OPEN_TAB');
        this.orderService.orderDoneSubject.next();
      }
    });
  }

  getNumSplits() {
    return this.numSplits;
  }

  getSplitValue() {
    return this.due / this.getNumSplits();
  }

  getInitial(data) {
    return this.formBuilder.group({
      id: [data.id, Validators.required],
      bot_order_id: [data.bot_order_id],
      split_amount: [{ value: data.split_amount || this.getSplitValue(), disabled: data.is_complete }],
      transaction_id: [data.transaction_id],
      is_complete: [data.is_complete],
      payment_type: [{ value: data.payment_type, disabled: data.is_complete }, Validators.required],
      is_custom: [data.is_custom],
      is_dirty: [false],
      history_id: [data.history_id]
    });
  }

  toggleDirty(split) {
    split.controls.is_dirty.value = true;
  }

  addSplit(row) {
    (this.form.controls.splits as UntypedFormArray).push(row);
    this.getDue();
  }

  removeSplits(index) {
    (this.form.controls.splits as UntypedFormArray).removeAt(index);
    this.getDue();
  }

  process(fg) {
    let payload = fg.value;
    switch (payload.payment_type) {
      case 'CASH':
        this.checkOutCashOther(fg);
        break;
      case 'GIFT':
        this.openCashDialog(fg, payload.split_amount, 0, payload.payment_type);
        break;
      case 'CREDIT':
        this.openCardDialog(fg, payload.split_amount, 'card');
        break;
      case 'KEYED':
        this.openCashDialog(fg, payload.split_amount, 0, payload.payment_type);
        break;
      case 'OTHER':
        this.checkOutCashOther(fg);
        break;
      case 'MANUAL':
      this.openCardDialog(fg, payload.split_amount, 'manual');
      break;
    }
  }

  checkOutCashOther(formValue) {
    let payload = formValue.value;
    const dialogRef = this.dialog.open(CheckoutDialogComponent, {
      width: '630px',
      maxHeight: '95vh',
      maxWidth: this.isMobile ? '100vw' : '80vw',
      disableClose: true,
      data: {
        orderWithPayment: this.orderWithPayment,
        terminalId: this.terminalId,
        total: this.orderWithPayment.payment.total,
        cashTotal: payload.split_amount,
        subTotal: this.orderWithPayment.payment.sub_total,
        openFromSplit: true,
        payment_type: payload.payment_type
      }
    });
    dialogRef.afterClosed().subscribe(isComplete => {
      if (isComplete) {
        this.openCashDialog(formValue, isComplete.cashDueTotal, isComplete.result, payload.payment_type);
      }
    });
  }

  openCardDialog(fg, split_amount, cd) {
    console.log(split_amount);
    const payload = {
      cashTotal: this.data.cashTotal,
      orderWithPayment: this.orderWithPayment,
      subTotal: split_amount,
      toCharge: split_amount,
      amount: split_amount,
      total: split_amount,
      is_split: true,
      split_id: fg.controls.id.value,
      payment_type: 'SPLIT',
      order_id: this.orderWithPayment.bot_order.order_hash,
      cashier_id: this.data.cashier_id,
      result:0,
      cashDueTotal:0

    };

    let dialogRef = null;

    if (cd == 'card') {
      if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
        payload.payment_type = 'SPLIT';
        payload.order_id = `${this.orderWithPayment.bot_order.order_hash}-${fg.controls.id.value}`;
        dialogRef = this.dialog.open(CardPaymentMqttComponent, {
          width: '630px', data: payload, disableClose: true
        });
      } else {
        dialogRef = this.dialog.open(CardPaymentConsumerChoiceComponent, {
          data: payload, disableClose: true, maxWidth: '100vw', maxHeight: '100vh', height: '100%', width: '100%',
        });
      }
    } else if (cd == 'manual') {
      dialogRef = this.dialog.open(ManualCardDialogComponent, {
        data: payload, disableClose: true, maxWidth: '100vw', maxHeight: '100vh', height: '100%', width: '100%',
      });
    }


    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      console.log(result);
      if (result) {
        fg.controls.payment_type.disable();
        fg.controls.split_amount.disable();
        fg.controls.is_complete.value = true;
        this.getDue();
        this.getSplits(1);
      }
    });
  }

  openCashDialog(fg, split_amount, due_amount, pt) {
    const payload = {
      cashTotal: split_amount,
      orderWithPayment: this.orderWithPayment,
      subTotal: split_amount,
      total: split_amount,
      is_split: true,
      split_id: fg.controls.id.value,
      split_type: pt
    };
    console.log(payload);


    if (split_amount === '0.00') {
      return;
    } else {
      const cashData = {
        cashDueTotal: split_amount,
        result: due_amount,
        totalDueAmount:pt == 'GIFT' || pt == 'KEYED' ? this.due :0
      }

      const dialogRef = this.dialog.open(CashPaymentComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        data: { ...payload, ...cashData },
        disableClose: true
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        fg.controls.payment_type.disable();
        fg.controls.split_amount.disable();
        fg.controls.is_complete.value = true;
        this.getSplits(1);
        this.getDue();
      });
    }
  }

  voidCash(split) {
    this.orderService.voidTransaction(this.orderHash, {id: split.value.id}).pipe(takeUntil(this.destroySubject)).subscribe(d => {
      split.controls.payment_type.enable();
      split.controls.split_amount.enable();
      split.controls.is_complete.value = false;
      this.getDue();
      this.getSplits(1);

    });
  }

  refund(split) {
    console.log('SPLIT DETAILS',split);
    const pinUser = localStorage.getItem('pinUser')
    this.isLoading = true;
    const keyData = {
      history_id: split.value.history_id,
      order_id: this.orderHash,
      payment_type: "FULL_REFUND",
      amount: split.getRawValue().split_amount,
      note: "Voided amount",
      user: pinUser
    };
    this.orderService.processPostPayment(this.store.id, keyData).subscribe(data => {
        this.isLoading = false;
        split.controls.payment_type.enable();
        split.controls.split_amount.enable();
        split.controls.is_complete.value = false;
        this.getSplits(1);
        this.getDue();
    }, (error) => {
        this.isLoading = false;

    });
  }

  resetSingleSplit(split) {
    this.orderService.resetSingleSplit(this.orderHash, split.value).pipe(takeUntil(this.destroySubject)).subscribe(d => {
      this.getSplits(1);
    });
  }

}
