import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from 'src/app/_services/order.service';
import {OrdersWithPayment} from 'src/app/_models/order';
import {CustomTipComponent} from './custom-tip/custom-tip.component';
import {OrderHelpers} from 'src/app/_helpers/helpers';
import {CardPaymentConsumerChoiceComponent} from '../card-payment-consumer-choice/card-payment-consumer-choice.component';
import {Store} from '../../_models/store';
import {CardPaymentMqttComponent} from '../card-payment-mqtt/card-payment-mqtt.component';
import {FrontFacingMqttService} from 'src/app/_services/front-facing-mqtt.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-tip-dialog',
  templateUrl: './tip-dialog.component.html',
  styleUrls: ['./tip-dialog.component.scss']
})
export class TipDialogComponent implements OnInit, OnDestroy {

  orderHash: string;
  order: OrdersWithPayment;

  @Input() refresh = new EventEmitter<any>();
  @Input() store: Store;

  tipPercent = 0;
  tipAmount = 0;
  terminalId = this.data.terminalId;
  goBackSub: Subscription;
  tipSub: Subscription;
  private destroySubject: Subject<void> = new Subject();
  taxDueTotal: number;
  tipTenPercent: number;
  tipFifteenPercent: number;
  tipTwentyPercent: number;
  tips: any[];
  paymentStartSub: Subscription;
  isMobile: boolean;

  constructor(public dialogRef: MatDialogRef<TipDialogComponent>,
              public dialog: MatDialog,
              private orderService: OrderService,
              private ffmq: FrontFacingMqttService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private chRef: ChangeDetectorRef) {
    this.order = data.orderWithPayment;
    this.taxDueTotal = this.data.orderWithPayment.getDueTotal();
    this.store = this.order.store;
    this.orderHash = this.order.bot_order.order_hash;
    dialogRef.disableClose = true;

    this.tipTenPercent = this.round((this.taxDueTotal * 10) / 100);
    this.tipFifteenPercent = this.round((this.taxDueTotal * 15) / 100);
    this.tipTwentyPercent = this.round((this.taxDueTotal * 20) / 100);

    if (this.store.getFeatureFlag('dynamic_tip_enabled') && (this.taxDueTotal <= this.store.store_data.tip_value_min_order)) {
      this.tips = [
        {name: 'Custom', value: this.store.store_data.tip_values[0], amount: this.store.store_data.tip_values[0]},
        {name: 'Custom', value: this.store.store_data.tip_values[1], amount: this.store.store_data.tip_values[1]},
        {name: 'Custom', value: this.store.store_data.tip_values[2], amount: this.store.store_data.tip_values[2]},
        {name: 'Custom', value: 'custom'},
        {name: 'No Tip', value: 0, amount: 0}
      ];
    } else {
      this.tips = [
        {name: '10%', value: 10, amount: this.tipTenPercent},
        {name: '15%', value: 15, amount: this.tipFifteenPercent},
        {name: '20%', value: 20, amount: this.tipTwentyPercent},
        {name: 'Custom', value: 'custom'},
        {name: 'No Tip', value: 0, amount: 0}
      ];
    }
  }

  round(num) {
    const p = Math.pow(10, 2);
    return Math.round(num * p) / p;
  }


  listenMQTT(){
    this.tipSub =  this.ffmq.listenFromFrontFacing().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      console.log(data);
      if (data.select === 'TIP') {
        console.log(data);
        if (data.tip.value === 'custom') {
          const y: number = +data.tip.amount;
          this.tipAmount = y;
        }else{
          this.tipPercent = data.tip.value;
          this.tipAmount = data.tip.amount;
        }
        this.chRef.detectChanges();
        this.refreshOrder();
      }});

    this.goBackSub = this.ffmq.goBackMqttSub().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (JSON.parse(data.payload.toString()).goBack === true) {
        this.goBack(0);
      }});

    this.paymentStartSub = this.ffmq.getPaymentStart().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      const parsedData = this.ffmq.decodeAndParse(data.payload);
      if (parsedData.onpay == true ) {
        this.onPay(1);
      }
    });
  }

  ngOnInit() {
    // this.tipAmount = (this.data.tipAmount ? this.data.tipAmount : 0);
    this.isMobile = window.innerWidth <= 767;
    this.tipAmount = this.data.tipAmount;
    this.tipPercent = this.data.tipPercent;
    this.taxDueTotal = this.data.orderWithPayment.payment.due_total;
    this.store =  this.data.orderWithPayment.store;
    this.listenMQTT();
  }

  calculateTip(change: any) {
    console.log(change);
    if (change.name === 'Custom') {
      this.calculateCustomTip(change);
    } else  {
      this.calculatePercentTip(change);
    }
  }

  calculatePercentTip(change){
    console.log(change);
    const keyData = {select: 'TIP',  tip: change,};
    this.ffmq.publishToFrontFacing(keyData);
    if (change.name === 'No Tip') {
      this.tipAmount = 0
    }
    this.tipPercent = change.value;
    this.tipAmount = change.amount;
    this.updateTipPercentInBackend(change.value);
  }

  calculateCustomTip(selectedTip){
    if (selectedTip.value == 'custom') {
      this.tipPercent = selectedTip.value;
      const isMobile = window.innerWidth <= 470;
      const dialogRef = this.dialog.open(CustomTipComponent, {
        width: isMobile ?'100vw' : '630px',
        height: isMobile ? 'auto' : '290px',
        maxWidth: isMobile ? '100vw' : '',
        // width: '630px', height: '290px',
        data: this.data, panelClass: 'custom-tip-mobile',
        disableClose: true, autoFocus: true
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        console.log(result);
        if(result){
          this.tipAmount = result
          const a = { name: 'custom', value: 'custom', amount: result }
          const keyData = {select: 'TIP',  tip: a};
          this.ffmq.publishToFrontFacing(keyData);
          this.updateCustomTip(this.tipAmount);
          // this.refreshOrder();
        }
      });
    } else {
      this.tipAmount = selectedTip.amount;
      this.tipPercent = selectedTip.value;
      const keyData = {select: 'TIP',  tip: selectedTip,};
      this.ffmq.publishToFrontFacing(keyData);
      this.updateCustomTip(this.tipAmount);
      // this.refreshOrder();
    }
  }

  updateTipPercentInBackend(tipPercent:number){
    this.orderService.updateTip(this.orderHash, tipPercent, 'tip-dialog-updatetippercent').pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.refresh.emit(true);
      this.tipPercent = data.tip_percent;
      this.refreshOrder();
      this.chRef.detectChanges();
    });
  }

  updateCustomTip(tipAmount: number){
    console.log(tipAmount);
    this.orderService.updateCustomTip(this.orderHash, tipAmount, 'tip-dialog-updatetip-custom').pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.refresh.emit(true);
      this.tipAmount = tipAmount;
      this.refreshOrder();
      this.chRef.detectChanges();
    });
  }


  getTipAmount(){
    return (+this.tipAmount || 0) + (+this.order.getTotal() || 0) ;
  }

  goBack(e) {
    this.order.payment.due_total = this.order.payment.due_total - this.order.payment.tip;
    this.order.updateTipCustom(0);
    this.dialog.closeAll();
    this.refreshOrder();
    if (e == 1) {
      this.ffmq.goBackMqttPub();
    }
    this.ffmq.resetTip();
  }

  onPay(e) {
    const isMobile = window.innerWidth <= 470;
    if (e == 0) {
      this.ffmq.publishStartPayment();
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.closeAll();

    let dialogRef = null;
    this.tipAmount = null;
    this.tipPercent = null;
    if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
      this.data.amount = this.order.payment.due_total;
      this.data.action = 'TIP';
      dialogRef = this.dialog.open(CardPaymentMqttComponent, {
        // width: '630px',
        width: isMobile ?'100vw' : '630px',
        height: isMobile ? '50%' : '600px',
        maxWidth: isMobile ? '100vw' : '',
         data: this.data, disableClose: true
      });
    } else {
      this.orderService.getOrder(this.orderHash).pipe(takeUntil(this.destroySubject))
        .subscribe((order) => {
          this.data.toCharge = order.payment.due_total;
          dialogRef = this.dialog.open(CardPaymentConsumerChoiceComponent, {
            maxWidth: '100vw', maxHeight: '100vh', height: '100%', width: '100%',
            data: this.data, disableClose: true
          });
        });
    }


    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      this.refreshOrder();
      this.chRef.detectChanges();
    });
  }

  refreshOrder() {
    this.orderService.getOrder(this.orderHash).pipe(takeUntil(this.destroySubject))
      .subscribe((result) => {
        this.order = OrderHelpers.parseOrderwithPayment(result);
        this.store = this.order.store;
      });
  }

  ngOnDestroy() {
    this.destroySubject.unsubscribe();
    if (this.goBackSub) {
      this.goBackSub.unsubscribe();
    }
    if (this.paymentStartSub) {
      this.paymentStartSub.unsubscribe();
    }
    if (this.tipSub) {
      this.tipSub.unsubscribe();
    }
    this.tipPercent = null;
    this.tipAmount = null;
  }
}
