<div class="flex flex-row justify-between">
  <button class="arrow-btn" mat-icon-button (click)="refreshOrder()">
    <mat-icon>refresh</mat-icon>
  </button>
  <button mat-icon-button (click)="cancelOrderConfirmation()" *ngIf="!isOpenTab">
    <mat-icon>delete_forever</mat-icon>
  </button>
</div>

<div class="clear"></div>

<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<div *ngIf="orderWithPayment">
  <div *ngIf="orderWithPayment.bot_order">
    <div *ngIf="orderWithPayment.bot_order.occasion != ''; then summaryBlock else messageBlock"></div>
  </div>
</div>
<div *ngIf="!orderWithPayment && !orderHash; then messageBlock"></div>

<ng-template #messageBlock>
  <p class="message center">
    Select an occasion to start an order.
  </p>
</ng-template>

<ng-template #summaryBlock>
  <div class="">
    <form [formGroup]="form" (submit)="openDialog()">
      <div class="w-full h-10 p-3 border-b border-gray-200 text-center">
        Order {{orderWithPayment?.bot_order.order_counter}}
      </div>
      <div class="user-info-new w-full flex flex-col">
        <input matInput placeholder="Name*" autofocus [errorStateMatcher]="errorMatcher"
               class="w-full h-10 p-3 border-b border-gray-200 text-center"
               (keyup)="updateOrderName($event)" formControlName="orderName" #orderName>
        <input #phone appPhoneMask matInput autofocus formControlName="phoneNumber" placeholder="Add phone number"
               class="w-full h-10 p-3 border-b border-gray-200 text-center"
               (input)="updatePhoneNumber($event.target.value, orderName.value);" [errorStateMatcher]="errorMatcher"
               [value]="orderWithPayment?.getFormattedPhoneNumber()" type="tel" #myInput (keyup.enter)="onEnterKeyPressed()">
      </div>

      <div class="w-full grid grid-cols-2 gap-1 justify-between px-4 py-2">
        <div class="l text-left">
          <p class="text-base">{{occasionDetail?.occasion | titlecase}}</p>
          <p class="text-base"
             *ngIf="orderWithPayment?.bot_order.occasion === 'TABLESIDE' || orderWithPayment?.bot_order.occasion === 'DINE-IN'">
            {{ occasionDetail?.dine_in_table_number }}
          </p>
          <p class="text-base"
             *ngIf="orderWithPayment?.bot_order.occasion === 'CURBSIDE'">
            {{occasionDetail?.curbside_vehicle_id}}
          </p>
          <p class="text-base"
             *ngIf="orderWithPayment?.bot_order.occasion === 'DELIVERY'">
            {{occasionDetail?.delivery_address}}
          </p>
        </div>
        <div class="r text-right">
          <p class="text-base">{{orderWithPayment?.bot_order.time_raw}}</p>
          <p class="text-base">{{occasionDetail?.occasion_schedule | date: 'MMM d, y'}}</p>
          <p class="text-base">{{occasionDetail?.occasion_schedule | date: 'hh:mm a'}}</p>
        </div>
      </div>
      <mat-divider class="w-full col-span-2 my-3"></mat-divider>

      <div class="px-4 py-2 w-full">
        <app-order-summary-item-list
          (quantityChange)="refreshOrder()"
          [orderHash]="orderHash"
          [orderWithPayment]="orderWithPayment"
          [isAdmin]="true"
          [offers]="offers"></app-order-summary-item-list>
      </div>
      <mat-divider class="w-full"></mat-divider>

      <div class="px-4 py-2 w-full">
        <app-payment-breakdown [orderWithPayment]="orderWithPayment"></app-payment-breakdown>
      </div>
      <mat-divider class="w-full"></mat-divider>

      <div class="px-2 py-4 xs:!pr-[0.8rem] xs:!pl-0" *ngIf="orderWithPayment?.store.has_card_payment && orderWithPayment?.store.toggle_tip">
        <app-order-summary-tip
          *ngIf="orderWithPayment?.store.has_card_payment && orderWithPayment?.store.toggle_tip"
          style="position: relative; left: 6px;"
          [orderWithPayment]="orderWithPayment"
          [orderHash]="orderHash"
          (refresh)="refreshOrder()"></app-order-summary-tip>
      </div>
      <mat-divider class="w-full py-2"></mat-divider>

      <div class="px-4 py-2 xs:!p-0 w-full">
        <mat-form-field class="w-full" subscriptSizing="dynamic">
          <mat-label>Special Request</mat-label>
          <textarea formControlName="specialRequest"
                    matInput rows=3 placeholder="Special requests..." (keyup)="updateSpecialRequest($event)"></textarea>
        </mat-form-field>
      </div>

      <div class="order-main-data order-summary">
        <div class="promo-section" *ngIf="offers?.length > 0">
          <mat-form-field appearance="fill" class="w-full" *ngIf="offers?.length > 0">
            <mat-label>Choose an offer</mat-label>
            <mat-select (selectionChange)="applyPromoCode($event.value)">
              <mat-option value="REMOVE">Remove</mat-option>
              <mat-option [value]="offer.promo_code" *ngFor="let offer of offers">{{offer.promo_code}}
                - {{offer.offer_description}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="promoCode">{{promoCode.offer_description}}</mat-hint>
          </mat-form-field>
          <div style="position: relative; width: 100%;" *ngIf="promoCodeApplied && !conditionsMet">
            <div class="triangle"></div>
            <div class="unverified-notification-tip">
              <span class="unverified-notification-text">Promo conditions have not been met.
                Please see promo details and update basket or remove code to submit order as is!</span>
            </div>
          </div>
        </div>

        <div class="service-charge" *ngIf="!store.enable_pos_service_charge_percent">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Add {{orderWithPayment?.store.pos_service_charge_title}}</mat-label>
            <mat-select (selectionChange)="applyServiceCharge($event.value)">
              <mat-option [value]="opt" *ngFor="let opt of serviceChargeOptions">{{opt.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="isOpenTab; then openTabBlock else posBlock"></div>
  <ng-template #openTabBlock>
    <div class="checkout-button-50">
      <button mat-button type="button" (click)="edit()" class="pull-left add">
        <span fxLayout="row" fxLayoutAlign="space-between center">
          <span class="add-size"></span>
          <span class="add-size" fxFlexAlign="center">Edit</span>
          <span class="add-size"></span>
        </span>
      </button>

      <button mat-button type="button" (click)="openDialog()" [disabled]="orderWithPayment?.bot_order.total == 0" class="pull-right">
        <span fxLayout="row" fxLayoutAlign="space-between center">
          <span class="add-size"></span>
          <span class="add-size" fxFlexAlign="center">Checkout</span>
          <span class="add-size"></span>
        </span>
      </button>
      <div class="clear"></div>
    </div>
  </ng-template>

  <ng-template #posBlock>
    <div class="grid grid-cols-2 gap-0 fixed bottom-0 xs:!w-[320px] w-[360px] xs:!w-[94%] 2xl:w-[400px]" style="z-index: 999999;" *ngIf="store.enable_pos_pay_later">
      <button type="button"
              (click)="payLater()"
              [disabled]="orderWithPayment?.bot_order.total == 0"
              class="bg-gray-400 h-20 text-white w-full text-2xl font-medium">

        Pay Later
      </button>
      <button type="button"
              (click)="openDialog()"
              [disabled]="isLoading || orderWithPayment?.bot_order.total == 0"
              class="bg-[#008000] h-20 text-white w-full text-2xl font-medium border-r border-[#008000]">
        Checkout
      </button>
    </div>

    <div class="grid grid-cols-1 gap-0 fixed bottom-0 w-[400px]" *ngIf="!store.enable_pos_pay_later">
      <button type="button"
              (click)="openDialog()"
              [disabled]="isLoading || orderWithPayment?.bot_order.total == 0"
              class="bg-[#008000] h-20 text-white w-full text-2xl font-medium border-r border-[#008000]">
        <span class="flex flex-row">
          <span>&nbsp;</span>
          <span class="flex-1 text-center">Checkout</span>
          <span class="flex-1 text-center">{{ orderWithPayment?.payment.total | currency }}</span>
        </span>
      </button>
    </div>
  </ng-template>
</ng-template>
