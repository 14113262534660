import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FloorPlanService } from '../_services/floor-plan.service';
import { AlertsMessagesComponent } from '../alerts-messages/alerts-messages.component';
import { MatDialog } from '@angular/material/dialog';
import { AddNewFloorPlanComponent } from './add-new-floor-plan/add-new-floor-plan.component';
import { FloorPlanViewComponent } from './floor-plan-view/floor-plan-view.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface PeriodicElement {
  id: any
  name: string;
  store: any;
  width: any;
  height: any;
  tables: any;
}
@Component({
  selector: 'app-floor-plan',
  templateUrl: './floor-plan.component.html',
  styleUrls: ['./floor-plan.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class FloorPlanComponent implements OnInit {

  storeId: any;
  feedbackList: any = [];
  totalFeedbacks: number;
  columnsToDisplay = ['id', 'name', 'width', 'height', 'button'];
  dataSource: MatTableDataSource<PeriodicElement>;
  tableColumns = ['id', 'table_name', 'seating_capacity','shape', 'width', 'height', 'created_at', 'action'];
  objectDatasource: any;
  objectColumns = ['id', 'table_name', 'width', 'height', 'created_at', 'action'];
  search;
  startDate;
  endDate;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  formGroup: FormGroup;
  private destroySubject: Subject<void> = new Subject();

  constructor(
    public floorPlanService: FloorPlanService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.floorPlanLists();
  }

  floorPlanLists() {
    this.floorPlanService.getFlooPlanlist(this.storeId).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource<PeriodicElement>(data);
      }
    })
  }

  deleteFloorPlan(element) {
    this.alertConfirmation(element, 'delete');
  }

  newFloorAdded() {
    let dialogRef = this.dialog.open(AddNewFloorPlanComponent, {
      disableClose: true,
      width: '570px',
      height: '400px',
      data: {
        from: 'add-floor'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.floorPlanLists();
      }
    });
  }

  createFloorPlan(ele) {
    let dialogRef = this.dialog.open(FloorPlanViewComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      maxHeight: '100vh',
      maxWidth: '100vw',
      data: {
        floor: ele
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.floorPlanLists();
      }
    });
  }

  deleteTable(element) {
    this.alertConfirmation(element, 'delete-table');
  }

  editFloorPlan(row) {
    const dialogData = {
      from: 'edit-floor',
      floor: row
    }
    this.editFloorTableObject(dialogData);
  }

  editTable(element) {
    const dialogData = {
      from: 'edit-table',
      f_id: element.floor_plan,
      table: element
    }
    this.editFloorTableObject(dialogData);
  }

  deleteObject(element) {
    this.alertConfirmation(element, 'delete-object');
  }

  editObject(element) {
    const dialogData = {
      from: 'edit-object',
      f_id: element.floor_plan,
      object: element
    }
    this.editFloorTableObject(dialogData);

  }

  onRowClicked(row: any) {}

  editFloorTableObject(dialogData) {
    const isMobile = window.innerWidth <= 599;
    let dialogHeight;
    if (dialogData.from === 'edit-table') {
      dialogHeight = '550px';
    } else if (dialogData.from === 'edit-object') {
      dialogHeight = '400px';
    } else if (dialogData.from === 'add-object') {
      dialogHeight = '240px';
    } else {
      dialogHeight = '410px';
    }
    let dialogRef = this.dialog.open(AddNewFloorPlanComponent, {
      disableClose: true,
      width: isMobile ? '100vw' : '570px',
      maxWidth: '100vw',
      height: dialogHeight,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.floorPlanLists();
      }
    });
  }

  copy(element) {
    this.floorPlanService.createTableCopy(element).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      if (data) {
        this.snackBar.open('Table copied successfully!', 'OK', { duration: 2000 });
        this.floorPlanLists();
      }
    });
  }

  alertConfirmation(element, key) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure you want to delete?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (key == 'delete') {
          this.floorPlanService.deleteFlooPlan(element.id).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
            this.ngOnInit()
          })
        } else if (key == 'delete-table') {
          this.floorPlanService.deleteTable(element.id).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
            this.floorPlanLists();
          })
        } else if (key == 'delete-object') {
          this.floorPlanService.deleteObject(element.id).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
            this.floorPlanLists();
          })
        }
      }
    });
  }
}
