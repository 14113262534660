import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OrdersWithPayment} from 'src/app/_models/order';
import {OrderService} from 'src/app/_services/order.service';
import {StoreService} from 'src/app/_services/store.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-order-add-misc-items',
  templateUrl: './order-add-misc-items.component.html',
  styleUrls: ['./order-add-misc-items.component.scss']
})
export class OrderAddMiscItemsComponent implements OnInit, OnChanges, OnDestroy {

  expandedRowId;

  @Input() order: OrdersWithPayment;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  private destroySubject: Subject<void> = new Subject();

  selectedOrderMiscFromGroup;

  constructor(private orderService: OrderService,
              private storeService: StoreService
    ) { }

  ngOnInit() {
    this.selectedOrderMiscFromGroup = new UntypedFormArray([]);
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.order){
      this.storeService.getMiscItems(this.order.bot_order.id).pipe(takeUntil(this.destroySubject)).subscribe(data => {
        data.forEach(element => {
          this.selectedOrderMiscFromGroup.push(this.getFormGroup(element))
        });
      });
    }
  }

  add(){
    this.selectedOrderMiscFromGroup.push(this.getFormGroup(null))
  }

  deleteItem(index, miscId){
    if(miscId){
      this.orderService.deleteMiscItem(miscId).pipe(takeUntil(this.destroySubject)).subscribe(data => {
        this.selectedOrderMiscFromGroup.removeAt(index);
      });
    }else{
      this.selectedOrderMiscFromGroup.removeAt(index);
    }
  }

  submitMisc(){
    const payload = {
      bot_order_id: this.order.bot_order.id,
      items: this.selectedOrderMiscFromGroup.value
    };

    this.storeService.addMiscItems(payload).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.refresh.emit(this.order.bot_order);
    });
  }

  getFormGroup(data){
    return new UntypedFormGroup({
      id: new UntypedFormControl(data ? data.id : null),
      quantity:  new UntypedFormControl(data ? data.quantity : 1, Validators.required),
      item_name: new UntypedFormControl(data ? data.item_name : null, Validators.required),
      price: new UntypedFormControl(data ? data.price : null, Validators.required),
      is_taxable: new UntypedFormControl(data ? data.is_taxable : true)
    })
  }


}
