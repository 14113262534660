import {Component, ElementRef, Inject, OnInit, ViewChild, NgZone} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Store} from 'src/app/_models/store';
import {StoreService} from 'src/app/_services/store.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatChipInputEvent} from '@angular/material/chips';
import { CdkDragDrop, CdkDragMove, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Table {
  id: number;
  table_name: string;
}

@Component({
  selector: 'app-tableside',
  templateUrl: './tableside.component.html',
  styleUrls: ['./tableside.component.scss']
})
export class TablesideComponent implements OnInit {

  store: Store;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService: StoreService,
    private menuService: MenuServiceV2, 
    private snackBar: MatSnackBar,
    private ngZone: NgZone
    ) {
    this.store = data.store;
  }

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  tables: Table[] = [];

  ngOnInit() {
    this.storeService.getTablesideTables(this.store.id).subscribe(data => {
      this.tables = data
    })
  }

  add(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value.trim();
    if (value) {
      this.storeService.addTablesideTable(this.store.id, value).subscribe(data => {
        this.tables.push(data);
        inputElement.value = '';
        this.snackBar.open('Item has been added', 'OK', { duration: 2000, verticalPosition: 'top'});

      }, error => {
        console.error('Error adding table:', error);
      });
    }
  }

  remove(table: Table): void {
    this.storeService.deleteTablesideTable(table.id).subscribe(data => {
      const index = this.tables.indexOf(table);
      if (index >= 0) {
        this.tables.splice(index, 1);
        this.snackBar.open('Item has been removed', 'OK', { duration: 2000, verticalPosition: 'top'});
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tables, event.previousIndex, event.currentIndex);
    let reorderedTables = this.tables;
    let tableIds = reorderedTables.map((table: any) => table.id);
    let payload = {
      type: 'tables',
      order: tableIds
    };
    this.menuService.updateOrder(this.store.id, JSON.stringify(payload)).subscribe(data => {
      this.ngOnInit();
    });
  }
}
