import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorSchemeService {

  private renderer: Renderer2;
  private colorSchemePrefix = 'color-scheme-';

  constructor(rendererFactory: RendererFactory2) {
    // Create a renderer instance
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // Always set dark mode
  load() {
    this.setDarkMode();
  }

  // Method to set dark mode
  setDarkMode() {
    this.renderer.addClass(document.body, this.colorSchemePrefix + 'dark');
  }

  // Method to set light mode (optional if you want to toggle later)
  setLightMode() {
    this.renderer.removeClass(document.body, this.colorSchemePrefix + 'dark');
    this.renderer.addClass(document.body, this.colorSchemePrefix + 'light');
  }
}
