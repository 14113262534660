<!-- <form class="fixActionRow">
  <h1 mat-dialog-title>Tableside</h1>
  <div class="ml-6">
    <p class="message">Set available table id’s for tableside here</p>
  </div>
  <div mat-dialog-content>
    <mat-form-field class="width-100 field-padding">
      <mat-chip-grid #chipGrid aria-label="Table selection" class="bg-white" style="padding: 0px !important;">
        <div cdkDropList class="cdk-drop-list" (cdkDropListDropped)="drop($event)">
          <mat-chip-option *ngFor="let table of tables" [cdkDrag]="table" cdkDrag [selectable]="selectable" [removable]="removable"
            (removed)="remove(table)">
            {{ table.table_name }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip-option>
        </div>
        <input class="mt-10 mb-7 !basis-auto" placeholder="Table names" [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-grid>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end" class="space-x-2">
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-button mat-flat-button mat-dialog-close color="primary"
      class="primary-color primary-button apply-btn-colr">Apply</button>
  </div>
</form> -->

<div class="p-5 h-full overflow-hidden">
  <h1 class="text-xl">Tableside</h1>
  <div class="pb-2">
    <p class="message">Set available table id’s for tableside here</p>
  </div>
  <div class="h-full">
    <div class="h-3/5 overflow-y-scroll" cdkScrollable>
      <mat-accordion cdkDropList [cdkDropListData]="tables" (cdkDropListDropped)="drop($event)">
        <div *ngFor="let table of tables">
          <mat-expansion-panel cdkDrag disabled class="not-open">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="relative top-[2px] right-[2px]"><mat-icon>drag_indicator</mat-icon></span>
                <span class="w-full"> {{ table.table_name }} </span>
                <span><mat-icon *ngIf="removable" (click)="remove(table)"
                    class="top-[3px] relative">cancel</mat-icon></span>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </div>
    <mat-form-field class="w-full">
      <input type="text" placeholder="Table names" matInput (keydown.enter)="add($event)">
    </mat-form-field>
    <div align="end" class="space-x-2 pt-[5px]">
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-button mat-flat-button mat-dialog-close color="primary"
        class="primary-color primary-button apply-btn-colr">Apply</button>
    </div>
  </div>
</div>