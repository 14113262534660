import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserService } from '../_services/user.service';
import { StoreService } from '../_services/store.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '../_models/store';
import { FormUtils } from '../_helpers/form-utils';
import { PermissionDialogComponent } from './permission-dialog/permission-dialog.component';
import { NewRoleComponent } from './new-role/new-role.component';
import { RolesComponent } from './roles/roles.component';
import { AlertsMessagesComponent } from '../alerts-messages/alerts-messages.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class UsersComponent implements OnInit {

  isLoading;
  columnsToDisplay = ['name', 'email', 'role', 'pin', 'last_login', 'star']
  dataSource = [];
  expandedElement;
  store: Store;
  roles: any;
  rolesList: any;
  constructor(private userService: UserService,
    private storeService: StoreService,
    private formUtils: FormUtils,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;
        let user = JSON.parse(localStorage.getItem('currentUser'))
        this.userService.getRoles(store.id, user['username']).toPromise().then(
          result => {
            this.roles = result;
            this.rolesList = result.slice();
            this.roles.push({ 'role_name': 'Add New Role' });
            this.roles.push({ 'role_name': 'Manage Roles' });
          });
        this.userService.getAll(store.id, user['username']).subscribe(data => {
          this.dataSource = data.map(user => {
            user['form'] = new UntypedFormGroup({
              id: new UntypedFormControl(user['id']),
              first_name: new UntypedFormControl(user['first_name'], Validators.required),
              last_name: new UntypedFormControl(user['last_name'], Validators.required),
              username: new UntypedFormControl(user['username'], [Validators.required, Validators.email]),
              password: new UntypedFormControl(user['password']),
              confirm_password: new UntypedFormControl(user['password']),
              user_profile: new UntypedFormGroup({
                phone: new UntypedFormControl(user['user_profile']['phone'], Validators.required),
                hourly_rate: new UntypedFormControl(user['user_profile']['hourly_rate']),
                role: new UntypedFormControl(user['user_profile']['role']['role_name']),
                has_pin: new UntypedFormControl(user['user_profile']['has_pin']),
                pin: new UntypedFormControl({ value: user['user_profile']['has_pin'] ? user['user_profile']['pin'] : null, disabled: !user['user_profile']['has_pin'] }),
              })
            })
            user['type'] = 'update';
            return user;
          })

          this.dataSource.push({
            type: 'add',
            first_name: 'Add new user',
            last_name: '', username: '', password: '', confirm_password: '',
            user_profile: {
              phone: '', hourly_rate: '', role: '', email_notify: false, sms_notify: false,
              message_sms_notify: false, message_email_notify: false
            },
            form: new UntypedFormGroup({
              account_id: new UntypedFormControl(this.store.account.id),
              first_name: new UntypedFormControl('', Validators.required),
              last_name: new UntypedFormControl('', Validators.required),
              username: new UntypedFormControl('', [Validators.required, Validators.email]),
              password: new UntypedFormControl('', Validators.required),
              confirm_password: new UntypedFormControl('', Validators.required),
              user_profile: new UntypedFormGroup({
                phone: new UntypedFormControl('', Validators.required),
                hourly_rate: new UntypedFormControl(''),
                role: new UntypedFormControl(''),
                has_pin: new UntypedFormControl(false),
                pin: new UntypedFormControl({ value: '', disabled: true }),
              })
            })
          })
        })
      }
    })
  }

  disablePin(form) {
    if (!form.controls.user_profile.value.has_pin) {
      form.controls.user_profile.controls.pin.disable()
    } else {
      form.controls.user_profile.controls.pin.enable()
    }
  }

  updateUser(user) {
    if (user.user_profile && user.user_profile.phone) {
      user.user_profile.phone = this.formUtils.getPlainPhoneNumber(user.user_profile.phone);
      user.user_profile.hourly_rate = this.formUtils.getPlainCurrencyNumber(user.user_profile.hourly_rate);
    }

    if (user.id) {
      if (user.password === user.confirm_password) {
        user.store_id = this.store.id;
        user.last_name = user.last_name.trim().replace(/\s+/g, '-');
        this.userService.updateUser(user).subscribe(data => {
          if(data['msg'] == "Duplicate PIN"){
            this.AlertPopUp('','The PIN has already been taken! Please retry with a different PIN.')
          } else {
            this.AlertPopUp('update_pin_alert','User information updated successfully.')
          }
        },
        error => {
          this.AlertPopUp('','Error while updating user.')
        })
      }else{
        this.AlertPopUp('','Password and Confrim Password must be same!')
      }
    } else {
      user.store_id = this.store.id;
      if (user['user_profile']['hourly_rate'] && typeof user['user_profile']['hourly_rate'] != 'number') {
        user['user_profile']['hourly_rate'] = user['user_profile']['hourly_rate'].replace('$', '');
      }
      user.last_name = user.last_name.trim().replace(/\s+/g, '-');
      this.userService.addUser(user).subscribe(data => {
        if(data['msg'] == "Duplicate PIN"){
          this.AlertPopUp('','The PIN has already been taken! Please retry with a different PIN.')
        } else {
          this.AlertPopUp('user_add_alert','User added successfully.')
        }
      }, error => {
        this.AlertPopUp('','Error while adding user.')
      })
    }
  }

  deleteUser(user) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure to delete?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deleteUser(user).subscribe(data => {
          this.ngOnInit();
        })
      }
    });
  }

  managePermission(user) {
    console.log(user);

    console.log("open permission");
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(PermissionDialogComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '100vw',
      
      data: {
        store: this.store,
        user: user
      }
    })
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
      console.log(data);
    })
  }

  newClick(e, f) {
    if (e == "Add New Role") {
      this.addRole(e, f);
    }
  }

  addRole(selectedRole, user) {
    this.dialog.closeAll();
    if (selectedRole == "Add New Role") {
      const dialogRef = this.dialog.open(NewRoleComponent, {
        // width: '630px', height: '260px',
        data: selectedRole
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(this.dataSource);
        
        if (result) {
          user.user_profile.role = result['data']
          user['store_id'] = this.store.id
          this.userService.updateUser(user).subscribe(data => {
            this.AlertPopUp('user_info_alert','User information updated successfully.')
          },
          error => {
            this.AlertPopUp('','Error while updating user.')
          })
        }
        
        user.user_profile.role = result['data'];
        user['store_id'] = this.store.id;
        this.userService.updateUser(user).subscribe(data => {
          this.AlertPopUp('user_info_alert','User information updated successfully.')
          // alert('User information updated successfully.');
          // this.ngOnInit();
        },
        error => {
          this.AlertPopUp('','Error while updating user.')
          // alert('Error while updating user.');
        })
      });
    } else if (selectedRole == "Manage Roles") {
      const dialogRef = this.dialog.open(RolesComponent, {
        width: '430px', height: '500px',
        data: { 'roles': this.rolesList, 'store': this.store }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    } else {
    }
  }

  AlertPopUp(name, msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Alert Dialog Closed', result, name);
      if (result) {
        if (name == 'update_pin_alert' || name == 'user_add_alert' || name == 'user_info_alert') {
          this.ngOnInit();
        }
      }
    });
  }

  onRowClicked(row) {
    if (row) {
      if (row.type === "add") {
        console.log('Add user');
        this.roles = this.roles.filter(e => e.role_name !== 'Add New Role' &&  e.role_name !== 'Manage Roles');
      } else {
        const found = this.roles.find((element) => element.role_name == 'Add New Role');
        if (!found) {
          this.roles.push({'role_name':'Add New Role'});
          this.roles.push({'role_name':'Manage Roles'});
        }  
        
      }
    }
    
  }
}
