<form class="order-message-table-filters">
  <mat-form-field appearance="fill" class="search">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Search Orders</mat-label>
    <input [(ngModel)]="search" name="search" matInput placeholder="Search orders" #input
      (input)="searchOrders($event)">
  </mat-form-field>

  <mat-form-field appearance="fill" class="start">
    <mat-label>Start Date</mat-label>
    <input [(ngModel)]="startDate" name="startDate" matInput [matDatepicker]="startPicker"
      placeholder="Choose a start date" (dateInput)="searchOrders($event)">
    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #startPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="fill" class="end">
    <mat-label>End Date</mat-label>
    <input [(ngModel)]="endDate" name="endDate" matInput [matDatepicker]="endPicker" placeholder="Choose a end date"
      (dateInput)="searchOrders($event)" [min]="startDate">
    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
  </mat-form-field>

  <button mat-flat-button class="set-button apply-btn-colr clear xs:!w-[100%] clear-gap xs:mt-3 btn-all action-btn-colr create" (click)="reset()" color="primary">CLEAR</button>&nbsp;&nbsp;
  <button mat-flat-button class="set-button apply-btn-colr clear xs:!w-[100%] clear-gap xs:mt-3 xs:mb-3 sm:mb-3 btn-all btn-bottom action-btn-colr create" (click)="exportData()" color="primary">EXPORT</button>
  <div class="clear"></div>
</form>

<div class="spinner-container" *ngIf="dataSource.loading$ | async">
  <mat-spinner></mat-spinner>
</div>

<div class="order-container">
  <mat-table width="100%" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear
    [dataSource]="dataSource">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm> Order </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Order" [ngClass.lt-md]="{'highlight-gray': true }" fxHide.lt-sm>
        {{element.id}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="redeemed_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Redeemed </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Placed"> {{ element.redeemed_at | date:'MMM dd EEE hh:mm a'}}
      </mat-cell>
    </ng-container>

  
    <ng-container matColumnDef="referee_by_phone_number">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Referrer </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="referee_by_phone_number">{{element.redeem_user.referee_by_phone_number}}
      </mat-cell>
    </ng-container>
    
  <ng-container matColumnDef="redeem_user__phone_number">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Referee </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="redeem_user__phone_number">{{element.redeem_user.phone_number}}
    </mat-cell>
  </ng-container>

    <ng-container matColumnDef="redeem_id">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm> ID </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="redeem_id" fxHide.lt-sm>
        {{element.redeem_id}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="redeemed_amount">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="redeemed_amount"> {{element.redeemed_amount | currency:'USD'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="payment_status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Status">
        <div *ngIf="element.is_verified">
          Approved
        </div>
        <div *ngIf="!element.is_verified">
          Pending
        </div>
        <!-- <mat-icon *ngIf="element.is_reversed" matTooltip="Reversed">sync</mat-icon> -->
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator [length]="dataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>
</div>