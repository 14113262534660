<div class="items" #scrollMe [scrollTop]="scrollMe.scrollHeight">
  <div *ngFor="let fg of selectedOrderMiscFromGroup.controls;let i = index;">
    <ng-container [formGroup]="fg">
      <mat-form-field class="width-15">
        <mat-label>Quantity</mat-label>
        <input formControlName="quantity" matInput placeholder="Qty" type="number">
      </mat-form-field>

      <mat-form-field class="width-30">
        <mat-label>Name</mat-label>
        <input formControlName="item_name" matInput placeholder="Name">
      </mat-form-field>

      <mat-form-field class="width-20">
        <mat-label>Price</mat-label>
        <input formControlName="price" matInput placeholder="Price">
      </mat-form-field>
      <mat-checkbox formControlName="is_taxable">Taxable</mat-checkbox>
      <div class="form-ops">
        <button mat-icon-button (click)="deleteItem(i, fg.value.id); false">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
      <div style="clear: both"></div>
    </ng-container>
  </div>
</div>

<div class="action-row">
  <button class="pull-left" mat-stroked-button color="primary" type="button" (click)="add(); false">Add Item</button>
  <button class="pull-right bg" mat-stroked-button color="primary" (click)="submitMisc()">Update</button>

  <button class="pull-right" mat-stroked-button type="button"
    (click)="expandedRowId = null;ngOnInit(); false">Cancel</button>
  <div style="clear: both"></div>
</div>
