import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams,} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {BotOrder, BotOrders, Order, OrdersWithPayment,} from '../_models/order';
import {environment} from '../../environments/environment';
import {Store} from '../_models/store';
import {IOrder, IOrderItem} from '../_interfaces/order';
import {TransationHistory} from '../_models/order-transaction-history';
import Utils from 'src/app/utils';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class OrderService {
  endpoint = environment.apiUrl;
  orderEndpoint = `${this.endpoint}/backend/order/`;
  newOrderSubject: BehaviorSubject<IOrder> = new BehaviorSubject<IOrder>(null);
  editOrderSubject: Subject<Order> = new Subject<Order>();
  editSubmitSubject: Subject<IOrderItem> = new Subject<IOrderItem>();
  orderDoneSubject: Subject<null> = new Subject<null>();
  openOrderCompleteSubject = new BehaviorSubject<any>({});
  openOrderComplete = this.openOrderCompleteSubject.asObservable();

  editMiscSubject: Subject<any> = new Subject<any>();

  changeOrderSubject: Subject<BotOrder> = new Subject<BotOrder>();
  disableInputButton: boolean;
  activeTerminalList: any;
  public occasionUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadClosedOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCashCLose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public keyboardValueSet: BehaviorSubject<any> = new BehaviorSubject<any>("");
  openPosLoginFromOpenOrder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public removeItemUpdateMenuTab: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient, private _snackBar : MatSnackBar) {}

  errorMessage(message: string, action: string, duration: number) {
    this._snackBar.open(message, action, {
      duration,
    });
  }

  updateTerminalStatus(terminalId, userId, sessionId, status) {
    const body = {
      terminal: terminalId,
      user: userId,
      status,
    };
    return this.http.patch<any>(
      `${this.endpoint}/backend/v2/terminal/selection/${sessionId}/`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  postLoginUser(body: any) {
    return this.http.post<any>(
      `${this.endpoint}/backend/v2/terminal/selection/`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  getOrder(orderId: string): Observable<OrdersWithPayment> {
    return this.http.get<OrdersWithPayment>(this.orderEndpoint + orderId + '/');
  }

  printOrder(orderId: string, selectedTerminalName) {
    const terminal_name = selectedTerminalName.toString();
    return this.http.get<string>(this.orderEndpoint + orderId +'/print/?terminal_name=' + terminal_name)
  }

  printOrderSplit(orderId: string, selectedTerminalName,splitId:string) {
    let terminal_name = selectedTerminalName.toString();
    return this.http.get<string>(this.orderEndpoint + orderId +"/split/"+splitId +"/print/?terminal_name=" + terminal_name)
  }

  orderReady(orderId: string) {
    return this.http.get<string>(
      this.orderEndpoint + orderId + '/order_ready/'
    );
  }

  closeTableside(orderId: string) {
    return this.http.get<string>(
      this.orderEndpoint + orderId + '/close_tableside/'
    );
  }

  submitTableside(orderId: string) {
    return this.http.get<string>(
      this.orderEndpoint + orderId + '/submit_tableside_checkout/?opt_in=true'
    );
  }

  deleteMiscItem(miscId: string) {
    return this.http.delete<string>(
      `${this.endpoint}/backend/v2/orders/misc_items/${miscId}/`
    );
  }

  getStoreLogo(storeId: string) {
    return this.http.get<any>(
      `${this.endpoint}/backend/v2/stores/logo/${storeId}/`
    );
  }

  getTempOrder(storeId: string) {
    return this.http.get<any>(
      `${this.endpoint}/backend/order/temp/${storeId}/`
    );
  }

  getTempOrderWithPhone(storeId: string, phoneNumber: string) {
    return this.http.post<any>(
      `${this.endpoint}/backend/order/temp/${storeId}/`,
      { phone_number: phoneNumber }
    );
  }

  getTempPOSOrderWithPhone(
    storeId: string,
    phoneNumber: string,
    cashier: string
  ) {
    return this.http.post<any>(
      `${this.endpoint}/backend/order/temp/${storeId}/`,
      { phone_number: phoneNumber, pos: true, cashier }
    );
  }

  getOrderByHash(hash: string) {
    return this.http.get<OrdersWithPayment>(
      `${this.endpoint}/backend/order/${hash}/`
    );
  }

  updateOccasionSchedule(hash: string, payload) {
    return this.http.post<any>(
      `${this.endpoint}/backend/order/${hash}/update_occasion_schedule/`,
      payload
    );
  }

  updateDescription(id, payload) {
    return this.http.post<any>(
      `${this.endpoint}/backend/order/${id}/update_edit_description/`,
      payload
    );
  }

  updateInstruction(hash: string, payload) {
    return this.http.post<any>(
      `${this.endpoint}/backend/order/${hash}/update_instruction/`,
      payload
    );
  }

  addItemId(hash: string, orderId: string, payload) {
    return this.http.post<any>(
      `${this.endpoint}/backend/order/${hash}/${orderId}/new-modifiers/`,
      JSON.stringify(payload)
    );
  }

  async getParserMenuItems(hash: string): Promise<any> {
    return await this.http.get<any>(
      `${this.endpoint}/backend/order/${hash}/parser_menu_items/`
    ).pipe(map((r: any) => {
      return r;
    }))
    .toPromise().then(ret => {
      return ret;
    });
  }

  async removeItemId(hash: string, parsed_menu_item_id: string, payload): Promise<any> {
    return await this.http.post<any>(
      `${this.endpoint}/backend/order/${hash}/${parsed_menu_item_id}/remove/`,
      JSON.stringify(payload)
    ).pipe(map((r: any) => {
      return r;
    }))
    .toPromise().then(ret => {
      return ret;
    });
  }

  updateItemId(hash: string, itemId, payload) {
    return this.http.post<any>(
      `${this.endpoint}/backend/order/${hash}/${itemId}/modifiers/`,
      JSON.stringify(payload)
    );
  }

  saveItemOrder(storeId: string, payload) {
    return this.http.post<any>(
      `${this.endpoint}/backend/v2/pos/${storeId}/authorize`,
      payload
    );
  }

  getTerminalId(storeId: string) {
    return this.http.get<any>(
      `${this.endpoint}/backend/v2/pos/?store_id=${storeId}`
    );
  }

  posAuthorize(terminalId: string, payload) {
    return this.http.post<any>(
      `${this.endpoint}/backend/v2/pos/${terminalId}/authorize/`,
      payload
    );
  }

  posTransactionStatus(terminalId: string, payload) {
    return this.http.post<any>(
      `${this.endpoint}/backend/v2/pos/${terminalId}/status/`,
      payload
    );
  }

  updateTip(orderId: string, tip: number, component: string) {
    return this.http.post<any>(
      this.orderEndpoint + orderId + '/update_tip/',
      JSON.stringify({ tip_percent: tip, component: component }),
      this.httpOptions
    );
  }

  updateCustomTip(orderId: string, tip: number, component: string) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + '/update_tip/',
      JSON.stringify({ tip, component }),
      this.httpOptions
    );
  }

  updateQuantity(
    orderId: string,
    parsed_menu_item_id: number,
    quantity: number
  ) {
    const options = { headers: { 'Content-Type': 'application/json' } };
    return this.http.post<string>(
      this.orderEndpoint +
        orderId +
        '/' +
        parsed_menu_item_id +
        '/update_quantity/',
      JSON.stringify({ quantity }),
      this.httpOptions
    );
  }

  updateServiceCharge(orderId: string, serviceCharge: number) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + '/update_service_charge/',
      JSON.stringify({ service_charge: serviceCharge }),
      this.httpOptions
    );
  }

  deleteItem(orderId: string, parsed_menu_item_id: number) {
    return this.http.get<string>(
      this.orderEndpoint + orderId + '/' + parsed_menu_item_id + '/delete/',
      this.httpOptions
    );
  }

  updatePhoneNumber(
    orderHash: string,
    phoneNumber: string,
    order_name: string,
    isPos = false
  ) {
    return this.http.post<string>(
      this.orderEndpoint + orderHash + '/update_phone_number/',
      JSON.stringify({
        phone_number: phoneNumber,
        order_name,
        is_pos: isPos,
      }),
      this.httpOptions
    );
  }

  updateDeliveryFee(orderId, delivery_fee) {
    const payload = { delivery_fee };
    return this.http.post<any>(
      `${this.endpoint}/backend/v2/orders/${orderId}/update_delivery_fee/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  getServiceAreas(store_id) {
    return this.http.get<any>(
      `${this.endpoint}/backend/v2/service-area/${store_id}/`,
      this.httpOptions
    );
  }

  removePromoCode(orderId) {
    return this.http.get<any>(
      this.orderEndpoint + orderId + '/remove_promo_code/',
      this.httpOptions
    );
  }

  applyPromoCode(orderId, promoCode) {
    return this.http.post<any>(
      this.orderEndpoint + orderId + '/apply_promo_code/',
      JSON.stringify({ promo_code: promoCode }),
      this.httpOptions
    );
  }


  payLater(orderId, payload) {
    return this.http.post(this.orderEndpoint + orderId + '/pay_later/',
    JSON.stringify(payload),
    this.httpOptions);
  }

  cancelOrder(orderId, message, split_id) {
    const params = new HttpParams()
    .set('cancel_reason', message)
    .set('split_id', split_id)
    return this.http.get<any>(
      this.orderEndpoint + orderId + '/cancel/',{ params });
  }

  closeCashOrder(orderId, payload) {
    return this.http.post<any>(
      this.orderEndpoint + orderId + '/close_cash_order/',
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  closeKeyedOrder(orderId, payload) {
    return this.http.post<any>(
      this.orderEndpoint + orderId + '/close_keyed_order/',
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  addNewCardGateway(
    storeId: string,
    phoneNumber: string,
    token: string,
    paymentGateway: string,
    isOneTime: boolean,
    card: any = {},
  ) {
    return this.http.post(
      this.endpoint + '/backend/payment/gateway/new_card/',
      JSON.stringify({
        store_id: storeId,
        token,
        phone_number: phoneNumber,
        payment_gateway: paymentGateway,
        is_one_time: isOneTime,
        card,
      }),
      this.httpOptions
    );
  }

  updatePreferredPayment(orderId: string, payload: any) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + '/updated_preferred_payment/',
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  getLastPreferredPayment(
    orderId: string,
    phoneNumber: string,
    paymentGateway: string
  ) {
    return this.http.post<any>(
      this.orderEndpoint + orderId + '/get_last_preferred_payment/',
      JSON.stringify({
        phone_number: phoneNumber,
        payment_gateway: paymentGateway,
      }),
      this.httpOptions
    );
  }

  getCards(storeId: string, phoneNumber: string, paymentGateway: string) {
    return this.http.get<any>(
      `${
        this.endpoint
      }/backend/payment/?store_id=${storeId}&phone_number=${encodeURIComponent(
        phoneNumber
      )}&payment_gateway=${paymentGateway}`,
      this.httpOptions
    );
  }

  deleteCard(cardDetails) {
    return this.http.post<any>(
      this.endpoint + '/backend/payment/delete/',
      JSON.stringify(cardDetails),
      this.httpOptions
    );
  }

  updateUser(orderHash, payload){
    return this.http.post(`${this.endpoint}/backend/v2/pos/receipts/${orderHash}/update_user/`, payload, this.httpOptions);
  }

  sendTextReceipt(orderHash, payload){
    return this.http.post(`${this.endpoint}/backend/v2/pos/receipts/${orderHash}/send_text_receipt/`, payload, this.httpOptions);
  }

  printPaperReceipt(orderHash, payload){
    return this.http.post(`${this.endpoint}/backend/v2/pos/receipts/${orderHash}/print_receipt/`, payload, this.httpOptions);
  }

  getSplits(orderHash, payload){
    return this.http.post<any>(`${this.endpoint}/backend/v2/pos/split/${orderHash}/get_splits/`, payload, this.httpOptions);
  }

  processSplit(orderHash, payload){
    return this.http.post(`${this.endpoint}/backend/v2/pos/split/${orderHash}/process_split/`, payload, this.httpOptions);
  }

  getDue(orderHash){
    return this.http.get(`${this.endpoint}/backend/v2/pos/split/${orderHash}/get_due/`, this.httpOptions);
  }

  closeSplitPayment(orderHash, payload){
    return this.http.post(`${this.endpoint}/backend/v2/pos/split/${orderHash}/close_split_payment/`, payload, this.httpOptions);
  }

  voidTransaction(orderHash, payload){
    return this.http.post(`${this.endpoint}/backend/v2/pos/split/${orderHash}/void_transaction/`, payload, this.httpOptions);
  }

  refundSplitTransaction(orderHash, payload){
    return this.http.post(`${this.endpoint}/backend/v2/pos/split/${orderHash}/refund/`, payload, this.httpOptions);
  }

  updateSplit(orderHash, payload){
    return this.http.post(`${this.endpoint}/backend/v2/pos/split/${orderHash}/update_split/`, payload, this.httpOptions);
  }

  resetSplits(orderHash){
    return this.http.get(`${this.endpoint}/backend/v2/pos/split/${orderHash}/reset/`, this.httpOptions);
  }

  resetSingleSplit(orderHash, payload){
    return this.http.post(`${this.endpoint}/backend/v2/pos/split/${orderHash}/reset_split/`, payload, this.httpOptions);
  }

  addTipSplitTransaction(orderHash, payload){
    return this.http.post(`${this.endpoint}/backend/v2/pos/split/${orderHash}/add_tip/`, payload, this.httpOptions);
  }

  splitAuthorize(terminalId: string, payload, optIn) {
    return this.http.post<any>(
      `${this.endpoint}/backend/v2/pos/split/${terminalId}/authorize/?opt_in=${optIn}&is_open_tab=true`,
      payload
    );
  }

  filterDeliveryAddress(store: Store, data: any){
    return this.http.get<any>(`${this.endpoint}/backend/${store}/address-list-by-phone/?phone_number=${data}`);
  }
  posCloseBatch(storeId){
    return this.http.get(`${this.endpoint}/backend/v2/pos/batch/${storeId}/close_batch/`, this.httpOptions);
  }

  posClosedBatches(storeId){
    return this.http.get(`${this.endpoint}/backend/v2/pos/batch/${storeId}/closed_batches/`, this.httpOptions);
  }

  closedBatchCustom(payload) {
    return this.http.post(`${this.endpoint}/backend/v2/custom-batch-time/`, payload);
  }

  customClosedBatchTime(store) {
    return this.http.get(`${this.endpoint}/backend/v2/custom-batch-time/${store}/`);
  }

  closedOrders(storeId:number, filter = '', startDate = null, endDate = null,
               sortField='id', sortOrder='asc', pageNumber=0, pageSize=10, cashierId = '',
               occasions = [], payment = [], source = [], status = [], server_id = []) {

    let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('filter', filter)
    .set('sort_field', sortField)
    .set('sort_dir', sortOrder)
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())
    .set('closed_tab', 'true')
    .set('cashier_id', cashierId || '')
    .set('occasion', occasions.join(','))
    .set('payment', payment.join(','))
    .set('source', source.join(','))
    .set('status', status.join(','))
    .set('server_id', server_id.join(','))


    if(startDate){
      // params = params.set('start_date', startDate.toISOString().slice(0, 10))
      params = params.set('start_date', Utils.toISOLocal(startDate).slice(0, 10))
    }

    if(endDate){
      // params = params.set('end_date', endDate.toISOString().slice(0, 10))
      params = params.set('end_date', Utils.toISOLocal(endDate).slice(0, 10))
    }

    return this.http.get<BotOrders>(`${environment.apiUrl}/backend/v2/business/orders/${storeId}/closed_orders/`, { params });
  }


  openOrders(storeId:number,filter='', startDate=null, endDate=null,
    sortField='id', sortOrder='asc', pageNumber=0, pageSize=10, cashierId='',
    occasions = [], payment = [], source = [], status = [], server_id = []){

    let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('filter', filter)
    .set('sort_field', sortField)
    .set('sort_dir', sortOrder)
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())
    .set('open_tab', 'true')
    .set('cashier_id', cashierId || '')
    .set('occasion', occasions.join(','))
    .set('payment', payment.join(','))
    .set('source', source.join(','))
    .set('status', status.join(','))
    .set('server_id', server_id.join(','))

    if(startDate){
      // params = params.set('start_date', startDate.toISOString().slice(0, 10))
      params = params.set('start_date', Utils.toISOLocal(startDate).slice(0, 10))
    }

    if(endDate){
      // params = params.set('end_date', endDate.toISOString().slice(0, 10))
      params = params.set('end_date', Utils.toISOLocal(endDate).slice(0, 10))

    }

    return this.http.get<BotOrders>(`${environment.apiUrl}/backend/v2/business/orders/${storeId}/open_orders/`, { params });
  }

  processPostPayment(storeId, payload){
    return this.http.post(`${environment.apiUrl}/backend/v2/business/orders/${storeId}/payment_process/`, payload);
  }

  history(orderId){
    return this.http.get<TransationHistory[]>(`${environment.apiUrl}/backend/v2/business/orders/${orderId}/history/`);
  }

  viewOrderConfirm(orderId: string, data: any) {
    return this.http.get<string>(
      this.orderEndpoint + orderId + `/order_view_ready/${data}/`
    );
  }

  numberVerifyForCard(payload, hash){
    return this.http.post(`${environment.apiUrl}/backend/card-verify/${hash}/otp/`, payload);
  }

  verifyCardOtp(payload, hash) {
    return this.http.post(`${environment.apiUrl}/backend/card-verify/${hash}/otp/verify/`, payload);

  }

  payLaterReceiptPrint(o_hash, selection) {
    return this.http.get(`${environment.apiUrl}/backend/store/${o_hash}/pay_later/${selection}/manual_receipt`);
  }

  splitOrderDetails(o_hash:any) {
    return this.http.get(`${environment.apiUrl}/backend/order_split_list/${o_hash}/`);
  }
  splitTransactionDetails(o_hash:any, split_id) {
     http://localhost:8000/backend/split_order_detail/0c324a30-5ea3-11ee-a606-01b7a62964f9/2257/
    return this.http.get(`${environment.apiUrl}/backend/split_order_detail/${o_hash}/${split_id}/`);
  }
  printOrderSplitManual(orderId: string, selectedTerminalName,splitId:string,selectionVal,data) {
    let terminal_name = selectedTerminalName.toString();
    return this.http.get<string>(this.orderEndpoint + orderId +"/split/"+splitId +"/print/?terminal_name=" + terminal_name+"&selection="+selectionVal+"&given_cash="+data.result+"&total="+data.cashDueTotal)
  }
  manualPrintReceipt(o_hash: string, selection: string, terminalName: string,  posLoggedUser: string): Observable<any> {
    let queryParams = new HttpParams();
  
    queryParams = queryParams.set('terminal_name', terminalName.toString());
    queryParams = queryParams.set('server_id', posLoggedUser.toString());
  
    return this.http.get(`${environment.apiUrl}/backend/store/${o_hash}/${selection}/manual_print`, { params: queryParams });
  }
  posDeliveryReorder(store_id: string, payload) {
    return this.http.post<any>(`${environment.apiUrl}/backend/order/${store_id}/pos_reorder/`,payload);
  }
  addQuickDiscount(orderId, payload) {
    return this.http.post(`${environment.apiUrl}/backend/v2/orders/pos_add_discount/${orderId}/`, payload);
  }

  removeQuickDiscount(orderId) {
    return this.http.post(`${environment.apiUrl}/backend/v2/orders/${orderId}/pos_remove_discount/`,{});
  }

  getSplitTransactionsHistory(orderId,splitId,includeTip=false){
    return this.http.get<any>(`${environment.apiUrl}/backend/v2/pos/split/${orderId}/history/?is_tip=${includeTip}&split_id=${splitId}`, this.httpOptions);
  }
  deliveryOrderList(storeid, page_number:any, page_size:any) {
    let params = new HttpParams()
    .set('page_number', page_number)
    .set('page_size', page_size);
    return this.http.get(`${environment.apiUrl}/backend/${storeid}/delivery_order_list/`, {params});
  }

  driverList(driverid) {  
    return this.http.get(`${environment.apiUrl}/backend/${driverid}/drivers_list/`);
  }
  
  assignOrderToDriver(payload:any, storeid:any) {
    return this.http.post(`${environment.apiUrl}/backend/${storeid}/update_driver_id/`, payload);
  }

  updateOrderStatus(payload:any, storeid:any) {
    return this.http.post(`${environment.apiUrl}/backend/${storeid}/update_delivery_status/`, payload);
  }
  
  updateServerId(order_hash, payload) {
    return this.http.post(`${environment.apiUrl}/backend/order/${order_hash}/update_server_id/`,payload);
  }
  getTrackinUrl(orderId: any):Observable<any>{
    return this.http.get(`${environment.apiUrl}/backend/orders/tracking_url/${orderId}`, this.httpOptions);
  }
}
