import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-video-dialog',
  templateUrl: './add-video-dialog.component.html',
  styleUrls: ['./add-video-dialog.component.scss']
})
export class AddVideoDialogComponent implements OnInit {
  isLoading = false;
  addMediaForm: UntypedFormGroup;
  storeId: string;
  file: File;
  addEditUpdate: any;
  editMediaForm: UntypedFormGroup;
  rowId: any;

  constructor(
    public fb: UntypedFormBuilder,
    public digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<AddVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    if (this.data.dialog == 1) {
      this.addMediaForm = this.fb.group({
        store: [this.storeId],
        name: ["", Validators.required],
        row: ["", Validators.required],
        video: [""],
      });
    } else if (this.data.dialog == 2) {
      this.rowId = this.data.data.id;
      const editData = this.data.data;
      this.editMediaForm = this.fb.group({
        store: [this.storeId],
        name: [editData.name],
        row: [editData.row],
        video: [""],
      });
    } else if (this.data.dialog == 3) {
      this.rowId = this.data.data.id;

    }

    this.addEditUpdate = this.data.dialog;
  }

  addMediaFormSubmit() {
    const validationForm = this.addMediaForm.status;
    if (validationForm == "VALID") {
      const formData = new FormData();
      formData.append("store", this.storeId);
      formData.append("name", this.addMediaForm.value.name);
      formData.append("row", this.addMediaForm.value.row);
      if (this.file != null) {
        formData.append("video", this.file, this.file.name);
      }
      this.isLoading = true;
      this.digitalDisplayService.addVideo(formData)
        .subscribe((data: any) => {
          const resData = { yesSubmit: "YesSubmit" };
          this.isLoading = false;
          this.dialogRef.close(resData);
        });
    }
  }
  editMediaFormSubmit() {
    const formData = new FormData();
    formData.append("store", this.storeId);
    formData.append("name", this.editMediaForm.value.name);
    formData.append("row", this.editMediaForm.value.row);
    if (this.file != null) {
      formData.append("video", this.file, this.file.name);
    }
    this.isLoading = true;
    this.digitalDisplayService.editVideo(formData, this.rowId).subscribe((data: any) => {
      const resData = { yesSubmit: "YesSubmit" };
      this.isLoading = false;
      this.dialogRef.close(resData);
    });

  }

  mediaDelete() {
    this.isLoading = true;
    this.digitalDisplayService
      .deleteVideo(this.rowId)
      .subscribe((data: any) => {
        const resData = { yesSubmit: "YesSubmit" };
        this.isLoading = false;
        this.dialogRef.close(resData);

      });
  }

  onChange(event) {
    this.file = event.target.files[0];
  }
}
