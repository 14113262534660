<span *ngIf="addEditUpdate == 1">
  <form name="addMediaForm" [formGroup]="addMediaForm" (ngSubmit)="addMediaFormSubmit()" class="fixActionRow">
    <h1 mat-dialog-title>Add Media</h1>
    <div mat-dialog-content>
      <div fxLayout="row" class="!mt-4 w-full">
        <div class="input-gap w-1/2">
          <mat-form-field appearance="fill" class="w-full"> 
            <mat-label>Name</mat-label>
            <input matInput placeholder="" formControlName="name" type="text">
          </mat-form-field>
        </div>
        <div class="input-gap w-1/2">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Row</mat-label>
            <input matInput formControlName="row" value="" placeholder="" type="number">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" style="margin-top: 14px;">
        <div class="input-gap">
          <input style="margin-top: 20px;" class="form-control" type="file" formControlName="image"
            (change)="onChange($event)" />
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
    </mat-dialog-actions>
  </form>
</span>
<span *ngIf="addEditUpdate == 2">
  <form name="editMediaForm" [formGroup]="editMediaForm" (ngSubmit)="editMediaFormSubmit()" class="fixActionRow">
    <h1 mat-dialog-title>Edit Media</h1>
    <div mat-dialog-content>
      <div fxLayout="row" class="!mt-4 w-full">
        <div class="input-gap w-1/2">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Name</mat-label>
            <input matInput placeholder="" formControlName="name" type="text">
          </mat-form-field>
        </div>
        <div class="input-gap w-1/2">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Row</mat-label>
            <input matInput formControlName="row" placeholder="" type="number">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" style="margin-top: 14px;">
        <div class="input-gap">
          <input style="margin-top: 20px;" class="form-control" type="file" formControlName="image"
            (change)="onChange($event)" />
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
    </mat-dialog-actions>
  </form>
</span>
<span *ngIf="addEditUpdate == 3">
  <div>
    <div mat-dialog-content>
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div class="content">Are you sure you want to delete. </div>
      </div>
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <button mat-button mat-dialog-close mat-stroked-button (click)="onNoClick()">Cancel</button>
      <button mat-button mat-flat-button color="primary" class="apply-btn-colr"
        (click)="mediaDelete()">Submit</button>
    </mat-dialog-actions>
  </div>
</span>