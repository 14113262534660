import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Store} from 'src/app/_models/store';
import {StoreService} from 'src/app/_services/store.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-default-pos-setting',
  templateUrl: './default-pos-setting.component.html',
  styleUrls: ['./default-pos-setting.component.scss']
})
export class DefaultPosSettingComponent implements OnInit {

  store: Store;
  form: UntypedFormGroup;
  allOccasions: any;
  allTime:any;
  defaultSelections: string[];
  defaultOrderOccasion: any;
  defaultTime: any;
  isLoading: boolean = false;
  constructor(private dialogRef: MatDialogRef<DefaultPosSettingComponent>,
    private formBuilder: UntypedFormBuilder,
    private storeService: StoreService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.store = data.store;
     }

  ngOnInit() {
    console.log(this.store);
    this.allOccasions = Object.keys(this.store.occasion_flags).filter(key => this.store.occasion_flags[key] === true);
    this.allTime = ["ASAP", "Schedule for Later"]

    this.form = this.formBuilder.group({
      default_order_occasion: new UntypedFormControl('', [Validators.required]),
      default_time: new UntypedFormControl('', [Validators.required])
    });
    if(this.store && this.store.pos_setting){
      this.form.get("default_order_occasion").setValue( this.store.pos_setting["default_order_occasion"] || "");
      this.form.get("default_time").setValue( this.store.pos_setting["default_time"]  || "");
    }
  }

  submit(form) {
    this.isLoading = true;
    if(this.store) {
      if(this.store && this.store.pos_setting && this.store.pos_setting["pos_setting_id"]){
        if(this.form.value.default_time === "Schedule for Later"){
          this.form.value.default_time = 'LATER'
        }
        this.storeService.createPosSetting(this.store.pos_setting["pos_setting_id"],this.store.id, this.form.value, this.store.pos_setting['default_load'], this.store.pos_setting['closed_orders'], this.store.pos_setting['open_orders']).subscribe(data=>{
          if(data.default_time === "LATER"){
            this.store.pos_setting["default_time"] = 'Schedule for Later'
          }else {
            this.store.pos_setting["default_time"] = data.default_time;
          }
          this.store.pos_setting["pos_setting_id"] = data.id;
          this.store.pos_setting["default_order_occasion"] = data.default_order_occasion;
          this.store.pos_setting["default_load"] = data.default_load;
          this.store.pos_setting["closed_orders"] = data.closed_orders;
          this.store.pos_setting["open_orders"] = data.open_orders;
          this.isLoading = false;
          this.dialogRef.close(this.store.pos_setting);
        })
      }else {
        let defaultLoad: boolean = false;
        let closedOrders: boolean = false;
        let openOrders: boolean = false;
        if(this.store && this.store.pos_setting && this.store.pos_setting['default_load'] && this.store.pos_setting['closed_orders'] && this.store.pos_setting['open_orders']){
          defaultLoad = this.store.pos_setting['default_load']
          closedOrders = this.store.pos_setting['closed_orders']
          openOrders = this.store.pos_setting['open_orders']
        }
        if(this.form.value.default_time === "Schedule for Later"){
          this.form.value.default_time = 'LATER'
        }
        this.storeService.setDefaultPosSetting(this.store.id, this.form.value, defaultLoad, closedOrders, openOrders ).subscribe(data=>{
          if(data){
            if(data.default_time === "LATER"){
              data.default_time = 'Schedule for Later'
            }
             this.store.pos_setting = {
              pos_setting_id : data.id,
              default_order_occasion: data.default_order_occasion,
              default_time : data.default_time,
              default_load : defaultLoad,
              closed_orders : closedOrders,
              open_orders : openOrders
            }
          }
          this.isLoading = false;
          this.dialogRef.close(this.store.pos_setting);
        },
        error => {
          this._snackBar.open("Please select default order occasion and default time!", "OK", {
            duration: 3000,
          });
        })
      }
    } else {
      this.isLoading = false;
      this.dialogRef.close(this.store.pos_setting);
    }
  }
  closePOP(){
    this.dialogRef.close({closePOP:true});
  }
}
