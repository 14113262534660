import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {cloneDeep} from 'lodash';
import {Constants} from 'src/app/constants';
import {Order, OrdersWithPayment} from 'src/app/_models/order';
import {OrderService} from 'src/app/_services/order.service';
import {StoreService} from 'src/app/_services/store.service';
import {PinConfirmComponent} from '../../pin-confirm/pin-confirm.component';
import {PermissionService} from '../../../_services/permission.service';
import {Permission} from '../../../_models/permission';
import {POSSummaryToggleService} from 'src/app/_services/pos-summary-toggle.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';

@Component({
  selector: 'app-order-summary-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() orderHash: string;
  @Input() orderWithPayment: OrdersWithPayment;
  @Input() isAdmin: boolean;
  @Input() offers;
  @Output() quantityChange = new EventEmitter<number>();
  @Output() promoConditionsMetChange = new EventEmitter<boolean>();

  formQuantity = Array.from(Array(250).keys()).map(n => n + 1);
  displayedColumns: string[] = ['quantity', 'item', 'itemTotal'];
  pizzaOptions: Map<string, string> = Constants.pizzaOptions;
  pizzaOptions$: Map<string, string> = Constants.pizzaOptionsFlag;
  promoHidden = true;
  promoCode;
  promoCodeApplied = false;
  conditionsMet;
  permission: Permission;
  pinPermission: Permission;
  private unsubscribe: Subject<null> = new Subject();
  localItemsCopy: any;
  private destroySubject: Subject<void> = new Subject();

  constructor(
    private orderService: OrderService,
    private storeService: StoreService,
    private router: Router,
    private permissionService: PermissionService,
    public dialog: MatDialog,
    private posSummaryToggle: POSSummaryToggleService,
  ) { }

  ngOnInit() {
    if (this.orderWithPayment) {
      this.localItemsCopy = cloneDeep(this.orderWithPayment.items);
      this.promoCode = this.orderWithPayment.bot_order.promo_code;
      this.promoCodeApplied = this.orderWithPayment.bot_order.promo_code.length > 0;
      this.promoHidden = !(this.orderWithPayment.bot_order.promo_code.length > 0);
      this.conditionsMet = +this.orderWithPayment.payment.promotional_discount > 0;
      if (this.promoCodeApplied) {
        this.promoConditionsMetChange.emit(this.conditionsMet);
      }
    }
    this.permissionService.pinCurrentOperator.pipe(takeUntil(this.destroySubject)).subscribe((permission) => {
      this.permission = permission;
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orderWithPayment) {
      this.orderWithPayment = changes.orderWithPayment.currentValue;
    }
  }


  updateQuantity(item: Order) {
    if (item.quantity <= 0) {
      this.deleteItem(item);
    } else {
      this.orderService.updateQuantity(this.orderHash, item.id, item.quantity).pipe(takeUntil(this.destroySubject)).subscribe(data => {
        // this.ngOnInit();
        this.quantityChange.emit(1);
      });
    }
  }

  checkPOSPermissionModify(item: Order) {
    console.log('this.orderWithPayment.bot_order.has_submitted_tab::::', this.orderWithPayment.bot_order.has_submitted_tab);
    console.log('this.orderWithPayment.bot_order.is_submitted::::', this.orderWithPayment.bot_order.is_submitted);
    if ((!this.orderWithPayment.bot_order.is_submitted && this.permission.hasPermission('POS_FUNCTIONS', 'MODIFY'))
      || (!this.orderWithPayment.bot_order.has_submitted_tab && this.permission.hasPermission('POS_FUNCTIONS', 'MODIFY'))) {
      this.updateQuantity(item);
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(PinConfirmComponent, {
        width: isMobile ? '100vw' : '500px',
        height: isMobile ? '100vh' : '410px',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        if (result.cancel === 'canceled') {
          const oldItem = this.localItemsCopy.find(x => x.id === item.id);
          this.updateQuantity(oldItem);
        } else {
          this.pinPermission = result.permission;
          if (this.pinPermission.hasPermission('POS_FUNCTIONS', 'MODIFY')) {
            this.updateQuantity(item);
            this.orderService.updateDescription(this.orderWithPayment.bot_order.id, {description: result.description}).pipe(takeUntil(this.destroySubject)).subscribe((updated) => {
              console.log('________________', updated);
            });
          } else {
            const dialogRef = this.dialog.open(AlertsMessagesComponent, {
              disableClose: true,
              width: '364px',
              minHeight: '20vh',
              data: {
                message: 'This User/Manager does not have the required permission to perform this operation!'
              }
            });
            dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
              if (result) {
                const oldItem = this.localItemsCopy.find(x => x.id === item.id);
                this.updateQuantity(oldItem);
              }
            });
            // alert('This User/Manager does not have the required permission to perform this operation!' );

          }
        }
      });
    }
  }

  deleteItem(item: Order) {
    this.orderService.deleteItem(this.orderHash, item.id).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.quantityChange.emit(1);
      this.orderService.removeItemUpdateMenuTab.next(true);
    });
  }

  onSelect(item: Order) {
    console.log(item);
    this.orderService.editOrderSubject.next(item);
    this.posSummaryToggle.toggle('OPEN');
    // this.posSummaryToggle.toggle('CLOSE');
  }

  onMiscSelect(item) {
    console.log(item);
    this.orderService.editMiscSubject.next(item);
    this.posSummaryToggle.toggle('CLOSE');
  }

  goBack() {
    this.router.navigate(['order', this.orderHash, 'store', this.orderWithPayment.store.id]);
  }


}
