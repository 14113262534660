
<div class="login-body">
    <mat-toolbar class="mat-elevation-z7" style="z-index:2; background-color: white">
      <a [routerLink]="['/home/dashboard']" routerLinkActive="is-active">
        <picture class="h-12">
          <source srcset="../../assets/logo_sm.jpg" media="(min-width: 300px) and (max-width: 576px)">
          <source srcset="../../assets/logo.jpg" media="(min-width: 576px)">
          <img srcset="../../assets/logo.jpg" alt="Labrador AI" class="h-12">
        </picture>
      </a>
    </mat-toolbar>
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="login-form">
      <p class="heading">Log into your account</p>
      <div class="form-group">
        <input type="text" formControlName="username" class="custom-input" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
          placeholder="Email">
      </div>
      <div class="form-group">
        <!-- <input type="password" formControlName="password" class="custom-input" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
          placeholder="Password"> -->
        <div style="position: relative;">
          <input type="{{hide ? 'password' : 'text'}}" formControlName="password" class="custom-input"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password">
          <div class="eye-pass">
            <mat-icon matSuffix (click)="togglePasswordVisibility()">{{hide ? 'visibility_off' :
              'visibility'}}</mat-icon>
          </div>
        </div>
      </div>
      <div *ngIf="error" class="alert alert-danger">Invalid login credentials</div>
      <div class="form-group remember-me">
        <input class="remember-me-checkbox" formControlName="rememberMe" cla type="checkbox" />
        <span style="color: white;">Remember me</span>
      </div>
      <div class="form-group" style="padding:10px;">
          <button type="submit">Login</button>
          <a mat-button href='/login/password-reset' style="color: white;">I forgot my password 🤦‍♀️</a>
        </div>
    </div>
  </form>
</div>
