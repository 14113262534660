<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="order-container w-full overflow-x-auto py-1.5 px-5" *ngIf="isOpenOrders">
  <table mat-table multiTemplateDataRows width="100%" matSort matSortActive="order_counter" matSortDirection="desc"
    matSortDisableClear [dataSource]="dataSource" class="oreder-tb-hisrtory dark-bg-color">

    <ng-container matColumnDef="ready">
      <th mat-header-cell *matHeaderCellDef> Ready </th>
      <td mat-cell *matCellDef="let element" data-label="Name">
        <button mat-flat-button class="view-btn" color="primary" (click)="timeSelect(element)"
          *ngIf="!element.ready_notified && !element.view_notified">View
        </button>
        <button mat-flat-button class="ready-btn" (click)="ready(element)"
          *ngIf="!element.ready_notified && element.view_notified">Ready</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="order_name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element" data-label="Name"> {{ element.order_name }} </td>
    </ng-container>

    <ng-container matColumnDef="placed">
      <th mat-header-cell *matHeaderCellDef> Created Date </th>
      <td mat-cell *matCellDef="let element" data-label="SubmitDate">
        {{ element.submitted_at | date: 'hh:mm a MM/dd/yyyy' }} </td>
    </ng-container>

    <ng-container matColumnDef="order_counter">
      <th mat-header-cell *matHeaderCellDef> Order #</th>
      <td mat-cell *matCellDef="let element" data-label="Order"> {{element.order_counter}} </td>
    </ng-container>

    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef>Source</th>
      <td mat-cell *matCellDef="let element" data-label="Source">
        <p class="pill blue-pill" *ngIf="!element.is_pos && element.occasion == 'TABLESIDE'">
          {{element.dine_in_table_number}}</p>
        <p class="pill blue-pill" *ngIf="!element.is_pos && element.occasion != 'TABLESIDE'">ONLINE</p>
        <p class="pill blue-pill" *ngIf="element.is_pos">POS</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="cashier_id">
      <th mat-header-cell *matHeaderCellDef> Server Name</th>
      <td mat-cell *matCellDef="let element" data-label="Server Name"> {{element.cashier_id}} </td>
    </ng-container>

    <ng-container matColumnDef="terminal_id">
      <th mat-header-cell *matHeaderCellDef> Station ID</th>
      <td mat-cell *matCellDef="let element" data-label="Station ID"> {{element.terminal_name == null || element.terminal_name == 0 ? (element.terminal_id == 0 ? '' : element.terminal_id): element.terminal_name}}</td>
    </ng-container>

    <ng-container matColumnDef="occasion">
      <th mat-header-cell *matHeaderCellDef>Occasion</th>
      <td mat-cell *matCellDef="let element" data-label="Occasion">
        <p class="pill grey-pill">{{element.occasion}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="payment_type">
      <th mat-header-cell *matHeaderCellDef>Payment</th>
      <td mat-cell *matCellDef="let element" data-label="Payment">
        <p class="pill grey-pill" *ngIf="element.payment_type">{{element.payment_type}}</p>
        <p class="pill red-pill" *ngIf="!element.payment_type">UNPAID</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Total</th>
      <td mat-cell *matCellDef="let element" data-label="Total"> {{element.total | currency:'USD'}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element" data-label="Status">
        <p class="pill blue-pill-filled">Open</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="table_id">
      <th mat-header-cell *matHeaderCellDef fxHide.lt-sm> Table ID </th>
      <td mat-cell *matCellDef="let element" data-label="Order" fxHide.lt-sm> {{element.dine_in_table_number}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <button mat-icon-button [matMenuTriggerFor]="actionMenu">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-menu-item (click)="print(element)">Reprint Order</button>
          <button mat-menu-item (click)="checkPOSPermission(element, '')">Cancel order</button>
          <button *ngIf="element.occasion === 'TABLESIDE'" mat-menu-item (click)="closeTab(element)">Close Tab</button>
          <button mat-menu-item *ngIf="element.is_paid_by_split" (click)="openSplitPayment(element)">Split
            Payment</button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" class="highlight">
        <div class="example-element-detail misc-detail" *ngIf="element"
          [@detailExpand]="element.id === expandedRowId ? 'expanded' : 'collapsed'">
          <mat-tab-group class="order-detailsss">
            <mat-tab label="ORDER DETAILS">
              <div class="grid grid-cols-4 gap-2 p-2 bg-gray-100 dark-bg-color">
                <div class="border border-gray-300 rounded-md p-2 bg-white dark-bg-color">
                  <app-order-details [orderWithPayment]="order"></app-order-details>
                </div>
                <div class="border border-gray-300 rounded-md p-2 bg-white dark-bg-color">
                  <app-order-item-breakup [orderWithPayment]="order"></app-order-item-breakup>
                </div>
                <div class="border border-gray-300 rounded-md p-2 bg-white dark-bg-color">
                  <app-order-payment-breakup [orderWithPayment]="order"></app-order-payment-breakup>
                </div>
                <div class="border border-gray-300 rounded-md p-2 bg-white dark-bg-color">
                  <button mat-flat-button color="primary" *ngIf="store.hasModule('POS')" class="action green"
                    (click)="edit()">Edit</button>
                  <button mat-flat-button color="primary" *ngIf="store.hasModule('POS')" class="action green"
                    (click)="checkout()">Checkout</button>
                  <button mat-flat-button color="primary" class="action" (click)="print(element)">Reprint</button>
                  <button mat-flat-button color="warn" class="action red"
                    (click)="checkPOSPermission(element, '')">Cancel</button>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Add miscellaneous">
              <div class="grid grid-cols-4 gap-2 p-2 bg-gray-100">
                <div class="border border-gray-300 rounded-md p-2 bg-white">
                  <app-order-item-breakup [orderWithPayment]="order"></app-order-item-breakup>
                </div>
                <div class="col-span-3 border border-gray-300 rounded-md p-2 bg-white">
                  <app-order-add-misc-items [order]="order" (refresh)="onRowClicked($event)"></app-order-add-misc-items>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="HISTORY">
              <div class="tab-content">
                <div class="section-fluid">
                  <ng-template matTabContent>
                    <app-order-transaction-history [order]="order"></app-order-transaction-history>
                  </ng-template>
                </div>
              </div>
            </mat-tab>

          </mat-tab-group>
        </div>
      </td>
    </ng-container>
    <!-- <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="openOrderRowClicked(row)">
    </tr> -->

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="onRowClicked(row,0)"
        [ngClass]="{ '!bg-gray-100 !blur-sm': expandedRowId && expandedRowId !== row.id }"
      [class.example-expanded-row]="expandedRowId === row.id"
      (click)="expandedRowId = expandedRowId === row.id ? null : row.id">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <mat-paginator [length]="dataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>
</div>

<div class="order-container w-full overflow-x-auto py-1.5 px-5" *ngIf="!isOpenOrders">
  <table mat-table multiTemplateDataRows width="100%" matSort matSortActive="order_counter" matSortDirection="desc"
    matSortDisableClear [dataSource]="dataSource" class="dark-bg-color">
    <ng-container matColumnDef="ready">
      <th mat-header-cell *matHeaderCellDef> Ready </th>
      <td mat-cell *matCellDef="let element" data-label="Name">
        <button mat-flat-button class="view-btn" color="primary" (click)="timeSelect(element)"
          *ngIf="!element.ready_notified && !element.view_notified">View
        </button>
        <button mat-flat-button class="ready-btn" (click)="ready(element)"
          *ngIf="!element.ready_notified && element.view_notified">Ready</button>
        <button mat-flat-button disabled *ngIf="element.ready_notified">Done</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="updated_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Placed </th>
      <td mat-cell *matCellDef="let element" data-label="Placed"> {{ formatDate(element.submitted_at)}} </td>
    </ng-container>

    <ng-container matColumnDef="order_counter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order </th>
      <td mat-cell *matCellDef="let element" data-label="Order"> {{element.order_counter}} </td>
    </ng-container>

    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
      <td mat-cell *matCellDef="let element" data-label="Source">
        <p class="pill blue-pill" *ngIf="!element.is_pos">ONLINE</p>
        <p class="pill blue-pill" *ngIf="element.is_pos">POS</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="occasion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Occasion </th>
      <td mat-cell *matCellDef="let element" data-label="Occasion">
        <p class="pill grey-pill" *ngIf="element.occasion == 1">{{'PICKUP'}}</p>
        <p class="pill grey-pill" *ngIf="element.occasion == 2">{{'DINE-IN'}}</p>
        <p class="pill grey-pill" *ngIf="element.occasion == 3">{{'DELIVERY'}}</p>
        <p class="pill grey-pill" *ngIf="element.occasion == 4">{{'CURBSIDE'}}</p>
        <p class="pill grey-pill" *ngIf="element.occasion == 5">{{'TABLESIDE'}}</p>
        <p class="pill grey-pill" *ngIf="element.occasion == 6">{{'TO-GO'}}</p>
        <p class="pill grey-pill" *ngIf="element.occasion == 7">{{'FOR-HERE'}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="table_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm> Table ID </th>
      <td mat-cell *matCellDef="let element" data-label="TableID" fxHide.lt-sm> {{ element.dine_in_table_number == 0 ?
        '' : element.dine_in_table_number }}</td>
    </ng-container>

    <ng-container matColumnDef="payment_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm> Payment </th>
      <td mat-cell *matCellDef="let element" data-label="Payment" fxHide.lt-sm> {{element.payment_type_raw | titlecase}}
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
      <td mat-cell *matCellDef="let element" data-label="Total">
        <span>
          ${{element.total}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="payment_status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element" data-label="Status">
        <div *ngIf="element.is_submitted && !element.is_cancelled && !element.is_split_cancelled; then submitted else cancelled"></div>
        <ng-template #submitted>
          <p class="pill green-pill my-2" *ngIf="element.is_submitted">Closed</p>
          <p class="pill grey-pill my-2" *ngIf="element.is_submitted && element.has_adjustment"
            matTooltip="Adjusted {{element.adjusted_total | currency: 'USD'}}">Adjusted</p>
          <p class="pill green-pill my-2" *ngIf="element.tip > '0.00'" matTooltip="Tip Added {{element.tip | currency: 'USD'}}">Tip Added</p>
          <p class="pill red-pill my-2" matTooltip="Refunded {{element.refund_total | currency: 'USD'}}"
            *ngIf="element.is_submitted && element.has_refund">Refunded</p>
          <p class="pill red-pill my-2" *ngIf="element.is_submitted && element.refund_pending">Pending</p>
        </ng-template>
        <ng-template #cancelled>
          <p class="pill red-pill my-2" *ngIf="element.is_cancelled || element.is_split_cancelled">Cancelled</p>
          <p class="pill red-pill my-2" matTooltip="Refunded {{element.refund_total | currency: 'USD'}}"
            *ngIf="element.is_submitted && element.has_refund">Refunded</p>
          <p class="pill red-pill my-2" *ngIf="element.is_submitted && element.refund_pending">Pending</p>
        </ng-template>

      </td>
    </ng-container> 
    <ng-container matColumnDef="cashier_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm>Server Name</th>
      <td mat-cell *matCellDef="let element" data-label="Server Name" fxHide.lt-sm> {{element.cashier_id == 0 ? '' :
        element.cashier_id}} </td>
    </ng-container>
    <ng-container matColumnDef="terminal_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm>Station ID</th>
      <td mat-cell *matCellDef="let element" data-label="Server Name" fxHide.lt-sm>
        <span *ngIf="element.is_pos">{{element.terminal_name == null || element.terminal_name == 0 ? (element.terminal_id == 0 ? '' : element.terminal_id): element.terminal_name}}</span>
        <span *ngIf="!element.is_pos"></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="order_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm>Order Name</th>
      <td mat-cell *matCellDef="let element" data-label="Server Name" fxHide.lt-sm> {{element.order_name}} </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" class="highlight expaanded-back">
        <div class="example-element-detail misc-detail" *ngIf="element"
          [@detailExpand]="element.id === expandedRowId ? 'expanded' : 'collapsed'">
          <mat-tab-group class="order-detailsss">
            <mat-tab label="Order Details">
              <!-- <div class="grid grid-cols-5 gap-2 p-2 bg-gray-100"> -->
              <div class="flex flex-wrap grid-cols-5 gap-2 p-2 bg-gray-100 dark-bg-color">
                <div fxFlex="19" fxFlex.sm="30" fxFlex.xs="48"  class="border border-gray-300 rounded-md p-2 bg-white max-w1 dark-bg-color">
                  <app-order-details [orderWithPayment]="order"></app-order-details>
                </div>
                <div fxFlex="19" fxFlex.sm="30" fxFlex.xs="48" class="border-gray-300 rounded-md bg-white p-2 max-w2 dark-bg-color">
                  <app-order-item-breakup [orderWithPayment]="order"></app-order-item-breakup>
                </div>
                <div fxFlex="19" fxFlex.sm="30" fxFlex.xs="48" class="border border-gray-300 rounded-md bg-white p-2 max-w3 dark-bg-color">
                  <app-order-payment-breakup [orderWithPayment]="order"></app-order-payment-breakup>
                </div>
                <div fxFlex="19" fxFlex.sm="30" fxFlex.xs="48" class="border border-gray-300 rounded-md bg-white p-2 max-w4 dark-bg-color">
                  <mat-progress-bar mode="indeterminate" *ngIf="isPaymentLoading"></mat-progress-bar>
                  <form [formGroup]="paymentForm" (ngSubmit)="checkPermissions()" class="payment-form" #f="ngForm">
                    <!-- Add a hidden input field for split_id -->
                    <!-- <input type="hidden" formControlName="split_id" [value]="selectedSplitRow?.id"> -->
                    <div class="row mb-3.5">
                      <button mat-flat-button color="primary" type="submit" class="submit !h-11"
                        [disabled]="isPaymentLoading || !paymentForm.valid || !walletInsufficient ">Submit</button>
                      <p *ngIf="paymentFailed" class="error">Unable to process payment. </p>
                      <p *ngIf="paymentSuccess" class="success">Payment processed successfully.</p>
                    </div>
                    <div class="row">
                      <mat-form-field appearance="fill" class="card-field">
                        <mat-label>Select Card</mat-label>
                        <mat-select formControlName="history_id">
                          <mat-option [value]="h.id" *ngFor="let h of history">
                            {{ '***' + (h.card_last_4 && h.card_last_4 !== 'NA' ? ' ' + h.card_last_4 : '') + (h.name_on_card && h.name_on_card !== 'NA' ? ' ' + h.name_on_card : '') + (h.amount && h.amount !== 'NA' ? ' $' + h.amount : '') + (h.payment_type && h.payment_type !== 'NA' ? ' ' + h.payment_type : '') }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-button-toggle-group formControlName="payment_type"
                        (change)="onPaymentTypeChange($event.value)" class="w-full">
                        <mat-button-toggle [disabled]="storeCredit == 0" value="ADJUST" class="pos">Adjust
                          Up</mat-button-toggle>
                        <mat-button-toggle [disabled]="storeCredit == 0" value="TIP_ADJUST" class="pos">Add
                          Tip</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                    <div class="row">
                      <mat-form-field appearance="fill" class="amount1-field">
                        <mat-label>Amount</mat-label>
                        <input matInput placeholder="Amount" formControlName="amount">
                        <mat-icon matSuffix>attach_money</mat-icon>
                        <mat-hint>{{paymentHint}}</mat-hint>
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field appearance="fill" class="note-field">
                        <mat-label>Add note for record</mat-label>
                        <input matInput placeholder="Add note for record" formControlName="note">
                        <!-- <mat-hint>{{paymentHint}}</mat-hint> -->
                      </mat-form-field>
                    </div>
                  </form>
                </div>
                <div fxFlex="19" fxFlex.sm="30" fxFlex.xs="48" class="border border-gray-300 rounded-md bg-white p-2 max-w5 dark-bg-color">
                  <button mat-flat-button color="primary" class="action w-full pb-2" (click)="print(element)">Reprint</button>
                  <button mat-flat-button color="warn" [disabled]="element.is_cancelled == true"
                          class="w-full re-print red"
                          (click)="checkPOSPermission(element, '')">Cancel</button>
                </div> 
              </div>
            </mat-tab>
            <mat-tab label="History">
              <div class="tab-content">
                <div class="section-fluid">
                  <ng-template matTabContent>
                    <app-order-transaction-history [order]="order"></app-order-transaction-history>
                  </ng-template>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div class="example-element-detail misc-detail" *ngIf="element && element.payment_type_raw == 'SPLIT'"
          [@detailExpand]="element.id === expandedRowId ? 'expanded' : 'collapsed'">
          <table mat-table multiTemplateDataRows width="100%" [dataSource]="splitDataSource">
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef> Time </th>
              <td mat-cell *matCellDef="let ele"> {{ele.updated_at}} </td>
            </ng-container>
            <ng-container matColumnDef="payment_type">
              <th mat-header-cell *matHeaderCellDef> Payment Type </th>
              <td mat-cell *matCellDef="let ele"> {{ele.payment_type}} </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef> Amount </th>
              <td mat-cell *matCellDef="let ele"> {{ele.total}} </td>
            </ng-container>
            <ng-container matColumnDef="card_number">
              <th mat-header-cell *matHeaderCellDef> Card Number </th>
              <td mat-cell *matCellDef="let ele"> {{ele.last_4}} </td>
            </ng-container>
            <ng-container matColumnDef="name_on_card">
              <th mat-header-cell *matHeaderCellDef> Name On Card </th>
              <td mat-cell *matCellDef="let ele"> {{ele.name_on_card}} </td>
            </ng-container>
            <ng-container matColumnDef="transaction_id">
              <th mat-header-cell *matHeaderCellDef> Transaction ID </th>
              <td mat-cell *matCellDef="let ele"> {{ele.transaction_id}} </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetails">
              <td mat-cell *matCellDef="let ele" [attr.colspan]="columnsToSplitDisplay.length"
                class="highlight expaanded-back">
                <div class="example-element-detail misc-detail" *ngIf="ele"
                  [@detailExpand]="ele.id === expandedSplitRowId ? 'expanded' : 'collapsed'">
                  <mat-tab-group>
                    <mat-tab label="Order Details">
                      <div class="tab-content">
                        <div class="section">
                          <app-order-details [orderWithPayment]="order"></app-order-details>
                        </div>
                        <div class="section">
                          <app-order-item-breakup [orderWithPayment]="order" [splitItems]="splitItems"></app-order-item-breakup>
                        </div>
                        <div class="section">
                          <app-order-payment-breakup [orderWithPayment]="order" [splitRow]="splitRow"></app-order-payment-breakup>
                        </div>
                        <div class="section">
                          <mat-progress-bar mode="indeterminate" *ngIf="isPaymentLoading"></mat-progress-bar>
                          <form [formGroup]="paymentForm" (ngSubmit)="checkPermissions()" class="payment-form"
                            #f="ngForm">
                            <!-- Add a hidden input field for split_id -->
                            <input type="hidden" formControlName="split_id" [value]="selectedSplitRow?.id">

                            <div class="row">
                              <mat-form-field appearance="fill" class="card-field">
                                <mat-label>Select Card</mat-label>
                                <mat-select formControlName="history_id">
                                  <mat-option [value]="h.id" *ngFor="let h of history">
                                    {{ '***' + (h.card_last_4 && h.card_last_4 !== 'NA' ? ' ' + h.card_last_4 : '') + (h.name_on_card && h.name_on_card !== 'NA' ? ' ' + h.name_on_card : '') + (h.amount && h.amount !== 'NA' ? ' $' + h.amount : '') + (h.payment_type && h.payment_type !== 'NA' ? ' ' + h.payment_type : '') }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="row">
                              <mat-button-toggle-group formControlName="payment_type"
                                (change)="onPaymentTypeChange($event.value)" class="w-full">
                                <mat-button-toggle [disabled]="storeCredit == 0" value="ADJUST" class="pos">Adjust
                                  Up</mat-button-toggle>
                                <mat-button-toggle [disabled]="storeCredit == 0" value="TIP_ADJUST" class="pos">Add
                                  Tip</mat-button-toggle>
                              </mat-button-toggle-group>
                            </div>
                            <div class="row">
                              <mat-form-field appearance="fill" class="amount1-field">
                                <mat-label>Amount</mat-label>
                                <input matInput placeholder="Amount" formControlName="amount">
                                <mat-icon matSuffix>attach_money</mat-icon>
                                <mat-hint>{{paymentHint}}</mat-hint>
                              </mat-form-field>
                            </div>
                            <div class="row">
                              <mat-form-field appearance="fill" class="note-field">
                                <mat-label>Add note for record</mat-label>
                                <input matInput placeholder="Add note for record" formControlName="note">
                              </mat-form-field>
                            </div>

                            <div class="row">
                              <button mat-flat-button color="primary" type="submit" class="submit"
                                [disabled]="isPaymentLoading || !paymentForm.valid || !walletInsufficient ">Submit</button>
                              <p *ngIf="paymentFailed" class="error">Unable to process payment. </p>
                              <p *ngIf="paymentSuccess" class="success">Payment processed successfully.</p>
                            </div>
                          </form>
                        </div>
                        <div class="section">
                          <button mat-flat-button color="primary" class="action"
                            (click)="printSplitReceipt(element,ele)">Reprint</button>
                          <button mat-flat-button color="warn" [disabled]="ele.is_cancelled"
                            class="action red" (click)="checkPOSPermission(element, splitItems)">Cancel</button>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="History">
                      <div class="tab-content">
                        <div class="section-fluid">
                          <ng-template matTabContent>
                            <app-order-transaction-history [order]="order"  [fromData]="paymentForm.value"></app-order-transaction-history>
                          </ng-template>
                        </div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToSplitDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToSplitDisplay;"
              (click)="onSplitRowClicked(element.order_hash, row)"
              [ngClass]="{ 'highlight-order-row': row?.id === selectedSplitRow?.id }"
              [class.example-expanded-row]="expandedSplitRowId === row.id"
              (click)="expandedSplitRowId = expandedSplitRowId === row.id ? null : row.id">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetails']" class="example-detail-row"></tr>
          </table>
        </div>

      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="onRowClicked(row,1)"
      [ngClass]="{ '!bg-gray-100 !blur-sm': expandedRowId && expandedRowId !== row.id }"
      [class.example-expanded-row]="expandedRowId === row.id"
      (click)="expandedRowId = expandedRowId === row.id ? null : row.id">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <mat-paginator [length]="dataSource.getTotal()" [pageSize]="10" [pageSizeOptions]="[10,20,50]"></mat-paginator>
</div>
