<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="order-container manage-user pad-2 full-y-width xs:!overflow-x-scroll xs:m-auto" *ngIf="!isLoading">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows width="100%">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element">{{element.first_name}} {{element.last_name}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">{{element.username}}</td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let element">{{element.user_profile.role.role_name | titlecase}}</td>
    </ng-container>

    <ng-container matColumnDef="pin">
      <th mat-header-cell *matHeaderCellDef>PIN</th>
      <td mat-cell *matCellDef="let element">{{element.has_pin}}</td>
    </ng-container>

    <ng-container matColumnDef="last_login">
      <th mat-header-cell *matHeaderCellDef>Last login</th>
      <td mat-cell *matCellDef="let element">{{element.last_login | date:'short'}}</td>
    </ng-container>

    <ng-container matColumnDef="star">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="clean-icon">
        <mat-menu #operations="matMenu">
          <button [disabled]="element.user_profile.role.role_name === 'MASTER_ADMIN'" mat-menu-item
            (click)="deleteUser(element)">Delete</button>
        </mat-menu>

        <button *ngIf="element.type != 'add'" mat-icon-button [matMenuTriggerFor]="operations">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <form class="form" [formGroup]="element.form" (ngSubmit)="updateUser(element.form.value)">
            <div class="form-row flex-wrap">
              <mat-form-field class="xs!:min-w-[130px] mr-2">
                <input matInput placeholder="First Name" formControlName="first_name" autofocus required>
              </mat-form-field>
              <mat-form-field class="xs!:min-w-[130px] mr-2">
                <input matInput placeholder="Last Name" formControlName="last_name" required>
              </mat-form-field>
              <mat-form-field class="xs!:min-w-[130px] mr-2">
                <input matInput placeholder="Email" formControlName="username" required>
              </mat-form-field>

            </div>
            <div class="form-row flex-wrap">
              <div formGroupName="user_profile">
                <mat-form-field class="xs!:min-w-[130px]  mr-2">
                  <input matInput placeholder="Phone Number" formControlName="phone"
                    [value]="element.user_profile.phone || '' | phone" appPhoneMask required>
                </mat-form-field>
                <mat-form-field class="xs!:min-w-[130px]  mr-2">
                  <!-- <mat-label></mat-label> -->
                  <mat-select formControlName="role" placeholder="Role"
                    (selectionChange)="addRole($event.value,element.form.value)">
                    <mat-option *ngFor="let r of roles" [value]="r.role_name" (click)="newClick(r.role_name, element.form.value)">{{r.role_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="xs!:min-w-[130px]  mr-2">
                  <input matInput placeholder="Hourly Rate" formControlName="hourly_rate"
                    [value]="element.user_profile.hourly_rate || '0' | currency">
                </mat-form-field>
                <mat-slide-toggle class="mr-2 w-[5%] xs:ml-3" formControlName="has_pin" #hasPin
                  (change)="disablePin(element.form)"></mat-slide-toggle>

                <mat-form-field class="width-20">
                  <input matInput formControlName="pin" placeholder="PIN" maxlength="4">
                </mat-form-field>
              </div>
            </div>

            <div class="form-row">

              <mat-form-field class="xs!:min-w-[130px] mr-2">
                <input type="password" matInput placeholder="Password" formControlName="password" required>
              </mat-form-field>

              <mat-form-field class="xs!:min-w-[130px] mr-2">
                <input type="password" matInput placeholder="Confirm Password" formControlName="confirm_password"
                  required>
              </mat-form-field>
            </div>

            <div class="action flex flex-row w-full">
              <div class="w-1/2">
                <button mat-stroked-button (click)="managePermission(element)" type="button" class="pull-left">Manage
                  Permissions</button>
              </div>
              <div class="w-1/2" >
                <button mat-stroked-button (click)="expandedElement = null; false">Cancel</button>
                <button mat-flat-button color="primary" type="submit" class="apply-btn-colr"
                  *ngIf="element.type == 'update'" [disabled]="!element.form.valid">Update</button>
                <button mat-flat-button color="primary" type="submit" *ngIf="element.type == 'add'"
                  [disabled]="!element.form.valid">Add</button>
              </div>
            </div>
          </form>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>