import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatTableExporterModule } from 'mat-table-exporter';
import { OrdersComponent } from './orders/orders.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MessageTableComponent } from './orders/message-table/message-table.component';
import { UserService } from './user.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { LoginComponent } from './login/login.component';
import { BusinessService } from './business.service';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { ReceiptComponent } from './orders/receipt/receipt.component';
import { ReceiptService } from './_services/receipt.service';
import { OrderService } from './_services/order.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { StoreService } from './_services/store.service';
import { AccountComponent } from './account/account.component';
import { SupportComponent } from './support/support.component';
import { InfoComponent } from './info/info.component';
import { RefundDialogComponent } from './orders/refund-dialog/refund-dialog.component';
import { AdjustDialogComponent } from './orders/adjust-dialog/adjust-dialog.component';
import { PaymentService } from './_services/payment.service';
import { StoreSettingsComponent } from './info/store-settings/store-settings.component';
import { StoreInfoComponent } from './info/store-info/store-info.component';
import { EditDialogComponent as StoreInfoEditDialogComponent } from './info/edit-dialog/edit-dialog.component';
import { DynamicFormComponent } from './info/edit-dialog/dynamic-form/dynamic-form.component';
import { DynamicFormControlComponent } from './info/edit-dialog/dynamic-form/dynamic-form-control/dynamic-form-control.component';
import { WorkingHoursComponent } from './info/edit-dialog/dynamic-form/working-hours/working-hours.component';
import { PhonePipe } from './_pipes/phone.pipe';
import { PhoneMaskDirective } from './_directives/phone-mask-directive';
import { GoogleChartsModule } from 'angular-google-charts';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardService } from './_services/dashboard.service';
import { EditDialogComponent as AccountEditDialogComponent } from './account/edit-dialog/edit-dialog.component';
import { VarDirective } from './_directives/var-directive';
import { FormUtils } from './_helpers/form-utils';
import { UploadDialogComponent } from './account/upload-dialog/upload-dialog.component';
import { UploadService } from './_services/upload.service';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { PasswordRestConfirmComponent } from './login/password-rest-confirm/password-rest-confirm.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { VerifyPhoneComponent } from './info/verify-phone/verify-phone.component';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { StoreMenuComponent } from './info/store-menu/store-menu.component';
import { MenuCategoryComponent } from './info/store-menu/menu-category/menu-category.component';
import { ModGroupsComponent } from './info/store-menu/mod-groups/mod-groups.component';
import { AssignModsComponent } from './info/store-menu/assign-mods/assign-mods.component';
import { MenuService } from './_services/menu.service';
import { AddNewComponent } from './info/store-menu/menu-category/add-new/add-new.component';
import { MenuServiceV2 } from './_services/menu-v2.service';
import { AddNewModifierGroupComponent } from './info/store-menu/mod-groups/add-new-modifier-group/add-new-modifier-group.component';
import { ModListComponent } from './info/store-menu/assign-mods/mod-list/mod-list.component';
import { SnackBarComponent } from './loader/snack-bar/snack-bar.component';
import { ProgressSpinnerComponent } from './loader/progress-spinner/progress-spinner.component';
import { LoaderService } from './_services/loader.service';
import { LoaderInterceptor } from './_helpers/loader.interceptor';
import { StripeComponent } from './account/stripe/stripe.component';
import { OffersComponent } from './offers/offers.component';
import { InsightsComponent } from './insights/insights.component';
import { OffersMessageTableComponent } from './offers/offers-message-table/offers-message-table.component';
import { SkillComponent } from './info/skill/skill.component';
import { MessageComponent } from './message/message.component';
import { ContactListComponent } from './message/contact-list/contact-list.component';
import { MessagesViewComponent } from './message/messages-view/messages-view.component';
import { UnreadService } from './_services/unread-message.service';
import { EditContactComponent } from './message/edit-contact/edit-contact.component';
import { ModMenuItemComponent } from './info/store-menu/assign-mods/mod-menu-item/mod-menu-item.component';
import { ModifiersComponent } from './info/store-menu/mod-groups/modifiers/modifiers.component';
import { CampaignComponent } from './campaign/campaign.component';
import { CampaignMessageTableComponent } from './campaign/campaign-message-table/campaign-message-table.component';
import { CampaignDialogComponent } from './campaign/campaign-dialog/campaign-dialog.component';
import { ReportComponent } from './dashboard/report/report.component';
import { InsightComponent } from './dashboard/insight/insight.component';
import { ReportDialogComponent } from './dashboard/report/report-dialog/report-dialog.component';
import { MiniReportComponent } from './dashboard/mini-report/mini-report.component';
import { MenuItemGroupComponent } from './info/store-menu/menu-category/menu-item-group/menu-item-group.component';
import { VisualMenuComponent } from './account/visual-menu/visual-menu.component';
import { HostedMenuService } from './_services/hosted-menu.service';
import { StoreLeadTimeComponent } from './info/store-lead-time/store-lead-time.component';
import { MenuCategoryImageComponent } from './info/store-menu/menu-category/menu-category-image/menu-category-image.component';
import { FileUploadModule } from 'ng2-file-upload';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { NiceDateFormatPipe } from './_pipes/nice-date-format.pipe';
import { UsersComponent } from './users/users.component';
import { ContactComponent } from './campaign/contact/contact.component';
import { ContactActiveTableComponent } from './campaign/contact/contact-active-table/contact-active-table.component';
import { ContactDialogComponent } from './campaign/contact/contact-dialog/contact-dialog.component';
import { OpenTabComponent } from './orders/open-tab/open-tab.component';
import { AttendantComponent } from './attendant/attendant.component';
import { SettingsComponent } from './attendant/settings/settings.component';
import { ActionsComponent } from './attendant/actions/actions.component';
import { AddActionComponent } from './attendant/add-action/add-action.component';
import { EditSettingComponent } from './attendant/edit-setting/edit-setting.component';
import { AttendantSettingsService } from './_services/attendant-settings.service';
import { OrderAppIframeComponent } from './orders/order-app-iframe/order-app-iframe.component';
import { CounterService } from './_services/counter.service';
import { StoreSettingPrintersComponent } from './info/store-settings/store-setting-printers/store-setting-printers.component';
import { PrinterSettingsService } from './_services/printer-settings.service';
import { EditPrinterComponent } from './info/store-settings/store-setting-printers/edit-printer/edit-printer.component';
import { TablesideComponent } from './info/tableside/tableside.component';
import { AutocompleteChipsComponent } from './autocomplete-chips/autocomplete-chips.component';
import { LastOrderBufferComponent } from './info/last-order-buffer/last-order-buffer.component';
import { StoreLogoComponent } from './account/store-logo/store-logo.component';
import { PhonePreviewComponent } from './campaign/phone-preview/phone-preview.component';
import { GiftCardsComponent } from './offers/gift-cards/gift-cards.component';
import { ProductMixComponent } from './insights/product-mix/product-mix.component';
import { GiftCardsMessageTableComponent } from './offers/gift-cards-message-table/gift-cards-message-table.component';
import { NewGiftCardsDialogComponent } from './offers/new-gift-cards-dialog/new-gift-cards-dialog.component';
import { GiftCardsPhonePreviewComponent } from './offers/gift-cards-phone-preview/gift-cards-phone-preview.component';
import { GiftCardsUploadImageComponent } from './offers/gift-cards-upload-image/gift-cards-upload-image.component';
import { OffersV2Component } from './offers-v2/offers-v2.component';
import { OfferListComponent } from './offers-v2/offer-list/offer-list.component';
import { OfferRedemptionListComponent } from './offers-v2/offer-redemption-list/offer-redemption-list.component';
import { OfferListMessageTableComponent } from './offers-v2/offer-list-message-table/offer-list-message-table.component';
import {
  OfferRedemptionListMessageTableComponent
} from './offers-v2/offer-redemption-list-message-table/offer-redemption-list-message-table.component';
import { NewOfferDialogComponent } from './offers-v2/new-offer-dialog/new-offer-dialog.component';
import { PortionCategoryItemComponent } from './offers-v2/portion-category-item/portion-category-item.component';
import {
  ItemAutoCompleteChipsComponent
} from './offers-v2/portion-category-item/item-auto-complete-chips/item-auto-complete-chips.component';
import { SpecialHoursComponent } from './info/special-hours/special-hours.component';
import { ServiceAreaComponent } from './info/service-area/service-area.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { environment } from 'src/environments/environment';
import { AgmCoreModule } from '@agm/core';
import { DebounceClickDirective } from './_directives/debounce-click-directive';
import { BusinessLocationComponent } from './info/business-location/business-location.component';
import { ContactBulkImportDialogComponent } from './campaign/contact/contact-bulk-import-dialog/contact-bulk-import-dialog.component';
import { ContactFieldMappingDialogComponent } from './campaign/contact/contact-field-mapping-dialog/contact-field-mapping-dialog.component';
import { TransactionDialogComponent } from './orders/transaction-dialog/transaction-dialog.component';
import { NewOrderComponent } from './orders/new-order/new-order.component';
import { OrderSummaryComponent } from './orders/order-summary/order-summary.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OrderItemGridComponent } from './orders/order-item-grid/order-item-grid.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CheckoutDialogComponent } from './orders/checkout-dialog/checkout-dialog.component';
import { CashPaymentComponent } from './orders/cash-payment/cash-payment.component';
import { CardPaymentComponent } from './orders/card-payment/card-payment.component';
import { POSService } from './_services/pos-service';
import { PendingChangesGuard } from './_guards/changes.guard';
import { OccasionDetailService } from './_services/occasion-detail.service';
import { PaymentBreakdownComponent } from './orders/order-summary/payment-breakdown/payment-breakdown.component';
import { TipComponent } from './orders/tip/tip.component';
import { PosMenuComponent } from './orders/pos-menu/pos-menu.component';
import { ItemListComponent } from './orders/order-summary/item-list/item-list.component';
import { CalculatorComponent } from './orders/calculator/calculator.component';
import {
  MenuCategoryAvailabilityComponent
} from './info/store-menu/menu-category/menu-category-availability/menu-category-availability.component';
import {
  MenuCategoryAvailabilityDialogComponent
} from './info/store-menu/menu-category/menu-category-availability-dialog/menu-category-availability-dialog.component';
import { TerminalService } from './_services/terminal.service';
import { PermissionService } from './_services/permission.service';
import { PermissionDialogComponent } from './users/permission-dialog/permission-dialog.component';
import { CurrentOrderService } from './_services/current-order.service';
import { RefreshService } from './_services/refresh.service';
import { AddTipDialogComponent } from './orders/add-tip-dialog/add-tip-dialog.component';
import { CancelDialogComponent } from './orders/cancel-dialog/cancel-dialog.component';
import { POSOrderTabChangeService } from './_services/pos-order-tab-change.service';
import { POSMenuTabChangeService } from './_services/pos-menu-tab-change.service';
import { OpenOrderToggleService } from './_services/open-order-toggle.service';
import { POSSummaryToggleService } from './_services/pos-summary-toggle.service';
import { OrderTypeTabChangeService } from './_services/order-type-tab-change.service';
import { ManualCardDialogComponent } from './orders/manual-card-dialog/manual-card-dialog.component';
import { PaymentOptionsDialogComponent } from './orders/payment-options-dialog/payment-options-dialog.component';
import {
  PaymentOptionsBreakdownComponent
} from './orders/payment-options-dialog/payment-options-breakdown/payment-options-breakdown.component';
import { TipDialogComponent } from './orders/tip-dialog/tip-dialog.component';
import { CustomTipComponent } from './orders/tip-dialog/custom-tip/custom-tip.component';
import { JoinUserBaseComponent } from './orders/join-user-base/join-user-base.component';
import { CardPaymentConsumerChoiceComponent } from './orders/card-payment-consumer-choice/card-payment-consumer-choice.component';
import { DialogPosComponent } from './orders/dialog-pos/dialog-pos.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DefaultPosSettingComponent } from './info/default-pos-setting/default-pos-setting.component';
import { MenuItemSearchResultComponent } from './info/store-menu/menu-category/menu-item-search-result/menu-item-search-result.component';
import { NewRoleComponent } from './users/new-role/new-role.component';
import { TimeClockComponent } from './time-clock/time-clock.component';
import { TimeTrackerComponent } from './time-clock/time-tracker/time-tracker.component';
import { ConfirmationComponent } from './time-clock/confirmation/confirmation.component';
import { TimeReportComponent } from './time-clock/time-report/time-report.component';
import { EditTimeComponent } from './time-clock/edit-time/edit-time.component';
import { EditTimeClockComponent } from './time-clock/edit-time-clock/edit-time-clock.component';
import { EditRoleComponent } from './users/edit-role/edit-role.component';
import { RolesComponent } from './users/roles/roles.component';
import { SplitPaymentComponent } from './orders/split-payment/split-payment.component';
import { OtherPaymentComponent } from './orders/other-payment/other-payment.component';
import { PinConfirmComponent } from './orders/pin-confirm/pin-confirm.component';
import { MenuPiechartComponent } from './insights/product-mix/menu-piechart/menu-piechart.component';
import { FilterDialogComponent } from './insights/product-mix/filter-dialog/filter-dialog.component';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
import { GrossGraphComponent } from './insights/product-mix/gross-graph/gross-graph.component';
import { PosSettingsComponent } from './pos-settings/pos-settings.component';
import { SurchargeDetailPopupComponent } from './info/store-settings/surcharge-detail-popup/surcharge-detail-popup.component';
import { DashboardSalesTaxComponent } from './info/store-info/dashboard-sales-tax/dashboard-sales-tax.component';
import { HeaderSettingsComponent } from './info/store-settings/header-settings/header-settings.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GiftCardOrderComponent } from './dashboard/gift-card-order/gift-card-order.component';
import { GiftOrderIframeComponent } from './orders/gift-order-iframe/gift-order-iframe.component';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { ConfirmDialogComponent } from './orders/new-order/confirm-dialog/confirm-dialog.component';
import { OccasionAvailabilityComponent } from './info/edit-dialog/dynamic-form/occasion-availability/occasion-availability.component';
import { CustomBatchTimeComponent } from './pos-settings/custom-batch-time/custom-batch-time.component';
import { LoyaltyComponent } from './loyalty/loyalty.component';
import { LoyaltySettingsComponent } from './loyalty/loyalty-settings/loyalty-settings.component';
import { UserLoyaltyComponent } from './loyalty/user-loyalty/user-loyalty.component';
import { TotalUserChartComponent } from './loyalty/user-loyalty/total-user-chart/total-user-chart.component';
import { LoyaltyPointsService } from './_services/loyalty-points.service';
import { CardPaymentWsComponent } from './orders/card-payment-ws/card-payment-ws.component';
import { POSStreamService } from './_services/pos-stream.service';
import { TerminalStatusService } from './_services/terminal-status.service';
import { OrderV2Component } from './order-v2/order-v2.component';
import { OrderTableComponent } from './order-v2/order-table/order-table.component';
import { OrderPaymentBreakupComponent } from './order-v2/order-table/order-payment-breakup/order-payment-breakup.component';
import { OrderItemBreakupComponent } from './order-v2/order-table/order-item-breakup/order-item-breakup.component';
import { OrderAddMiscItemsComponent } from './order-v2/order-table/order-add-misc-items/order-add-misc-items.component';
import { OrderTransactionHistoryComponent } from './order-v2/order-table/order-transaction-history/order-transaction-history.component';
import { OrderFiltersComponent } from './order-v2/order-filters/order-filters.component';
import { HistoryRefundComponent } from './order-v2/order-table/history-refund/history-refund.component';
import { OrderAcceptanceDialogComponent } from './order-v2/order-table/order-acceptance-dialog/order-acceptance-dialog.component';
import {
  TimeAcceptanceOrderIframeComponent
} from './order-v2/order-table/time-acceptance-order-iframe/time-acceptance-order-iframe.component';
import { OrderDetailsComponent } from './order-v2/order-table/order-details/order-details.component';
import { OrderWebsocketService } from './_services/websocket.service';
import { SignaturePadComponent } from './orders/signature-pad/signature-pad.component';
import { PinVerifyPadComponent } from './orders/signature-pad/pin-verify-pad/pin-verify-pad.component';
import { CardPaymentMqttComponent } from './orders/card-payment-mqtt/card-payment-mqtt.component';
import { LinkMqttService } from './_services/mqtt.service';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { ManualPrintControlComponent } from './orders/order-summary/manual-print-control/manual-print-control.component';
import { CancelCardPaymentComponent } from './orders/cancel-card-payment/cancel-card-payment.component';
import { EditBreakTimeComponent } from './time-clock/edit-break-time/edit-break-time.component';
import { GiftCardHoldersComponent } from './offers/gift-card-holders/gift-card-holders.component';
import { TerminalStatusComponent } from './terminal-status/terminal-status.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { StoreFaviconComponent } from './account/store-favicon/store-favicon.component';
import { UploadFaviconDialogComponent } from './account/upload-favicon-dialog/upload-favicon-dialog.component';
import { DigitalDisplayComponent } from './digital-display/digital-display.component';
import { DDScreensComponent } from './digital-display/d-d-screens/d-d-screens.component';
import { DDCategoriesComponent } from './digital-display/d-d-categories/d-d-categories.component';
import { MenuCategoriesComponent } from './digital-display/menu-categories/menu-categories.component';
import { ScreensComponent } from './digital-display/screens/screens.component';
import { DigitalDisplayService } from './_services/digital-display.service';
import { DigitalScreensComponent } from './digital-display/digital-screens/digital-screens.component';
import { AddScreenDialogComponent } from './digital-display/add-screen-dialog/add-screen-dialog.component';
import { EditScreenDialogComponent } from './digital-display/edit-screen-dialog/edit-screen-dialog.component';
import { ScreenDeleteComponent } from './digital-display/screen-delete/screen-delete.component';
import { MasterCategoryDialogComponent } from './digital-display/master-category-dialog/master-category-dialog.component';
import { CategoryDialogComponent } from './digital-display/category-dialog/category-dialog.component';
import { SubCategoryDialogComponent } from './digital-display/sub-category-dialog/sub-category-dialog.component';
import { AddCategoriesDialogComponent } from './digital-display/add-categories-dialog/add-categories-dialog.component';
import { AddMasterCategoryDialogComponent } from './digital-display/add-master-category-dialog/add-master-category-dialog.component';
import { ScreenMediaComponent } from './digital-display/screen-media/screen-media.component';
import { ScreenMediaDialogComponent } from './digital-display/screen-media/screen-media-dialog/screen-media-dialog.component';
import { ScreenVideoComponent } from './digital-display/screen-video/screen-video.component';
import { AddVideoDialogComponent } from './digital-display/screen-video/add-video-dialog/add-video-dialog.component';
import { MenuCategorgDialogComponent } from './digital-display/menu-categorg-dialog/menu-categorg-dialog.component';
import {
  AllStoreEditMasterCategoryDialogComponent
} from './digital-display/all-store-edit-master-category-dialog/all-store-edit-master-category-dialog.component';
import { SubMenuCategoriesDialogComponent } from './digital-display/sub-menu-categories-dialog/sub-menu-categories-dialog.component';
import { RestaurantImagesComponent } from './digital-display/restaurant-images/restaurant-images.component';
import {
  RestaurantImagesDialogComponent
} from './digital-display/restaurant-images/restaurant-images-dialog/restaurant-images-dialog.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AddTimeClockComponent } from './time-clock/add-time-clock/add-time-clock.component';
import { DateTimeFormatDirective } from './_directives/date-time-format-directive';
import { GiftCardDefaulImageComponent } from './info/store-settings/gift-card-defaul-image/gift-card-defaul-image.component';
import { FrontFacingMqttService } from './_services/front-facing-mqtt.service';
import { OrderSubCatGridComponent } from './orders/order-sub-cat-grid/order-sub-cat-grid.component';
import { CampaignProgressComponent } from './campaign/campaign-progress/campaign-progress.component';
import { CampaignService } from './_services/campaign.service';
import { CampaignCountdownComponent } from './campaign/campaign-countdown/campaign-countdown.component';
import { CampaignCustomListComponent } from './campaign/campaign-custom-list/campaign-custom-list.component';
import {
  CampaignCustomListTableComponent
} from './campaign/campaign-custom-list/campaign-custom-list-table/campaign-custom-list-table.component';
import { AlertsMessagesComponent } from './alerts-messages/alerts-messages.component';
import { OrderReadySettingsComponent } from './info/store-settings/order-ready-settings/order-ready-settings.component';
// import { CustomErrorHandlerService } from './_helpers/error-handler';
// import { ErrorLoggingService } from './_services/error-logging.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FilterPipe } from './digital-display/filter.pipe';
import { AngMusicPlayerModule } from 'ang-music-player';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SettlementsComponent } from './settlements/settlements.component';
import { NMISettlementService } from './_services/nmi-settlement.service';
import { SelectSummaryToprintComponent } from './dashboard/select-summary-toprint/select-summary-toprint.component';
// import { PapaParseModule } from 'ngx-papaparse';
import { DashboardEmployeeTaxComponent } from './info/store-info/dashboard-employee-tax/dashboard-employee-tax.component';

// import { UserReferralComponent } from './referral/user-referral/user-referral.component';
import { DeliveriesDispatchComponent } from './orders/deliveries-dispatch/deliveries-dispatch.component';
import {DuplicateCallInterceptor} from './_helpers/duplicate-call.interceptor';
import {CustomErrorHandlerService} from './_helpers/error-handler';
import {ErrorLoggingService} from './_services/error-logging.service';
// import { DragDropModule } from '@angular/cdk/drag-drop';
// import { FilterPipe } from './digital-display/filter.pipe';
// import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { HolidaysComponent } from './holidays/holidays.component';
import { CashDrawerManagementComponent } from './orders/cash-drawer-management/cash-drawer-management.component';
import { CashDrawerConfirmComponent } from './orders/cash-drawer-management/cash-drawer-confirm/cash-drawer-confirm.component';
import { EndDrawerDetailComponent } from './orders/cash-drawer-management/end-drawer-detail/end-drawer-detail.component';
import { ManageDrawerCashLogsComponent } from './orders/manage-drawer-cash-logs/manage-drawer-cash-logs.component';
import { EndDrawerConfirmationComponent } from './orders/cash-drawer-management/end-drawer-confirmation/end-drawer-confirmation.component';
// import { PapaParseModule } from 'ngx-papaparse';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { FrontDisplayStatusComponent } from './front-display-status/front-display-status.component';
import { MenuItemImageComponent } from './info/store-menu/menu-category/menu-item-image/menu-item-image.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DefaultScreenDialogComponent } from './digital-display/default-screen-dialog/default-screen-dialog.component';
import { DefaultScreenComponent } from './digital-display/default-screen/default-screen.component';
import { ColumnManagementComponent } from './digital-display/column-management/column-management.component';
import { ColumnManagementDialogComponent } from './digital-display/column-management/column-management-dialog/column-management-dialog.component';
import { DefaultMenuCategoriesComponent } from './digital-display/default-menu-categories/default-menu-categories.component';
import { UpdateDefaultMenuDialogComponent } from './digital-display/default-menu-categories/update-default-menu-dialog/update-default-menu-dialog.component';
import { ShowScreenService } from './_services/show-screen.service';
import { TvScreenPreviewComponent } from './digital-display/column-management/tv-screen-preview/tv-screen-preview.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BarCodeScannerComponent } from './orders/bar-code-scanner/bar-code-scanner.component';
import { WorldnetDialogComponent } from './orders/worldnet-dialog/worldnet-dialog.component';
import {PaymentGatewayService} from './_services/payment-gateway.service';
import { ReferralComponent } from './referral/referral.component';
import { ReferralSettingsComponent } from './referral/referral-settings/referral-settings.component';
import { UserReferralComponent } from './referral/user-referral/user-referral.component';
import { RedemptionsComponent } from './referral/redemptions/redemptions.component';
import { CashierRedemptionsDialogComponent } from './referral/redemptions/cashier-redemptions-dialog/cashier-redemptions-dialog.component';
import { ReferralSettingsService } from './_services/referral-settings.service';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackDetailsComponent } from './feedback/feedback-details/feedback-details.component';
import { FeedbackDashboardComponent } from './feedback/feedback-dashboard/feedback-dashboard.component';
import { EditItemDialogComponent } from './orders/edit-item-dialog/edit-item-dialog.component';
import { EditModifierDialogComponent } from './orders/edit-modifier-dialog/edit-modifier-dialog.component';
import { RatingPieChartComponent } from './feedback/feedback-dashboard/rating-pie-chart/rating-pie-chart.component';
import { FloorPlanComponent } from './floor-plan/floor-plan.component';
import { AddNewFloorPlanComponent } from './floor-plan/add-new-floor-plan/add-new-floor-plan.component';
import { FloorPlanViewComponent } from './floor-plan/floor-plan-view/floor-plan-view.component';
import { AdvancedCssDialogComponent } from './digital-display/advanced-css-dialog/advanced-css-dialog.component';
export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'labrador-o58365.a01.euc1.aws.hivemq.cloud',
  port: 8884,
  protocol: 'wss',
  path: '/mqtt',
  clean: true,
  username: 'labrador',
  password: 'kUbS6EyzcXYU8R',
  connectTimeout: 4000,
  reconnectPeriod: 4000,
  clientId: 'labmq-business-' + localStorage.getItem('mqttsession')
};

@NgModule({
    declarations: [
        AppComponent,
        FilterPipe,
        OrdersComponent,
        SideNavComponent,
        MessageTableComponent,
        LoginComponent,
        LoginLayoutComponent,
        HomeLayoutComponent,
        ReceiptComponent,
        AccountComponent,
        SupportComponent,
        InfoComponent,
        RefundDialogComponent,
        AdjustDialogComponent,
        StoreSettingsComponent,
        StoreInfoComponent,
        StoreInfoEditDialogComponent,
        DynamicFormComponent,
        DynamicFormControlComponent,
        WorkingHoursComponent,
        PhonePipe,
        NiceDateFormatPipe,
        PhoneMaskDirective,
        DashboardComponent,
        AccountEditDialogComponent,
        VarDirective,
        UploadDialogComponent,
        UploadFaviconDialogComponent,
        ForgotPasswordComponent,
        PasswordRestConfirmComponent,
        ChangePasswordComponent,
        VerifyPhoneComponent,
        StoreMenuComponent,
        MenuCategoryComponent,
        ModGroupsComponent,
        AssignModsComponent,
        AddNewComponent,
        AddNewModifierGroupComponent,
        ModListComponent,
        SnackBarComponent,
        ProgressSpinnerComponent,
        StripeComponent,
        OffersComponent,
        InsightsComponent,
        OffersMessageTableComponent,
        SkillComponent,
        MessageComponent,
        ContactListComponent,
        MessagesViewComponent,
        EditContactComponent,
        ModMenuItemComponent,
        ModifiersComponent,
        CampaignComponent,
        CampaignMessageTableComponent,
        CampaignDialogComponent,
        ReportComponent,
        InsightComponent,
        ReportDialogComponent,
        MiniReportComponent,
        MenuItemGroupComponent,
        VisualMenuComponent,
        StoreLeadTimeComponent,
        MenuCategoryImageComponent,
        UsersComponent,
        ContactComponent,
        ContactActiveTableComponent,
        ContactDialogComponent,
        OpenTabComponent,
        AttendantComponent,
        SettingsComponent,
        ActionsComponent,
        AddActionComponent,
        EditSettingComponent,
        OrderAppIframeComponent,
        StoreSettingPrintersComponent,
        EditPrinterComponent,
        TablesideComponent,
        AutocompleteChipsComponent,
        LastOrderBufferComponent,
        StoreLogoComponent,
        PhonePreviewComponent,
        GiftCardsComponent,
        ProductMixComponent,
        GiftCardsMessageTableComponent,
        NewGiftCardsDialogComponent,
        GiftCardsPhonePreviewComponent,
        GiftCardsUploadImageComponent,
        OffersV2Component,
        OfferListComponent,
        OfferRedemptionListComponent,
        OfferListMessageTableComponent,
        OfferRedemptionListMessageTableComponent,
        NewOfferDialogComponent,
        PortionCategoryItemComponent,
        ItemAutoCompleteChipsComponent,
        SpecialHoursComponent,
        ServiceAreaComponent,
        DebounceClickDirective,
        BusinessLocationComponent,
        ContactBulkImportDialogComponent,
        ContactFieldMappingDialogComponent,
        TransactionDialogComponent,
        NewOrderComponent,
        OrderSummaryComponent,
        OrderItemGridComponent,
        CheckoutDialogComponent,
        CashPaymentComponent,
        CardPaymentComponent,
        PaymentBreakdownComponent,
        TipComponent,
        PosMenuComponent,
        ItemListComponent,
        CalculatorComponent,
        MenuCategoryAvailabilityComponent,
        MenuCategoryAvailabilityDialogComponent,
        PermissionDialogComponent,
        AddTipDialogComponent,
        CancelDialogComponent,
        ManualCardDialogComponent,
        PaymentOptionsDialogComponent,
        PaymentOptionsBreakdownComponent,
        TipDialogComponent,
        CustomTipComponent,
        JoinUserBaseComponent,
        CardPaymentConsumerChoiceComponent,
        DialogPosComponent,
        DefaultPosSettingComponent,
        MenuItemSearchResultComponent,
        NewRoleComponent,
        TimeClockComponent,
        TimeTrackerComponent,
        ConfirmationComponent,
        TimeReportComponent,
        EditTimeComponent,
        EditTimeClockComponent,
        EditRoleComponent,
        RolesComponent,
        SplitPaymentComponent,
        OtherPaymentComponent,
        PinConfirmComponent,
        MenuPiechartComponent,
        FilterDialogComponent,
        GrossGraphComponent,
        SurchargeDetailPopupComponent,
        DashboardSalesTaxComponent,
        HeaderSettingsComponent,
        PosSettingsComponent,
        GiftCardOrderComponent,
        GiftOrderIframeComponent,
        DashboardSalesTaxComponent,
        ConfirmDialogComponent,
        OccasionAvailabilityComponent,
        CardPaymentWsComponent,
        OrderV2Component,
        OrderTableComponent,
        OrderPaymentBreakupComponent,
        OrderItemBreakupComponent,
        OrderAddMiscItemsComponent,
        OrderTransactionHistoryComponent,
        OrderFiltersComponent,
        HistoryRefundComponent,
        OrderAcceptanceDialogComponent,
        TimeAcceptanceOrderIframeComponent,
        OrderDetailsComponent,
        // ManageFiltersComponent,
        // ConfirmationDialogComponent,
        CustomBatchTimeComponent,
        LoyaltyComponent,
        LoyaltySettingsComponent,
        UserLoyaltyComponent,
        TotalUserChartComponent,
        SignaturePadComponent,
        PinVerifyPadComponent,
        CardPaymentMqttComponent,
        ManualPrintControlComponent,
        CancelCardPaymentComponent,
        EditBreakTimeComponent,
        GiftCardHoldersComponent,
        TerminalStatusComponent,
        AlertMessageComponent,
        StoreFaviconComponent,
        MenuCategoriesComponent,
        ScreensComponent,
        DDScreensComponent,
        DDCategoriesComponent,
        DigitalScreensComponent,
        AddScreenDialogComponent,
        EditScreenDialogComponent,
        ScreenDeleteComponent,
        MasterCategoryDialogComponent,
        CategoryDialogComponent,
        SubCategoryDialogComponent,
        AddCategoriesDialogComponent,
        AddMasterCategoryDialogComponent,
        ScreenMediaComponent,
        ScreenMediaDialogComponent,
        DigitalDisplayComponent,
        ScreenVideoComponent,
        AddVideoDialogComponent,
        MenuCategorgDialogComponent,
        AllStoreEditMasterCategoryDialogComponent,
        SubMenuCategoriesDialogComponent,
        RestaurantImagesComponent,
        RestaurantImagesDialogComponent,
        OrderSubCatGridComponent,
        AddTimeClockComponent,
        DateTimeFormatDirective,
        GiftCardDefaulImageComponent,
        CampaignProgressComponent,
        CampaignCountdownComponent,
        CampaignCustomListComponent,
        CampaignCustomListTableComponent,
        AlertsMessagesComponent,
        OrderReadySettingsComponent,
        SettlementsComponent,
        DashboardEmployeeTaxComponent,
        SelectSummaryToprintComponent,
        CashDrawerManagementComponent,
        CashDrawerConfirmComponent,
        EndDrawerDetailComponent,
        ManageDrawerCashLogsComponent,
        EndDrawerConfirmationComponent,
        HolidaysComponent,
        DeliveriesDispatchComponent,
        FrontDisplayStatusComponent,
        MenuItemImageComponent,
        DefaultScreenDialogComponent,
        DefaultScreenComponent,
        ColumnManagementComponent,
        ColumnManagementDialogComponent,
        DefaultMenuCategoriesComponent,
        UpdateDefaultMenuDialogComponent,
        TvScreenPreviewComponent,
        BarCodeScannerComponent,
        ReferralComponent,
        ReferralSettingsComponent,
        UserReferralComponent,
        RedemptionsComponent,
        CashierRedemptionsDialogComponent,
        WorldnetDialogComponent,
        FeedbackComponent,
        FeedbackDetailsComponent,
        FeedbackDashboardComponent,
        EditItemDialogComponent,
        EditModifierDialogComponent,
        RatingPieChartComponent,
        FloorPlanComponent,
        AddNewFloorPlanComponent,
        FloorPlanViewComponent,
        AdvancedCssDialogComponent
    ],
    imports: [
        DragDropModule,
        FlexLayoutModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        NgxMatDatetimePickerModule,
        NgxMaterialTimepickerModule,
        NgxMatNativeDateModule,
        MatMomentDateModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        NgxAudioPlayerModule,
        AngMusicPlayerModule,
        // PapaParseModule,
        GoogleChartsModule.forRoot(),
        MatProgressButtonsModule.forRoot(),
        FileUploadModule,
        AngularCropperjsModule,
        MatGoogleMapsAutocompleteModule,
        MatTableExporterModule,
        MaterialTimePickerModule,
        NgIdleKeepaliveModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: environment.placesAPIKey,
            libraries: ['places']
        }),
        ScrollingModule,
        AngularEditorModule,
        NgxMaskModule.forRoot(),
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        Ng2SearchPipeModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        ZXingScannerModule
    ],
    providers: [
      { provide: HTTP_INTERCEPTORS, useClass:JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
      { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
      { provide: ErrorHandler, useClass: CustomErrorHandlerService},
      { provide: LocationStrategy, useClass: HashLocationStrategy },
      HttpClientModule,
      UserService,
      OrderService,
      CookieService,
      BusinessService,
      ReceiptService,
      StoreService,
      PaymentService,
      DashboardService,
      FormUtils,
      UploadService,
      MenuService,
      MenuServiceV2,
      LoaderService,
      UnreadService,
      HostedMenuService,
      AttendantSettingsService,
      CounterService,
      PrinterSettingsService,
      POSService,
      PendingChangesGuard,
      OccasionDetailService,
      TerminalService,
      PermissionService,
      CurrentOrderService,
      RefreshService,
      POSOrderTabChangeService,
      POSMenuTabChangeService,
      POSSummaryToggleService,
      OpenOrderToggleService,
      OrderTypeTabChangeService,
      DatePipe,
      LoyaltyPointsService,
      POSStreamService,
      TerminalStatusService,
      OrderWebsocketService,
      LinkMqttService,
      DigitalDisplayService,
      FrontFacingMqttService,
      CampaignService,
      ErrorLoggingService,
      NMISettlementService,
      ShowScreenService,
      PaymentGatewayService,
      ReferralSettingsService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
