import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, } from '@angular/core';
import { MenuGroupItem, MenuItemGroup } from '../../_models/order';
import { FullscreenHeightIncreaseService } from 'src/app/_services/fullscreen-height-increase.service';
import { EditItemDialogComponent } from '../edit-item-dialog/edit-item-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Permission } from 'src/app/_models/permission';
import { takeUntil } from 'rxjs/operators';
import { PinConfirmComponent } from '../pin-confirm/pin-confirm.component';
import { Subject } from 'rxjs';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { PermissionService } from 'src/app/_services/permission.service';

@Component({
  selector: "app-order-item-grid",
  templateUrl: "./order-item-grid.component.html",
  styleUrls: ["./order-item-grid.component.scss"],
})
export class OrderItemGridComponent implements OnInit {
  @Input() validUserData = "";
  @Input() items: MenuGroupItem[] | MenuItemGroup[];
  @Input() selected: string;
  @Output() onSelectHandler: EventEmitter<any> = new EventEmitter<any>();
  breakpoint: number;
  heightBox: any;
  orders: any;
  divHeight: string;
  innerHeightAA: string
  updateUserInfo: any;
  maxHeightMenuItem: string;
  maxHeightNewOrder: string;
  // fullscreenHeightIncreaseService: any;
  pressTimer: any;
  pressHoldDuration = 1000;
  permission: Permission;
  private destroySubject: Subject<void> = new Subject();
  constructor(private dialog: MatDialog, private permissionService: PermissionService) { }

  ngOnInit() {
    this.maxHeightMenuItem = String(window.innerHeight - 270) + 'px';
    if (window.innerWidth <= 499) {
      this.breakpoint = 3;
      this.heightBox = '65px';
    } else if (window.innerWidth >= 500 && window.innerWidth <= 599) {
      this.breakpoint = 3;
      this.heightBox = '70px';
    } else if (window.innerWidth >= 600 && window.innerWidth <= 900) {
      this.breakpoint = 4;
      this.heightBox = '80px';
    } else if (window.innerWidth >= 901 && window.innerWidth <= 1100) {
      this.breakpoint = 4;
      this.heightBox = '100px';
    } else {
      this.breakpoint = 5;
      this.heightBox = '100px';
    }
   // console.log('gggggggggggggggg',window.innerHeight);
    this.innerHeightAA = ''+(window.innerHeight -230)+'px'
    this.permissionService.pinCurrentOperator.pipe(takeUntil
      (this.destroySubject)).subscribe((permission) => {
        this.permission = permission;
    });
  }

  onMouseDown(item: any) {
    console.log('in function');

    // Start the timer when button is pressed down
    this.pressTimer = setTimeout(() => {
      // Open the EditItemDialogComponent on long press
      this.openEditItemDialog(item);
      console.log('in dialog');
    }, this.pressHoldDuration);
  }

  // Called when mouseup or touchend event is fired (when the user releases the mouse or touch)
  onMouseUp() {
    // Clear the timer if the mouse/touch is released before the threshold
    clearTimeout(this.pressTimer);
  }

  // Called when mouse leaves the button
  onMouseLeave() {
    // Clear the timer if the mouse leaves the button (prevents dialog from opening)
    clearTimeout(this.pressTimer);
  }

  // Open EditItemDialogComponent for editing an item
  openEditItemDialog(item: any) {
    console.log('Open edit dialog for:', item);
    if (this.permission.hasPermission('ACCESS', 'MENU')) {
      this.editItem(item);
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(PinConfirmComponent, {
        width: isMobile ? '100vw' : '500px',
        height: isMobile ? '100vh' : '410px',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(resultPer => {
        if (resultPer && resultPer.permission) {
          this.permission = resultPer['permission'];
          if (this.permission.hasPermission('ACCESS', 'MENU')) {
            this.editItem(item);
          } else {
            this.alertPopup('', 'This User/Manager does not have the required permission to perform this operation!');
          }
        }
      });
    }
  }

  editItem(item) {
    const dialogRef = this.dialog.open(EditItemDialogComponent, {
      data: item, // Pass the item data to the dialog
      maxWidth: '100vw',
      width: '100vw',
      height: '100vh',
      panelClass: 'full-screen-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result from the dialog (if any)
      console.log('Dialog result:', result);
    });
  }

  alertPopup(name, msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      // if (result) {
      //   if (name == 'process_payment') {
      //     this.ngOnInit();
      //   }
      // }
    });
  }


  onResize(event) {
    // this.breakpoint = (event.target.innerWidth <= 400) ? 2 : 5;

    if (event.target.innerWidth <= 499) {
      this.breakpoint = 3;
      this.heightBox = '65px';
    } else if (event.target.innerWidth >= 500 && event.target.innerWidth <= 599) {
      this.breakpoint = 3;
      this.heightBox = '70px';
    } else if (event.target.innerWidth >= 600 && event.target.innerWidth <= 900) {
      this.breakpoint = 4;
      this.heightBox = '80px';
    } else if (event.target.innerWidth >= 901 && event.target.innerWidth <= 1100) {
      this.breakpoint = 4;
      this.heightBox = '100px';
    } else {
      this.breakpoint = 5;
      this.heightBox = '100px';
    }
  }
  calculateContainerHeight(): string {
    // Adjust height dynamically based on the number of menu items
    const itemCount = this.items ? this.items.length : 0;
    const itemHeight = 70; // Adjust this value as needed
    const maxHeight = 316; // Set a maximum height if needed
    const calculatedHeight = itemCount * itemHeight;

    // Return the calculated height or a maximum height if it exceeds the limit
    return (calculatedHeight > maxHeight ? maxHeight : calculatedHeight) + 'px';
  }

}
