<mat-tab-group class="orders-page back-color-fullscreen tab-blink" #orderTabGroupNew (selectedTabChange)="onTabChange($event);
sidenav?.close();">
  <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab" class="clases">
    <ng-template matTabContent>
      <ng-container *ngIf="tab === 'POS'">
        <div class="overlay">
          <mat-drawer-container *ngIf="store?.hasModule('POS') && permission?.hasPermission('ORDERS', 'POS')">
            <mat-drawer class="xs:!w-[320px] w-[360px] 2xl:w-[400px] xs:!h-[250px] subtract-mr-5  xs:!h-auto"
              [style.height.px]="heightWindowResize" #orderSummaryDrawer mode="side" [opened]="false" cdk-scrollable>
              <app-order-summary [store]="store" #orderSummary (toggleSummaryDrawer)="changeTabOfDoneButton()">
              </app-order-summary>
            </mat-drawer>
            <mat-drawer-content>
              <!-- <div class="flex flex-row flex-nowrap justify-items-start" [ngClass]="{'mr-5': !orderSummaryDrawer.opened}"> -->
              <div class="flex flex-row flex-nowrap justify-items-start mr-5 xs:!mr-[0px]">
                <button *ngIf="showSummaryDrawer" (click)="orderSummaryDrawer.toggle()"
                  class="drawer-toggle h-[90vh] xs:!h-[auto] hover:bg-gray-200 w-[30px]" [disabled]="!validUserData">
                  <mat-icon>keyboard_double_arrow_{{orderSummaryDrawer.opened ? "left" : "right"}}</mat-icon>
                </button>
                <div class="w-full overflow-x-hidden overflow-y-hidden xs:!mr-2" (click)="handleNewOrderClick()">
                  <app-new-order #newOrderComponent [store]="store" [validUserData]="validUserData" #scrollableElement
                    [drawerOpened]="orderSummaryDrawer.opened" [tabIndex]="tabIndex" (toggleShowSummaryDrawer)="
                     openPOSDrawer(); orderSummary.refreshOrder()" (toggleSummaryDrawer)="changeTabOfDoneButton()"
                    (refresh)="orderSummary.refreshOrder()" (parserMenuItems)="getParserMenuItems($event)"
                    [@.disabled]="true" >
                  </app-new-order>
                </div>
                <button *ngIf="showSummaryDrawer && orderSummaryDrawer.opened" (click)="orderSummaryDrawer.toggle()"
                  class="drawer-toggle xs:!h-[91vh] hover:bg-gray-200 w-[30px] hidden xs:!block"
                  [disabled]="!validUserData">
                  <mat-icon>keyboard_double_arrow_left</mat-icon>
                </button>
              </div>
              <!-- <div style="float: left; width: 100%" (click)="handleNewOrderClick()">
                <app-new-order #newOrderComponent [store]="store" [validUserData]="validUserData"
                  [drawerOpened]="orderSummaryDrawer.opened" [tabIndex]="tabIndex" (toggleShowSummaryDrawer)="
                  openPOSDrawer(); orderSummary.refreshOrder()" (toggleSummaryDrawer)="changeTabOfDoneButton()"
                  (refresh)="orderSummary.refreshOrder()" (parserMenuItems)="getParserMenuItems($event)"
                  [@.disabled]="true">
                </app-new-order>
              </div> -->
              <div style="clear: both"></div>
            </mat-drawer-content>
          </mat-drawer-container>
        </div>
      </ng-container>
      <ng-container *ngIf="tab === 'CLOSED ORDERS'">
        <app-order-filters #closedFilters [store]="store" [isOpenClose]="true"></app-order-filters>
        <app-order-table #closedOrders [filters]="closedFilters.form.value" [isOpenOrders]="false"
          [refresh]="refresh"></app-order-table>
      </ng-container>
      <ng-container *ngIf="tab === 'OPEN ORDERS'">
        <app-order-filters #openOrderFilters [store]="store" [isOpenClose]="false"></app-order-filters>
        <app-order-table #openOrders [filters]="openOrderFilters.form.value" [isOpenOrders]="true"
          [refresh]="refresh"></app-order-table>
      </ng-container>
      <ng-container *ngIf="tab === 'REPORTING'">
        <div *ngIf="permission?.hasPermission('ORDERS', 'REPORTING')">
          <!-- <app-insight></app-insight> -->
          <app-mini-report class="report-app"></app-mini-report>
        </div>
      </ng-container>
      <ng-container *ngIf="tab === 'CASH LOGS'">
        <div *ngIf="permission?.hasPermission('REPORTING', 'VIEW_CASH_DRAWER')">
          <app-manage-drawer-cash-logs></app-manage-drawer-cash-logs>
        </div>
      </ng-container>
      <ng-container *ngIf="tab === 'STATEMENTS'">
        <div *ngIf="permission?.hasPermission('ORDERS', 'STATEMENTS')">
          <app-report></app-report>
        </div>
      </ng-container>
      <ng-container *ngIf="tab === 'DELIVERIES'">
        <app-deliveries-dispatch></app-deliveries-dispatch>
    </ng-container>
      <!-- <ng-container *ngIf="tab === 'GIFT CARD ORDERS'">
      <mat-sidenav-container class="closed-order-container">
        <mat-sidenav #sidenav mode="side" position="end" [(opened)]="opened" [fixedInViewport]="mobileQuery.matches"
          [mode]="'over'" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
          <app-gift-order-iframe></app-gift-order-iframe>
          <div class="close">
            <button mat-icon-button (click)="sidenav.toggle()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-sidenav>
        <mat-sidenav-content>
          <app-gift-card-order (openSideNav)="sidenav.open()" #giftCard></app-gift-card-order>

        </mat-sidenav-content>
      </mat-sidenav-container>
      </ng-container> -->
    </ng-template>
  </mat-tab>
  <mat-tab disabled class="position-right">
    <ng-template mat-tab-label>
      <div *ngIf="validUserData?.sessionId" class="flex flex-row items-center">
        <span class="black-mat-icon text-clr">{{validUserData?.getTerminalName}}: </span>
        <span class="black-mat-icon text-clr">{{
          validUserData.currentUser.first_name +
          " " +
          validUserData.currentUser.last_name
          }}</span>
        <button mat-icon-button type="button" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon class="black-mat-icon text-clr">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logoutCurrentUser()">
            <mat-icon class>power_settings_new</mat-icon>
            <span>Logout</span>
          </button>
          <span *ngIf="!fullScreenViaIcon">
            <button mat-menu-item (click)="openFullscreen(1)" *ngIf="!isFullScreen">
              <mat-icon>fullscreen</mat-icon>
              <span>Fullscreen</span>
            </button>
            <button mat-menu-item (click)="closeFullscreen()" *ngIf="isFullScreen">
              <mat-icon>fullscreen_exit</mat-icon>
              <span>Exit Fullscreen</span>
            </button>
          </span>
          <span *ngIf="fullScreenViaIcon">
            <button mat-menu-item>
              <mat-icon>menu</mat-icon>
              <span (click)="openSideMenu()" *ngIf="sideShowHide">Show Menu Bar</span>
              <span (click)="closeSideMenu()" *ngIf="!sideShowHide">Hide Menu Bar</span>
            </button>
            <!-- <button mat-menu-item (click)="closeSideMenu()">
              <mat-icon>menu</mat-icon>
              <span>Hide Menu</span>
            </button> -->
          </span>
        </mat-menu>
        <!-- <button mat-icon-button (click)="toggleFullscreen()">
            <mat-icon class="black-mat-icon" *ngIf="!isFullScreen">fullscreen</mat-icon>
            <mat-icon class="black-mat-icon" *ngIf="isFullScreen">fullscreen_exit</mat-icon>
            </button> -->
        <app-front-display-status [terminalId]="terminalId" ></app-front-display-status>

        
        <button mat-icon-button type="button" [opened]="opened" (click)="toggleFullscreen('height')">
          <mat-icon class="black-mat-icon text-clr" *ngIf="!isFullScreen">fullscreen</mat-icon>
          <mat-icon class="black-mat-icon text-clr" *ngIf="isFullScreen">fullscreen_exit</mat-icon>
        </button>
      </div>
      <app-terminal-status *ngIf="hasBLEPOS()"></app-terminal-status>

    </ng-template>
  </mat-tab>
</mat-tab-group>
