<form name="editScreenForm" [formGroup]="editScreenForm" (ngSubmit)="editScreenFormSubmit()"
  class="edit-screen-form fixActionRow">
  <h1 mat-dialog-title>Edit Screen</h1>
  <div mat-dialog-content class="xs:!overflow-y-scroll xs:!overflow-x-hidden">
    <div fxLayout="row" class="!mt-8 xs:!w-full">
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Screen ID</mat-label>
          <input matInput placeholder="" formControlName="tv_id" type="number">
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Content Type</mat-label>
          <mat-select formControlName="content_type" [(ngModel)]="optionValue">
            <mat-option value="MENU">Menu</mat-option>
            <mat-option value="IMAGE">Image</mat-option>
            <mat-option value="VIDEO">Video</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Canvas</mat-label>
          <input matInput formControlName="canvas" placeholder="" type="number">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" class="!mt-8 xs:!w-full">
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Screen width</mat-label>
          <input matInput placeholder="" formControlName="screen_width" type="number">
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Screen height</mat-label>
          <input matInput placeholder="" formControlName="screen_height" type="number">
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Rows Per Screen</mat-label>
          <input matInput placeholder="" formControlName="rows_per_screen" type="number">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" class="!mt-8 xs:!w-full">
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:w-full">
          <mat-label>Rotate</mat-label>
          <input matInput placeholder="" formControlName="rotate" type="number">
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:w-full">
          <mat-label>Refresh seconds</mat-label>
          <input matInput placeholder="" formControlName="refresh_seconds" type="number">
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Master category alignment</mat-label>
          <mat-select formControlName="master_category_alignment">
            <mat-option value="left">left</mat-option>
            <mat-option value="center">center</mat-option>
            <mat-option value="right">right</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" class="!mt-8 xs:!w-full" *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:w-full">
          <mat-label>Padding</mat-label>
          <input matInput placeholder="" formControlName="padding" type="number">
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:w-full">
          <mat-label>Product Font Size</mat-label>
          <input matInput placeholder="" formControlName="product_font_size" type="number">
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:w-full">
          <mat-label>Product Description Font Size</mat-label>
          <input matInput placeholder="" formControlName="product_description_size" type="number">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" class="!mt-8 xs:!w-full" *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Product price font size</mat-label>
          <input matInput placeholder="" formControlName="product_price_font_size" type="number">
        </mat-form-field>
      </div>

      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Price header font size</mat-label>
          <input matInput placeholder="" formControlName="price_header_font_size" type="number">
        </mat-form-field>
      </div>

      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Font family</mat-label>
          <mat-select formControlName="font_family">
            <mat-option value="Arial_light.ttf">Arial</mat-option>
            <mat-option value="ARIALBD0.TTF">Arial Bold</mat-option>
            <mat-option value="baskervi.ttf">Baskerville</mat-option>
            <mat-option value="BaskervilleBoldBT.ttf">Baskerville Bold</mat-option>
            <mat-option value="Calibri.ttf">Calibri</mat-option>
            <mat-option value="calibrib.ttf">Calibri Bold</mat-option>
            <mat-option value="CALIST.TTF">Calist</mat-option>
            <mat-option value="CALISTB.TTF">Calist Bold</mat-option>
            <mat-option value="CANDARA.TTF">Candara</mat-option>
            <mat-option value="CANDARAB.TTF">Candara Bold</mat-option>
            <mat-option value="CenturyGothic.ttf">Century Gothic</mat-option>
            <mat-option value="GOTHICB0.TTF">Century Gothic Bold</mat-option>
            <mat-option value="CONSOLA.TTF">Consolas</mat-option>
            <mat-option value="CONSOLAB.TTF">Consolas Bold</mat-option>
            <mat-option value="DejaVuSans.ttf">Dejavu Sans</mat-option>
            <mat-option value="DejaVuSans-Bold.ttf">Dejavu Sans Bold</mat-option>
            <mat-option value="GARA.TTF">Garamond</mat-option>
            <mat-option value="GARABD.TTF">Garamond Bold</mat-option>
            <mat-option value="LBRITE.ttf">Lucida Bright</mat-option>
            <mat-option value="LBRITED.ttf">Lucida Bright Bold</mat-option>
            <mat-option value="Microsoft_Sans_Serif.ttf">Microsoft Sans Serif</mat-option>
            <mat-option value="PALAT34.ttf">Palatino</mat-option>
            <mat-option value="PALAT32.ttf">Palatino Bold</mat-option>
            <mat-option value="Ultima.ttf">Ulitma</mat-option>
            <mat-option value="dk_butterfly_ball.otf">DK Butterfly Ball</mat-option>
          </mat-select>
        </mat-form-field>
      </div>


    </div>

    <div fxLayout="row" class="!mt-8 xs:!w-full" *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Sub category font size</mat-label>
          <input matInput placeholder="" formControlName="sub_category_font_size" type="number">
        </mat-form-field>
      </div>

      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Category font size</mat-label>
          <input matInput placeholder="" formControlName="category_font_size" type="number">
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Master category font size</mat-label>
          <input matInput placeholder="" formControlName="master_category_font_size" type="number">
        </mat-form-field>
      </div>

    </div>
    <div *ngIf="storeType == 'Leaflogix'">
      <div fxLayout="row" class="!mt-8 xs:!w-full" style="margin-bottom: 15px;"
        *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
        <div class="input-gap xs:!w-1/3 xs:!-ml-1">
          <mat-form-field appearance="fill" class="add-screen-hint xs:!w-full">
            <mat-label>Hybrid color</mat-label>
            <input matInput placeholder="" formControlName="hybrid_color" type="input">
            <mat-hint>Color in hex: #ffffff</mat-hint>
          </mat-form-field>
        </div>
        <div class="input-gap xs:!w-1/3 xs:!-ml-1">
          <mat-form-field appearance="fill" class="add-screen-hint xs:!w-full">
            <mat-label>Indica color</mat-label>
            <input matInput placeholder="" formControlName="indica_color" type="input">
            <mat-hint>Color in hex: #ffffff</mat-hint>
          </mat-form-field>
        </div>
        <div class="input-gap xs:!w-1/3 xs:!-ml-1">
          <mat-form-field appearance="fill" class="add-screen-hint xs:!w-full">
            <mat-label>Sativa color</mat-label>
            <input matInput placeholder="" formControlName="sativa_color" type="input">
            <mat-hint>Color in hex: #ffffff</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="storeType == 'Leaflogix'">
      <div fxLayout="row" class="!mt-8 xs:!w-full" *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
        <div class="input-gap xs:!w-1/3 xs:!-ml-1">
          <mat-form-field appearance="fill" class="xs:!w-full">
            <mat-label>Strain type size</mat-label>
            <input matInput placeholder="" formControlName="strain_type_size" type="input">
          </mat-form-field>
        </div>
        <div class="input-gap xs:!w-1/3 xs:!-ml-1">
          <mat-form-field appearance="fill" class="xs:!w-full">
            <mat-label>Strain font size</mat-label>
            <input matInput placeholder="" formControlName="strain_font_size" type="input">
          </mat-form-field>
        </div>
        <div class="input-gap xs:!w-1/3 xs:!-ml-1">
          <mat-form-field appearance="fill" class="xs:!w-full">
            <mat-label>Product weight font size</mat-label>
            <input matInput placeholder="" formControlName="product_weight_font_size" type="number">
          </mat-form-field>
        </div>

      </div>
    </div>


    <div fxLayout="row" class="!mt-8 xs:!w-full" style="margin-top: 14px;"
      *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Header text</mat-label>
          <input matInput placeholder="" formControlName="header_text" type="input">
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="add-screen-hint xs:!w-full">
          <mat-label>Header color</mat-label>
          <input matInput placeholder="" formControlName="header_font_color" type="input">
          <mat-hint>Color in hex: #ffffff</mat-hint>
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Header Emoji</mat-label>
          <input matInput aria-label="Header Emoji" [matAutocomplete]="auto" formControlName="header_emoji">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let state of emoji" [value]="state.value">
              <img style="float: left; margin-right: 10px; width: 30px; height: 30px; max-width: 30px;"
                class="example-option-img" aria-hidden [src]="state.value" height="25">
              <span style="line-height: 35px;">{{state.name}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" class="!mt-8 xs:!w-full" style="margin-top: 14px;"
      *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="add-screen-hint xs:!w-full">
          <mat-label>Weight Font color</mat-label>
          <input matInput placeholder="" formControlName="weight_font_color" type="input">
          <mat-hint>Color in hex: #ffffff</mat-hint>
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="add-screen-hint xs:!w-full">
          <mat-label>Description font color</mat-label>
          <input matInput placeholder="" formControlName="description_font_color" type="input">
          <mat-hint>Color in hex: #ffffff</mat-hint>
        </mat-form-field>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="add-screen-hint xs:!w-full">
          <mat-label>Price font color</mat-label>
          <input matInput placeholder="" formControlName="price_font_color" type="input">
          <mat-hint>Color in hex: #ffffff</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" class="!mt-8 xs:!w-full" style="margin-top: 14px;"
      *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="add-screen-hint xs:!w-full">
          <mat-label>Product font color</mat-label>
          <input matInput placeholder="" formControlName="product_font_color" type="input">
          <mat-hint>Color in hex: #ffffff</mat-hint>
        </mat-form-field>
      </div>


      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="add-screen-hint xs:!w-full">
          <mat-label>Tag Font color</mat-label>
          <input matInput placeholder="" formControlName="tag_font_color" type="input">
          <mat-hint>Color in hex: #ffffff</mat-hint>
        </mat-form-field>
      </div>

      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Screen bgcolor</mat-label>
          <input matInput placeholder="" formControlName="screen_bgcolor" type="input">
          <mat-hint>Color in hex: #ffffff</mat-hint>
        </mat-form-field>
      </div>



    </div>
    <div fxLayout="row" class="!mt-8 xs:!w-full" style="margin-top: 14px;"
      *ngIf="(optionValue != 'MENU' && optionValue != 'VIDEO') || (optionValue != 'IMAGE' && optionValue != 'MENU') || (optionValue != 'VIDEO'  && optionValue != 'MENU') ">

      <div class="input-gap xs:!w-1/3 xs:!-ml-1" *ngIf="optionValue != 'MENU' && optionValue != 'VIDEO'">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Image</mat-label>
          <mat-select formControlName="image" multiple>
            <mat-option *ngFor="let image of image_list" [value]="image.id">
              {{image.row}} {{image.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <div class="input-gap xs:!w-1/2 xs:!-ml-1" *ngIf="optionValue != 'IMAGE' && optionValue != 'MENU'">
        <mat-form-field appearance="fill" class="xs:!w-full">
          <mat-label>Video</mat-label>
          <mat-select formControlName="video">
            <mat-option *ngFor="let video of video_list" [value]="video.id">
              {{video.row}} {{video.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-gap xs:!w-1/2 xs:!-ml-1" *ngIf="optionValue != 'VIDEO'  && optionValue != 'MENU'">
        <mat-form-field appearance="fill" fxFlex="100" class="xs:!w-full">
          <mat-label>Image Width</mat-label>
          <mat-select formControlName="image_width">
            <mat-option [value]="width" *ngFor="let width of imageWidth">
              {{width}}%
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  

    <div fxLayout="row" class="!mt-8 xs:!w-full" *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
      <div class="input-gap xs:!w-1/3 xs:!-ml-1" style="width: 33%;">
        <mat-checkbox (change)="displayCheck($event)" [checked]="hideKeyDisplay">Hide key display</mat-checkbox>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1" style="width: 33%;">
        <mat-checkbox (change)="headerCheck($event)" [checked]="showHeader">Show header</mat-checkbox>
      </div>
      <div class="input-gap xs:!w-1/3 xs:!-ml-1">
        <mat-checkbox (change)="hideColorCheck($event)" [checked]="hideColorsCheck">Hide tag colors</mat-checkbox>
      </div>
    </div>
    <div fxLayout="row" class="!mt-4 xs:!w-full xs:!flex-col xs:!flex-row-none" style="margin-top: 14px;">
      <div *ngIf="storeType == 'Leaflogix'">
        <div class="input-gap xs:!w-1/3 xs:!-ml-1" *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
          <mat-form-field appearance="fill" class="add-screen-hint xs:!w-full">
            <mat-label>Strain right margin</mat-label>
            <input matInput placeholder="" formControlName="strain_right_margin" type="input">
          </mat-form-field>
        </div>
      </div>
      <div class="input-gap xs:!w-full xs:!-ml-1" style="width: 33%;"
        *ngIf="optionValue != 'IMAGE' && optionValue != 'VIDEO'">
        <mat-checkbox (change)="decimalPointCheck($event)" [checked]="decimalPointsCheck">Decimal Point
          Hide</mat-checkbox>
      </div>
      <div class="input-gap xs:!w-full xs:!-ml-1 xs:!mt-8" *ngIf="optionValue != 'MENU' && optionValue != 'VIDEO'">
        <input style="margin-top: 4px;" class="form-control" type="file" formControlName="media_file"
          (change)="onChange($event)" />
      </div>

    </div>
  </div>
  <mat-dialog-actions align="end" class="button-box-right form-row">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
    <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
  </mat-dialog-actions>
</form>