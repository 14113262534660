import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OrdersWithPayment} from 'src/app/_models/order';
import {OrderService} from '../../_services/order.service';
import {UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {CheckoutDialogComponent} from '../checkout-dialog/checkout-dialog.component';
import {Store} from 'src/app/_models/store';
import {TipDialogComponent} from '../tip-dialog/tip-dialog.component';
import {CardPaymentConsumerChoiceComponent} from '../card-payment-consumer-choice/card-payment-consumer-choice.component';
import {OrderHelpers} from 'src/app/_helpers/helpers';
import {CustomTipComponent} from '../tip-dialog/custom-tip/custom-tip.component';
import {CardPaymentMqttComponent} from '../card-payment-mqtt/card-payment-mqtt.component';
import {FrontFacingMqttService} from 'src/app/_services/front-facing-mqtt.service';
import {MatStepper} from '@angular/material/stepper';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-payment-options-dialog',
  templateUrl: './payment-options-dialog.component.html',
  styleUrls: ['./payment-options-dialog.component.scss']
})
export class PaymentOptionsDialogComponent implements OnInit, OnDestroy {

  orderWithPayment: OrdersWithPayment;
  card = 'card';
  cash = 'cash';
  orderInstruction: string;
  public form: UntypedFormGroup;
  private unsubscribe: Subject<null> = new Subject();
  orderName: string;
  terminalId = this.data.terminalId;
  store: Store;

  subTotal = this.data.subTotal;
  taxDueTotal = this.data.orderWithPayment.payment.tax + this.subTotal;
  tipTenPercent = (this.subTotal * 10) / 100;
  tipFifteenPercent = (this.subTotal * 15) / 100;
  tipTwentyPercent = (this.subTotal * 20) / 100;
  tips = [
    { name: 'No Tip', value: 0, amount: 0 },
    { name: '15%', value: 15, amount: this.tipTenPercent },
    { name: '18%', value: 18, amount: this.tipFifteenPercent },
    { name: '20%', value: 20, amount: this.tipTwentyPercent },
  ];
  tipPercent;
  tipAmount;
  orderHash = this.data.orderWithPayment.bot_order.order_hash;

  selectedPayment;
  selectedTip;
  selectedTipPercent;
  tipSelected = false;
  paymentModeSub : Subscription;
  @ViewChild('stepper',{static: false}) stepper: MatStepper;
  amountWithoutTip: number = 0;
  tipcondition: any;

  private destroySubject: Subject<void> = new Subject();


  constructor(public dialogRef: MatDialogRef<PaymentOptionsDialogComponent>,
              public dialog: MatDialog,
              private orderService: OrderService,
              @Inject(MAT_DIALOG_DATA) public data,
              private mq: FrontFacingMqttService,
              private chRef: ChangeDetectorRef) {
    this.orderWithPayment = data.orderWithPayment;
    this.store = this.orderWithPayment.store;
    dialogRef.disableClose = true;

    if (this.orderWithPayment.payment.tip != null) {
      this.amountWithoutTip = this.orderWithPayment.payment.tip;
      this.tipcondition = 0;
    } else {
      this.amountWithoutTip = 0;
    }

  }

  ngOnInit() {
    this.tipPercent = this.orderWithPayment.getTipPercent();

    if (this.orderWithPayment.payment.tip != 0) {
      if (Number(this.orderWithPayment.bot_order.tip_percent) == 15) {
        this.selectTip(this.tips[1], 0);
      } else if (Number(this.orderWithPayment.bot_order.tip_percent) == 18) {
        this.selectTip(this.tips[2], 0);
      } else if (Number(this.orderWithPayment.bot_order.tip_percent) == 20) {
        this.selectTip(this.tips[3], 0);
      } else if (Number(this.orderWithPayment.bot_order.tip_percent) == 1) {
        const a = {name: 'Custom', value: 'custom', amount: this.orderWithPayment.payment.tip};
        this.selectTip(a,0);
      }
    }



    this.paymentModeSub =  this.mq.paymentMode.pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (data.type === 'paymentTypeCash') {
        this.openCheckoutDialog(0);
      } else if (data.type === 'paymentTypeCard') {
        this.openCardPaymentDialog();
      }
    });
  }



  selectTip(tip, e) {
    console.log(tip);
    this.selectedTip = tip;
    this.tipSelected = true;
    this.tipPercent = tip.value;
    this.tipcondition = 1;
    this.chRef.detectChanges();
  }

  back() {
    if (this.data.openOrder) {
      this.mq.refreshOpenOrder();
    }
    if (this.stepper.selectedIndex <= 0) {
      this.dialogRef.close();
    } else {
      this.stepper.previous();
    }
  }

  calculateTip(change: any) {
    if (change.name === 'Custom') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialog.closeAll();
    const isMobile = window.innerWidth <= 470;
      const dialogRef = this.dialog.open(CustomTipComponent, {
        // width: '630px', height: '290px',
        width: isMobile ?'100vw' : '630px',
          height: isMobile ? 'auto' : '290px',
          maxWidth: isMobile ? '100vw' : '',
        data: this.data, panelClass: 'trend-dialog'
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        this.refreshOrder();
        this.chRef.detectChanges();
      });
    } else {
      this.orderWithPayment.updateTip(change.value);
      this.tipAmount = this.orderWithPayment.payment.tip;
      this.orderService.updateTip(this.orderHash, change.value, 'payment-Options-calculate').pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        // this.refresh.emit(true);
        this.tipPercent = data.tip_percent;
      });
    }
  }


    cancelOrder() {
    this.orderWithPayment = null;
    this.orderName = null;
    this.form.controls.orderName.setValue(null);
    this.form.controls.phoneNumber.setValue(null);
    this.form.reset();
    this.orderService.orderDoneSubject.next();
  }

  selectPayement(paymentType) {
    this.mq.setPaymentMode({paymentMode : paymentType});
    this.selectedPayment = paymentType;
    // this.stepper.next();
    this.openCardPaymentDialog();


    if (!this.store.enable_consumer_choice_tip) {
      console.log('goto payment');
      this.onPay();
    }
  }

  openCheckoutDialog(e) {
    const orderHash = localStorage.getItem('order_hash');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (e) {this.mq.setPaymentMode({
      paymentMode : 'cash'
    }); }

    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(CheckoutDialogComponent, {
        width: isMobile ?'100vw' : '630px',
        height: isMobile ? 'auto' : '630px',
        maxWidth: isMobile ? '100vw' : '',
      // width: '630px', height: '630px',
      data: {
        orderWithPayment: this.orderWithPayment,
        terminalId: this.terminalId,
        total: this.orderWithPayment.payment.total,
        cashTotal: this.orderWithPayment.payment.cash_due_total,
        subTotal: this.orderWithPayment.payment.sub_total,
      },
      panelClass: 'checkout-mobile',
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(isComplete => {
      if (isComplete) {
        this.dialogRef.close(isComplete);
      }
    });
  }

  goBack() {
    this.orderWithPayment.payment.due_total = this.orderWithPayment.payment.due_total - this.orderWithPayment.payment.tip;
    this.orderWithPayment.updateTipCustom(0);
    this.dialog.closeAll();
  }

  onPay() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let dialogRef = null;
    let tipObs = null;

    if (this.selectedTip.value) {

      tipObs = this.orderService.updateTip(this.orderHash, this.selectedTip.value, 'payment-Options-onPay');
    } else {
      tipObs = this.orderService.updateCustomTip(this.orderHash, this.selectedTip.amount, 'payment-Options-onPay-custom');
    }

    tipObs.pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
        this.data.amount = this.orderWithPayment.getCardChargeWithTip(this.selectedTip.amount || 0);
        dialogRef = this.dialog.open(CardPaymentMqttComponent, {
          width: '630px', data: this.data, disableClose: true
        });
      } else {
        this.data.toCharge = this.orderWithPayment.getCardChargeWithTip(this.selectedTip.amount || 0);
        dialogRef = this.dialog.open(CardPaymentConsumerChoiceComponent, {
          data: this.data, disableClose: true, maxWidth: '100vw', maxHeight: '100vh', height: '100%', width: '100%',
        });
      }

      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        this.dialogRef.close(result);
        this.chRef.detectChanges();
      });
    });
  }


  openCardPaymentDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const isMobile = window.innerWidth <= 470;
    this.dialog.closeAll();
    if (this.store.enable_consumer_choice_tip) {
      const dialogRef = this.dialog.open(TipDialogComponent, {
        width: isMobile ?'100vw' : '100%',
        height: isMobile ? 'auto' : '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: this.data, panelClass: 'trend-dialog' ,
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(isComplete => {
        console.log(isComplete);
        if (isComplete) {
          this.cancelOrder();
        }
      });
    } else {
      let dialogRef = null;
      if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
        dialogRef = this.dialog.open(CardPaymentMqttComponent, {
          // width: '630px',
          width: isMobile ?'100vw' : '630px',
          height: isMobile ? 'auto' : '',
          maxWidth: isMobile ? '100vw' : '',
          data: this.data, disableClose: true
        });
      } else {
        dialogRef = this.dialog.open(CardPaymentConsumerChoiceComponent, {
          maxWidth: '100vw', maxHeight: '100vh', height: '100%', width: '100%',
          data: this.data, disableClose: true
        });
      }

      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(isComplete => {
        if (isComplete) {
          this.cancelOrder();
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroySubject.next();
    if (this.paymentModeSub) {
      this.paymentModeSub.unsubscribe();
    }
    this.stepper.reset();
    this.orderWithPayment = null;
    this.mq.resetPaymentMode();
    this.mq.resetTip();
  }

  refreshOrder() {
    this.orderService.getOrder(this.orderHash).pipe(takeUntil(this.destroySubject))
      .subscribe((result) => {
        this.orderWithPayment = OrderHelpers.parseOrderwithPayment(result);
        this.store = this.orderWithPayment.store;
      });
  }
}
