import {
  ChangeDetectorRef,
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '../../_models/store';
import { capitalize } from '../../_helpers/string.helper';
import { UntypedFormControl, UntypedFormGroup, Validators, } from '@angular/forms';
import { MenuServiceV2 } from '../../_services/menu-v2.service';

import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil, } from 'rxjs/operators';
import {
  BotOrder,
  MenuGroup,
  MenuGroupItem,
  MenuItem,
  MenuItemGroup,
  Modifier,
  ModifierGroup,
  ModifierOption,
  OccasionDetail,
  Order,
  OrdersWithPayment,
} from '../../_models/order';
import { Appearance, Location, } from '@angular-material-extensions/google-maps-autocomplete';
import { StoreService } from '../../_services/store.service';
import { Table } from '../../_models/table';
import { OrderService } from '../../_services/order.service';
import { IOrder, IOrderItem } from '../../_interfaces/order';
import { FormUtils } from '../../_helpers/form-utils';
import { days, Modules, pad } from 'src/app/constants';
import * as moment from 'moment';
import { ComponentCanDeactivate } from '../../_guards/changes.guard';
import { OccasionDetailService } from 'src/app/_services/occasion-detail.service';
import { OrderHelpers } from 'src/app/_helpers/helpers';
import { CalculatorComponent } from '../calculator/calculator.component';
import { POSService } from 'src/app/_services/pos-service';
import { TerminalService } from 'src/app/_services/terminal.service';
import { CurrentOrderService } from 'src/app/_services/current-order.service';
import { RefreshService } from 'src/app/_services/refresh.service';
import { POSOrderTabChangeService } from 'src/app/_services/pos-order-tab-change.service';
import { POSMenuTabChangeService } from 'src/app/_services/pos-menu-tab-change.service';
import { POSSummaryToggleService } from 'src/app/_services/pos-summary-toggle.service';
import { BusinessService } from 'src/app/business.service';

import Utils from 'src/app/utils';
import { Permission } from "src/app/_models/permission";
import { PermissionService } from "src/app/_services/permission.service";
import { PinConfirmComponent } from "../pin-confirm/pin-confirm.component";
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MatDrawer } from '@angular/material/sidenav';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatTabGroup } from '@angular/material/tabs';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { NgxMatDatepickerInputEvent } from '@angular-material-components/datetime-picker/lib/datepicker-input-base';
import { MatRadioChange } from '@angular/material/radio';
import Keyboard from "simple-keyboard";
import PlaceResult = google.maps.places.PlaceResult;
import { FullscreenHeightIncreaseService } from 'src/app/_services/fullscreen-height-increase.service';
import { CashDrawerManagementComponent } from '../cash-drawer-management/cash-drawer-management.component';
// import MapTypeControlOptions = google.maps.MapTypeControlOptions;
// import MapTypeControlStyle = google.maps.MapTypeControlStyle;
// import MapTypeId = google.maps.MapTypeId;
import { BarCodeScannerComponent } from '../bar-code-scanner/bar-code-scanner.component';
import { FloorPlanService } from 'src/app/_services/floor-plan.service';
import Konva from 'konva';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss'],
})
export class NewOrderComponent
  implements OnInit, OnDestroy, DoCheck, ComponentCanDeactivate, AfterViewInit {
  setDefaultOccasion: any;
  setDefaultTime: any;
  idStore: any;
  filter: any;
  search: any;
  startDate: any;
  endDate: any;
  myOrder = false;
  lastOrderHash: any;
  addressList: any = [];
  previousOrderList: any = [];
  numberForm: any;
  orderInstruction: string;
  specialRequest: any;
  normalNumber: any;
  dialogConfig: MatDialogConfig<any>;
  addressConfirmValue: any;
  asyncResult: void;
  getOccasion: any;
  getTime: any;
  disableDiscountEntry: any;
  permission: Permission;
  pinPermission: Permission;
  orders: any[] = [];
  divHeight: string = 'auto'; // Default height
  fullcreenHeightIncreaseService: any;

  @ViewChild('stageContainer') stageContainer: ElementRef;
  stage: any;
  layer: any;
  tablesList: any;
  objectList: any;
  transformer: Konva.Transformer;
  floorPlanData: any;

  constructor(
    private businessService: BusinessService,
    private storeService: StoreService,
    private menuService: MenuServiceV2,
    private orderService: OrderService,
    private formUtils: FormUtils,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private occasionDetailService: OccasionDetailService,
    private ref: ChangeDetectorRef,
    private posService: POSService,
    private terminalService: TerminalService,
    private _snackBar: MatSnackBar,
    private refreshService: RefreshService,
    private currentOrderService: CurrentOrderService,
    private posOrderTabChangeService: POSOrderTabChangeService,
    private posMenuTabChangeService: POSMenuTabChangeService,
    private posSummaryToggleService: POSSummaryToggleService,
    private posSummaryToggle: POSSummaryToggleService,
    private permissionService: PermissionService,
    private fullscreenHeightIncreaseService: FullscreenHeightIncreaseService,
    private floorPlanService: FloorPlanService
  ) { }

  @Input() validUserData: any;
  @Input() store: Store;
  @Input() drawerOpened: boolean;
  @Input() tabIndex = 0;
  @Output() toggleShowSummaryDrawer: EventEmitter<null> =
    new EventEmitter<null>();
  @Output() toggleSummaryDrawer: EventEmitter<null> = new EventEmitter<null>();
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() parserMenuItems: EventEmitter<any> = new EventEmitter<any>();
  @Input() form1: UntypedFormGroup;
  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild('menuGroupInput', { static: false }) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('orderSummaryDrawer', { static: false }) orderSummaryDrawer: MatDrawer;
  @ViewChild('picker', { static: false }) picker: MatDatepicker<any>;
  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('orderName', { static: false }) orderNameInput: ElementRef;
  @ViewChild('deliveryAddress', { static: false }) inputDeliveryAddress: ElementRef;
  @ViewChild('phone', { static: false }) phoneInput: ElementRef;
  public form: UntypedFormGroup;
  public modifierFormGroup: UntypedFormGroup;

  public orderHash: string;
  public orderCounter: string;
  public orderOccasionPanel: boolean;
  public orderTimePanel: boolean;
  public deliveryAddressPanel: boolean;
  public buildOrderPanel: boolean;
  public occasions: string[] = [];
  public unsubscribe: Subject<null> = new Subject<null>();
  public menuGroups$: MenuGroup[] = [];
  public menuItemGroups$: MenuItemGroup[] = [];
  public menuItems$: MenuItem[] = [];
  public modifierGroup$: ModifierGroup;
  public currentMenuTitle: string;
  public previousMenuTitle: string;
  public loading = false;
  public editItem: IOrderItem;
  public modifiersLoading = false;
  public stepNumber = 1;
  public tables$: Table[] = [];
  public latitude: number;
  public longitude: number;
  public zoom: number;
  public addButtonWidth: number;
  public totalPrice = 0;
  public appearance: Appearance;
  public mapTypeControlOptions: any;
  public filteredOrders: { [key: string]: MenuGroupItem[] };
  public filteredOrdersGroups: string[] = [];
  public modifierDirections = null;
  public modifierQuantities = null;
  public panelOpenState = false;
  public selectModifiers = ['LEFT', 'WHOLE', 'RIGHT'];
  public workingHours;
  public hours = [];
  public days: string[];
  public quantities = 1;
  public selected = 0;
  public hideTab = false;
  public occasionSchedule;
  public occasionStatus: any;
  public minDate = new Date();
  public a;

  public selectedOccasion;
  public selectedTime;
  public occasionForm;
  public retrievedAddress: any = '';
  orderWithPayment: OrdersWithPayment;
  occasionDetail: OccasionDetail = new OccasionDetail();
  timeRange;
  // public refresh: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  checkScheduleDate: any = null;

  serviceAreas;
  deliveryAddress;
  locationSelected;
  deliveryAddressInput;
  serviceAreaLabel;
  terminalId;
  disableQuickEntry: boolean = false;
  orderTabChangeSub: Subscription;
  cashierName: string;
  defaultOrderOccasion: any;
  defaultTime: any;
  retrievedDate
  retrievedTime
  timeSelectionClicked = false;
  isDefaultLoad = true;
  selectedTable: string;
  currentOrderSub: Subscription;
  orderName: string;
  public showDiv = false;
  breakpoint: number;
  showQuickEntry: boolean = true;
  browser: string
  updateUserInfo: any;
  ocassionsArray = ['DELIVERY', 'CURBSIDE', 'DINE-IN', 'TABLESIDE'];

  previoudOrderOverview: any;
  addressInput: any;
  value = "";
  numberKeyData = "";
  inputName = "";
  inputNumberName = "";
  keyboard: Keyboard;
  numericKeyboard: Keyboard;
  keyboardCreated: boolean = false;
  /* Numeric KeyBoard Layout */
  customNumberLayout = {
    default: [
      "1 2 3",
      "4 5 6",
      "7 8 9",
      "0 {backspace}"
    ],
  };
  /* Without Numeric KeyBoard Layout */
  customLayoutWithoutNumbers = {
    default: [
      "1 2 3 4 5 6 7 8 9 0",
      "q w e r t y u i o p",
      "a s d f g h j k l",
      "{shift} z x c v b n m {backspace}",
      "{space} {enter}"
    ],
    shift: [
      "1 2 3 4 5 6 7 8 9 0",
      "Q W E R T Y U I O P",
      "A S D F G H J K L",
      "{shift} Z X C V B N M {backspace}",
      "{space} {enter}"
    ]
  };

  tableStatusTabChange: Subscription;
  defaultOccasionUpdate: Subscription;
  scannedResult: string | null = null;
  private scannedData: string = '';
  beepAudio = new Audio('assets/audio/alert.mp3');

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | any { }
  maxHeightNewOrder: string;
  public dialogRef: MatDialogRef<CashDrawerManagementComponent>
  canvasCreated: boolean = false; 


  @HostListener('window:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.store.feature_flag['barcode_scanner_feature']) {
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        console.log('This key press is not valid');
      } else {
        this.blurActiveElement();
        const char = event.key;
        if (char === 'Enter' && this.scannedData.length >= 12 && /^\d+$/.test(this.scannedData)) {
          this.scannedResult = this.scannedData;
          this.beepAudio.play();
          this.posSummaryToggleService.toggle('OPEN');
          this.addItemToTheBasket(this.scannedData);
          this.scannedData = '';
        } else if (char !== 'Enter' && /^\d$/.test(char)) {
          this.scannedData += char;
        }
        console.log('Scanned Data', this.scannedData);
      }
    }
  }

  blurActiveElement() {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  ngOnInit() {
    // this.maxHeightNewOrder = String(window.innerHeight - 330) + 'px';
    this.browser = localStorage.getItem('browser');
    // console.log('browser dialog poss',this.browser);
    
    this.fullscreenHeightIncreaseService.reciveInfo().subscribe(info => {
      // console.log('infoo',info);
      if(info == ""){
        this.maxHeightNewOrder = String(window.innerHeight - 330) + 'px';
        // console.log('maxHeightNewOrder',this.maxHeightNewOrder);
      }else{
        let sss= Number(info)
        this.maxHeightNewOrder = String(sss) + 'px';
        // console.log('maxHeightNewOrder',this.maxHeightNewOrder);
      }
   
    })
  
  

    if (window.innerWidth <= 400) {
      this.breakpoint = 4;
    } else if (window.innerWidth >= 401 && window.innerWidth <= 599) {
      this.breakpoint = 4;
    } else if (window.innerWidth >= 600) {
      this.breakpoint = 4;
    }

    this.idStore = this.store.id
    this.loadBotOrders(this.idStore, this.myOrder ? localStorage.getItem('posLoggedUser') : '');
    this.addressList = [];
    this.previousOrderList = [];

    if (localStorage.getItem('order_hash')) {
      this.orderHash = localStorage.getItem('order_hash');
    } else {
      this.refreshOrder()
      this.orderHash = localStorage.getItem('order_hash');
    }
    this.refreshTerminal();
    this.orderService.getServiceAreas(this.store.id).pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.serviceAreas = data;
      if (data.service_type === 'RADIUS') {
        this.serviceAreaLabel = `Delivery Radius: ${data.radius} Miles`;
      } else {
        this.serviceAreaLabel = `Delivering ${data.areas
          .map((p) => p.name)
          .join(', ')}`;
      }
    });

    this.occasionForm = new UntypedFormGroup({
      occasion: new UntypedFormControl(null, Validators.required),
      time: new UntypedFormControl(null, Validators.required),
      dine_in_table_number: new UntypedFormControl(null),
      schedule_date: new UntypedFormControl(null),
      schedule_time: new UntypedFormControl(null),
      delivery_address: new UntypedFormControl({ value: '', disabled: true }),
      curbside_vehicle_id: new UntypedFormControl(null),
      phone_number: new UntypedFormControl(null),
      order_name: new UntypedFormControl({ value: '', disabled: true }),
      specialRequest: new UntypedFormControl(null),
    });

    this.form = new UntypedFormGroup({
      search: new UntypedFormControl(null),
      menuGroupId: new UntypedFormControl(null, [Validators.required]),
      menuGroupItemId: new UntypedFormControl(null, [Validators.required]),
      menuItemId: new UntypedFormControl(null, [Validators.required]),
      modifiers: new UntypedFormControl([]),
    });

    this.modifierFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      direction: new UntypedFormControl(null),
      hasQuantity: new UntypedFormControl(null),
    });

    this.storeService
      .getTablesideTables(this.store.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((tables: Table[]) => (this.tables$ = tables));



    this.menuService
      .getStoreMenuGroups(this.store.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (menuGroups: MenuGroup[]) => {
          this.menuGroups$ = menuGroups;
          this.loading = false;
        },
        () => (this.loading = false)
      );

    this.days = days;
    this.workingHours = this.store.working;
    console.log(this.workingHours);
    const filteredOccasions: string[] = [];
    Object.entries(this.store.occasion_flags).forEach(
      ([occasion, available]) => available && filteredOccasions.push(occasion)
    );
    this.occasions = filteredOccasions;
    this.updateHoursBasedOnSelectedDate();
    this.setCurrentPosition();
    this.orderService.orderDoneSubject
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.resetState();
        this.cashierName = this.getCashierName();
        this.orderService
          .getTempPOSOrderWithPhone(
            this.store.id,
            this.store.sms_number,
            this.cashierName
          )
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((orderHash) => {
            localStorage.setItem('order_hash', orderHash.order_hash);
            this.orderHash = orderHash.order_hash;
            this.orderCounter = '';
            // this.refreshOrder();
            this.resetState();
            this.updateProfile();
            localStorage.getItem('setOccasionDetail') === null ? this.posSummaryToggle.toggle('CLOSE') : '';
          });
      });

    this.orderService.editMiscSubject.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.posMenuTabChangeService.changeTab(2);
      this.refreshExistingOrder();
    });

    this.currentOrderSub = this.currentOrderService.current.pipe(take(1)).pipe(takeUntil(this.unsubscribe)).subscribe((currentOrder) => {
      if (localStorage.getItem('setOccasionDetail') === null) {
        this.orderWithPayment = null;
        this.refreshService.refreshModule('POS_ORDER_SUMMARY');
      } else {
        if (currentOrder) {
          this.orderWithPayment = currentOrder;
          localStorage.setItem(
            'order_hash',
            this.orderWithPayment && this.orderWithPayment.bot_order && this.orderWithPayment.bot_order.order_hash
          );
          this.refreshService.refreshModule('POS_ORDER_SUMMARY');
        }
      }
    });

    this.orderTabChangeSub = this.posOrderTabChangeService.current.pipe(takeUntil(this.unsubscribe)).subscribe(
      (tabIndex) => {
        this.selected = tabIndex;
        if (this.tabGroup) {
          this.tabGroup.selectedIndex = tabIndex;
        }
      }
    );

    this.tableStatusTabChange = this.orderService.tableUpdate.pipe(takeUntil(this.unsubscribe)).subscribe(
      (result) => {
        if (result && this.occasionForm.value.dine_in_table_number) {
          this.orderService.updateTableStatus(Number(this.store.id), this.occasionForm.value.dine_in_table_number).pipe(takeUntil(this.unsubscribe)).subscribe((data:any) => {
            this.orderService.tableUpdate.next(false);
          });
        }
      }
    );

    this.defaultOccasionUpdate = this.orderService.updateDefaultOccasion.pipe(takeUntil(this.unsubscribe)).subscribe((result) => {
      if (result) {
        console.log('Setting Occasion Values');
        this.multipleDefaultLoad();
      }
    });

    /* Open Order Edit Set Occasion and Time ByDefault Start */
    const storeOccasionalDetails = JSON.parse(localStorage.getItem('setOccasionDetail'));
    if (storeOccasionalDetails != null) {
      this.getOccasion = storeOccasionalDetails.occasion;
      if (storeOccasionalDetails.time === 'NOW') {
        storeOccasionalDetails.time = 'ASAP'
        this.getTime = storeOccasionalDetails.time;
      } else {
        this.getTime = storeOccasionalDetails.time;
      }
    }
    this.occasionForm.patchValue({
      occasion: this.getOccasion,
      time: this.getTime
    });
    /* Open Order Edit Set Occasion and Time ByDefault End */
    this.orderService.keyboardValueSet.subscribe(value => {
      if (value == false) {
        this.keyboardCreated = false;
      }
    });
    this.occasionForm.get('order_name').valueChanges.subscribe((order_name) => {
      if (order_name) {
        this.occasionForm.get('delivery_address').enable();
      } else {
        this.occasionForm.get('delivery_address').reset();
        this.occasionForm.get('delivery_address').disable();
      }
    });
    this.occasionForm.get('phone_number').valueChanges.subscribe((number) => {
      if (!number) {
        this.occasionForm.get('order_name').reset();
        this.occasionForm.get('order_name').disable();
      }
    });


    this.permissionService.pinCurrentOperator.pipe(takeUntil(this.unsubscribe)).subscribe((permission) => {
      this.permission = permission;
    });

    this.floorPlanService.floorCreatedUpdate.subscribe(value => {
      if (value == false) {
        this.canvasCreated = false;
      }
    });

  }

  multipleDefaultLoad() {
    const terminalId = localStorage.getItem('terminalId');
    const posSet = this.store.pos_setting.find((ele) => ele.terminal_id == terminalId);
    this.occasionForm.patchValue({
      occasion: posSet.default_order_occasio,
      time: posSet.default_time
    });
    this.onOccasionSelect(posSet.default_order_occasion);
    this.onTimeSelect(posSet.default_time)
    if (this.ocassionsArray.includes(posSet.default_order_occasion)) {
      this.tabGroup.selectedIndex = 0;
    } else {
      if (posSet.default_time === 'LATER') {
        this.tabGroup.selectedIndex = 1;
      } else {
        this.tabGroup.selectedIndex = 2;
      }
    }
    this.orderService.defaultOccasionUpdate(this.orderHash, terminalId, this.store.id).pipe(takeUntil(this.unsubscribe)).subscribe((data) => {});
  }

  receiveShowQuickEntry(showQuickBtn: any) {
    if (this.selected == 2) {
      this.showQuickEntry = showQuickBtn;
    } else {
      this.showQuickEntry = true;
    }
  }

  openConfirmDialog(addressFromList) {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '364px'
    this.dialogConfig.data = {
      title: addressFromList
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.addressConfirmValue = data;
      this.afterConfirmAddress(this.addressConfirmValue);
    })
  }

  refreshOrder() {
    console.log('refreshing');
    console.log(this.orderHash);

    if (typeof this.orderHash === 'undefined' || this.orderHash === 'null' || this.orderHash === null) {
      this.getNewOrder();
    } else {
      this.refreshExistingOrder();
    }
  }

  // set for edit functionality order occasion and time value
  setValueOccasionForm() {

    const storeOccasionalDetail = JSON.parse(localStorage.getItem('setOccasionDetail'));
    if (storeOccasionalDetail.time === 'NOW') {
      storeOccasionalDetail.time = 'ASAP'
    }
    if (storeOccasionalDetail.time === 'LATER') {
      this.retrievedDate = new Date(moment(storeOccasionalDetail.occasion_schedule).format('MM/DD/YYYY hh:mm A'));
      this.retrievedTime = moment.utc(storeOccasionalDetail.occasion_schedule).format('hh:mm A ');
      console.log(this.retrievedDate);
      console.log(this.retrievedTime);
    } else {
      this.retrievedDate = null;
      this.retrievedTime = null;
    }

    this.occasionForm.setValue({
      occasion: storeOccasionalDetail.occasion,
      time: storeOccasionalDetail.time,
      dine_in_table_number: storeOccasionalDetail.dine_in_table_number,
      schedule_date: this.retrievedDate,
      schedule_time: this.retrievedTime,
      delivery_address: storeOccasionalDetail.delivery_address,
      curbside_vehicle_id: storeOccasionalDetail.curbside_vehicle_id,
    });

    console.log(this.occasionForm);
    this.buildTimeRange(storeOccasionalDetail.occasion);

    if (this.occasionForm.valid && this.occasionForm.value.occasion && this.occasionForm.value.time) {
      this.onTableSelect(storeOccasionalDetail.dine_in_table_number)
      this.tabGroup.selectedIndex = 2;
      this.posOrderTabChangeService.changeTab(2);
    }

  }
  ngAfterViewInit() {
    if (localStorage.getItem('setOccasionDetail') != null) {
      setTimeout(() => {
        this.getAddress();
      }, 1000)
    }
  }

  getDefaultOccasion() {
    if (this.orderWithPayment && this.orderWithPayment.bot_order.occasion) {
      this.setDefaultOccasion = this.orderWithPayment.bot_order.occasion;
      this.setDefaultTime = this.orderWithPayment.bot_order.time;
      if (this.setDefaultTime == 'NOW') {
        this.setDefaultTime = 'ASAP'
      }
      console.log(this.setDefaultOccasion, this.setDefaultTime);
      this.occasionForm.patchValue({
        occasion: this.setDefaultOccasion,
        time: this.setDefaultTime
      });
      this.onOccasionSelect(this.setDefaultOccasion);
      this.onTimeSelect(this.setDefaultTime);
      if (this.ocassionsArray.includes(this.setDefaultOccasion)) {
        this.tabGroup.selectedIndex = 0;
      } else {
        if (this.setDefaultTime === 'LATER') {
          this.tabGroup.selectedIndex = 1;
        } else {
          this.tabGroup.selectedIndex = 2;
        }
      }
      this.buildTimeRange('');
    }
  }

  updateProfile() {
    if (localStorage.getItem('setOccasionDetail') != null) {
      this.setValueOccasionForm();
    } else {
      this.orderService.getOrderByHash(this.orderHash).pipe(takeUntil(this.unsubscribe)).subscribe({
        next: (resultOrder) => {
          const botOrder = resultOrder;
          let posSettings;
          if (this.store.feature_flag['multiple_default_load']) {
            const terminalId = localStorage.getItem('terminalId');
            posSettings = this.store.pos_setting.find((ele) => ele.terminal_id == terminalId);
          } else {
            posSettings = botOrder.store.pos_setting;
          }
          this.defaultOrderOccasion = posSettings.default_order_occasion;
          this.defaultTime = posSettings.default_time;
          if (posSettings.default_load) {
            this.occasionForm.patchValue({
              occasion: this.defaultOrderOccasion,
              time: this.defaultTime
            });
            this.onOccasionSelect(this.defaultOrderOccasion);
            this.selectedOccasion = this.defaultOrderOccasion;
            this.onTimeSelect(this.defaultTime)
            if (this.ocassionsArray.includes(posSettings.default_order_occasion)) {
              this.tabGroup.selectedIndex = 0;
            } else {
              if (posSettings.default_time === 'LATER') {
                this.tabGroup.selectedIndex = 1;
              } else {
                this.tabGroup.selectedIndex = 2;
              }

            }
            this.buildTimeRange('');
          } else {
            this.getDefaultOccasion();
          }
        },
        error: err => {
          this.occasionForm.patchValue({
            occasion: this.defaultOrderOccasion,
            time: this.defaultTime
          });
          console.log(err)
        }
      });
    }
  }

  refreshTerminal() {
    this.orderService
      .getTerminalId(this.store.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        if (response.length > 0) {
          this.terminalId = response[0].terminal_id;
          this.terminalService.changeTerminal(this.terminalId);
        }
      });
  }

  getNewOrder() {
    this.orderService
      .getTempPOSOrderWithPhone(this.store.id, this.store.sms_number, this.cashierName)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((orderHash) => {
        this.orderHash = orderHash.order_hash;
        localStorage.setItem('order_hash', orderHash.order_hash);
        this.orderService.occasionUpdate.next(false);
        let posSettings
        if (this.store.feature_flag['multiple_default_load']) {
          const terminalId = localStorage.getItem('terminalId');
          posSettings = this.store.pos_setting.find((ele) => ele.terminal_id == terminalId);
        } else {
          posSettings = this.store.pos_setting;
        }
        if (this.store && posSettings && posSettings.pos_setting_id) {
          this.updateProfile();
        }
        setTimeout(() => {
          this.orderService
            .getOrder(orderHash.order_hash)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
              if (result) {
                // this.orderWithPayment = OrderHelpers.parseOrderwithPayment(result);
                this.initializeOrderTab(result);
              }
            });
        }, 500);
      });
  }

  refreshExistingOrder() {
    this.orderService
      .getOrder(this.orderHash)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        if (result) {
          this.orderWithPayment = OrderHelpers.parseOrderwithPayment(result);
          this.currentOrderService.changeCurrentOrder(this.orderWithPayment);
          this.initializeOrderTab(result);
        }
      });
  }

  resetState() {
    this.refresh.emit();
    this.form.reset();
    this.toggleSummaryDrawer.emit();
    this.posMenuTabChangeService.changeTab(0);
    this.tabGroup.selectedIndex = 0;
    this.orderWithPayment = null;
    this.orderCounter = '';
    this.selectedOccasion = null;
    this.selectedTime = null;
    if (this.selectedTable != null) {
      this.selectedTable = null;
    }
    // if(this.retrievedAddress != null){
    this.retrievedAddress = '';
    // }
    this.occasionForm.controls.time.reset();
    this.occasionForm.controls.curbside_vehicle_id.reset();
    this.occasionForm.controls.occasion.reset();
    this.occasionForm.controls.delivery_address.reset();
    this.occasionForm.controls.dine_in_table_number.reset();
    this.occasionForm.controls.schedule_date.reset();
    this.occasionForm.controls.schedule_time.reset();
    this.occasionForm.controls.curbside_vehicle_id.reset();
    this.occasionForm.controls.order_name.reset();
    // this.occasionForm.controls.phone_number.reset();
    if (!this.store.pos_setting.default_load) {
      this.occasionDetail = new OccasionDetail();
      this.occasionForm.controls.time.reset();
      this.occasionForm.controls.occasion.reset();
    }
    this.posOrderTabChangeService.changeTab(0);

  }

  ngDoCheck() {
    // if (this.addButtonWidth !== this.container.nativeElement.offsetWidth) {
    if (this.container) {
      this.addButtonWidth = this.container.nativeElement.offsetWidth;
    }

  }

  initializeOrderTab(result) {
    this.orderWithPayment = OrderHelpers.parseOrderwithPayment(result);
    this.occasionDetail = this.orderWithPayment.getOccasionDetail();
    // this.selectedOccasion = this.occasionDetail.occasion;
    // this.selectedTime = this.occasionDetail.time;
    // console.log(occasionDetail.occasion , occasionDetail.time);

    // if(occasionDetail.occasion && occasionDetail.time){
    //   this.tabGroup.selectedIndex = 2
    // }
  }
  onTimeSelectClick(event) {
    console.log(event);
    this.timeSelectionClicked = true;
    this.occasionForm.controls.schedule_date.value != null ? this.tabGroup.selectedIndex = 2 : this.orderService.errorMessage('Please select schedule date', 'OK', 3000);

  }

  tabChange(event) {

    console.log('Tab Change VENT', this.tabGroup.selectedIndex, event.index);
    if (this.tabGroup.selectedIndex !== event.index) { return; }

    if (event.index == 2 && (this.occasionForm.value.occasion == "DINE-IN" || this.occasionForm.value.occasion == "TABLESIDE") && !this.occasionForm.value.dine_in_table_number) {
      this.snackBar.open('Please select the table', 'OK');
      this.tabGroup.selectedIndex = 0;
      return;
    }

    // GLITCH culprit below.
    // this.tabGroup.selectedIndex = event.index;

    let posSettings
    if (this.store.feature_flag['multiple_default_load']) {
      const terminalId = localStorage.getItem('terminalId');
      posSettings = this.store.pos_setting.find((ele) => ele.terminal_id == terminalId);
    } else {
      posSettings = this.store.pos_setting;
    }
    if (this.store && posSettings && posSettings.default_load && this.isDefaultLoad == posSettings.default_load) {
      this.isDefaultLoad = true;
    }
    else {
      this.isDefaultLoad = false;
    }
    if (this.store && posSettings && posSettings.default_load && posSettings.default_order_occasion === 'PICKUP' && posSettings.default_time === 'ASAP') {
      if (posSettings.default_order_occasion !== 'PICKUP') {
        this.onOccasionSelect(posSettings.default_order_occasion);
      }
      this.onTimeSelect(posSettings.default_time);
      this.tabGroup.selectedIndex = event.index;
    }

    if (event.index > 1 && this.occasionForm.value.time === 'LATER') {
      if (
        this.occasionForm.controls.schedule_date.value === null) {
        this.tabGroup.selectedIndex = 1;
        event.index = 1;
        this.orderService.errorMessage('Please select schedule date', 'OK', 3000);
      } else if (
        this.occasionForm.controls.schedule_time.value === null
      ) {
        this.tabGroup.selectedIndex = 1;
        event.index = 1;
        this.orderService.errorMessage('Please select schedule time', 'OK', 3000);
      }
      this.isDefaultLoad = this.store.pos_setting.default_load;
    }

    if (event.index === 2 && this.occasionForm.valid) {
      console.log(this.occasionForm.value);
      const payload = this.occasionForm.value;
      if (payload.time === 'LATER'
        && this.occasionForm.value.schedule_date != null
        && this.occasionForm.value.schedule_time != null) {
        if (this.occasionForm.value.schedule_date) {
          const occasionSchedule = moment(
            this.occasionForm.value.schedule_date.toISOString().split('T')[0] +
            ' ' +
            payload.schedule_time,
            'YYYY-MM-DD hh:mm A'
          );
          payload.occasion_schedule = occasionSchedule;
        }
      } else if (payload.time === 'LATER'
        && this.occasionForm.value.schedule_date == null
        && this.occasionForm.value.schedule_time == null) {
        this.tabGroup.selectedIndex = 1;
        event.index = 1;
        this.alertPopup('', 'Please select schedule date');
        // alert("Please select schedule date")
      } else {
        event.index = 2;
        payload.occasion_schedule = moment();
      }

      this.buildTimeRange('');
      this.orderHash = localStorage.getItem('order_hash');
      console.log(this.orderHash, 'update occasion api call', payload);

      if (payload.occasion === 'PICKUP' || payload.occasion === 'DELIVERY' || payload.occasion === 'CURBSIDE') {
        payload.dine_in_table_number = null;
      }

      this.orderService.occasionUpdate.pipe(takeUntil(this.unsubscribe)).subscribe(r => {
        this.orderHash = localStorage.getItem('order_hash');
        if (!payload.delivery_address) {
          payload["delivery_address"] = this.occasionForm.controls.delivery_address.value;
        }
        if (!payload.order_name) {
          payload["order_name"] = this.occasionForm.controls.order_name.value;
        }
        this.orderService
          .updateOccasionSchedule(this.orderHash, payload).pipe(takeUntil(this.unsubscribe))
          .subscribe((data) => {
            this.refresh.emit(true);
            if (payload.occasion === "DELIVERY") {
              this.clearDeliveryInput();
            }
          });
      });

    }
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.mapTypeControlOptions = {
          mapTypeIds: ['roadmap', 'satellite'],
          style: 1,
        };
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  public toggleVisibility(): void {
    this.hideTab = !this.hideTab;
  }

  updateOrderSummaryWidget(field: string, value: number | string | Table) {
    const currentOrder: IOrder = this.orderService.newOrderSubject.getValue();
    if (currentOrder) {
      this.orderService.newOrderSubject.next({
        ...currentOrder,
        [field]: value,
      });
    }
  }

  hourCompare(left, right) {
    return left.value === right.value;
  }

  configureValidations(field: string, condition: boolean) {
    console.log(field, condition);
    if (condition) {
      this.occasionForm.controls[field].setValidators([Validators.required]);
    } else {
      this.occasionForm.controls[field].setErrors(null);
      this.occasionForm.controls[field].clearValidators();
    }
    this.occasionForm.controls.delivery_address.updateValueAndValidity();
  }

  onOccasionSelect(occasion: string) {
    if (this.validUserData) {
      this.storeService
        .getTablesideTables(this.store.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((tables: Table[]) => (this.tables$ = tables));

      this.selectedOccasion = occasion ? occasion.toUpperCase() : '';
      this.configureValidations('delivery_address', occasion === 'DELIVERY');

      this.configureValidations(
        'dine_in_table_number',
        occasion === 'TABLESIDE'
      );
      this.configureValidations('dine_in_table_number', occasion === 'DINE-IN');
      this.updateOrderSummaryWidget('occasion', occasion);

      if (occasion !== 'DELIVERY') {
        this.updateOrderSummaryWidget('delivery_address', null);
      }

      if (this.store.feature_flag['table_floor_plan']) {
        if (occasion === 'DINE-IN' || occasion === 'TABLESIDE') {
          console.log('ppppppppppppppppppppppp');
          
          this.createFloorCanvas();
        } else {
          this.canvasCreated = false;
        }
      }

      if (occasion === 'PICKUP' || occasion === 'TO-GO' || occasion === 'FOR-HERE') {
        this.tabGroup.selectedIndex = 1;
        if (
          this.occasionForm.controls.occasion.value &&
          this.occasionForm.controls.time.value
        ) {
          this.tabGroup.selectedIndex = 2;
        }
      }
      if (occasion === "DELIVERY") {
        this.occasionForm.get('order_name').reset();
        this.occasionForm.get('delivery_address').reset();
        this.occasionForm.get('phone_number').reset();
        this.configureValidations('delivery_address', true);
        this.configureValidations('phone_number', true);
        this.configureValidations('order_name', true);
        this.previoudOrderOverview = null;
        this.addressList = null;
        this.previousOrderList = null;
        this.numberKeyData = "";
        this.occasionForm.get('order_name').disable();
        this.occasionForm.get('delivery_address').disable();
        setTimeout(() => {
          this.createVirtualKeyboards();
        }, 1000);
      } else {
        this.keyboardCreated = false;
      }
      // this.buildTimeRange(occasion);
    } else {
      setTimeout(() => {
        this.createVirtualKeyboards();
      }, 3000); //sumit-n
      // this.occasionForm.controls.occasion.patchValue(occasion);

      if (this.store.feature_flag['table_floor_plan']) {
        if (occasion === 'DINE-IN' || occasion === 'TABLESIDE') {
          this.createFloorCanvas();
        } else {
          this.canvasCreated = false;
        }
      }
    }
    this.occasionForm.controls.occasion.patchValue(occasion);
    this.buildTimeRange(occasion);

  }
  
  buildTimeRange(occasion) {

    let start = null;
    const leadTime = this.store.getLeadTime(this.occasionDetail.occasion);

    start = moment().add(leadTime, 'minutes');
    const remainder = 15 - (start.minute() % 15);
    start = moment(start).add(remainder, 'minutes');

    this.occasionSchedule = start;
    const times = 24 * 4; // 24 hours * 15 mins in an hour
    this.timeRange = new Array(times);
    for (let i = 0; i < times; i++) {
      const toPrint = moment(start).add(15 * i, 'minutes');
      this.timeRange[i] = toPrint;
    }
  }

  onTimeSelect(time: string) {
    this.showQuickEntry = true;
    if (this.validUserData) {
      this.selectedTime = time;
      this.configureValidations('schedule_date', time === 'Schedule for Later');

      this.updateOrderSummaryWidget('time', time);
      //sumit-n
      this.occasionForm.controls.time.patchValue(time);
    }
  }

  onTableSelect(tableId: string) {
    if (this.validUserData) {
      this.selectedTable = tableId;
      this.occasionForm.controls.dine_in_table_number.setValue(tableId);
      this.updateOrderSummaryWidget('dine_in_table_number', tableId);
      this.updateOrderSummaryWidget('dine_in_table_number', this.selectedTable);
      this.tabGroup.selectedIndex = 1;
      if ((this.occasionForm.controls.time.value === null || this.occasionForm.controls.time.value === undefined)
        ||
        (this.occasionForm.controls.time.value === 'LATER'
          && this.occasionForm.controls.schedule_date.value === null
          && this.occasionForm.controls.schedule_time.value === null)
      ) {

        this.tabGroup.selectedIndex = 1;

      } else {
        this.tabGroup.selectedIndex = 2;
      }
    }
  }

  onCurbsideSelect(event) {
    if (this.validUserData) {
      console.log(event);

      this.occasionForm.controls.curbside_vehicle_id.setValue(event);
      this.updateOrderSummaryWidget('curbside_vehicle_id', event);
      if (this.occasionForm.controls.time.value === null
        ||
        (this.occasionForm.controls.time.value === 'LATER'
          && this.occasionForm.controls.schedule_date.value === null
          && this.occasionForm.controls.schedule_time.value === null)
      ) {

        this.tabGroup.selectedIndex = 1;

      } else {
        this.tabGroup.selectedIndex = 2;
      }
    }
  }

  // onAutocompleteSelected(placeResult: PlaceResult) {
  //   this.occasionForm.controls.delivery_address.setValue(placeResult.formatted_address);
  //   if (placeResult.formatted_address) {
  //     this.tabGroup.selectedIndex = 1;
  //   }

  //   this.updateOrderSummaryWidget('delivery_address', placeResult.formatted_address);
  // }

  haversineDistance(mk1, mk2) {
    const R = 3958.8; // Radius of the Earth in miles
    const rlat1 = mk1.lat() * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = mk2.lat() * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (mk2.lng() - mk1.lng()) * (Math.PI / 180); // Radian difference (longitudes)

    const d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
          Math.cos(rlat2) *
          Math.sin(difflon / 2) *
          Math.sin(difflon / 2)
        )
      );
    return d;
  }

  getAc(v) {
    return {
      long_name: v.long_name,
      short_name: v.short_name,
      type: v.types[0],
    };
  }

  getAreaType(key, obj) {
    return obj.find((a) => a.type === key);
  }

  getCashierName() {
    if (this.validUserData) {
      const cashier = this.validUserData.currentUser;
      return cashier.first_name + ' ' + cashier.last_name
    }
    return ''
  }

  getAddress() {
    const storeOccasionalDetail = JSON.parse(localStorage.getItem('setOccasionDetail'));
    if (storeOccasionalDetail.delivery_address) {
      const autocompleteService = new google.maps.places.AutocompleteService();
      const request = { input: this.orderWithPayment.bot_order.delivery_address };
      autocompleteService.getPlacePredictions(request, (predictionsArr, placesServiceStatus) => {
        console.log('getting place predictions :: predictionsArr = ', predictionsArr, '\n',
          'placesServiceStatus = ', placesServiceStatus);

        const placeRequest = { placeId: predictionsArr[0].place_id };
        const placeService = new google.maps.places.PlacesService(document.createElement('div'));
        placeService.getDetails(placeRequest, (placeResult, placeServiceStatus) => {
          console.log('placeService :: placeResult = ', placeResult, '\n',
            'placeServiceStatus = ', placeServiceStatus);
          this.retrievedAddress = placeResult.formatted_address;
        });
      });
    }
  }

  onAutocompleteSelected(result: PlaceResult) {
    if (this.validUserData) {
      console.log('result map', result);
      if (this.occasionForm.controls.phone_number.valid && this.occasionForm.controls.order_name.valid) {

        if (this.store.gmb_location_id) {
          if (this.serviceAreas && this.serviceAreas.service_type === "RADIUS") {
            console.log(this.store.gmb_location_id);
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { placeId: this.store.gmb_location_id },
              (storeResult, status) => {
                if (storeResult.length > 0) {
                  let storeLocation = storeResult[0].geometry.location;
                  let deliveryLocation = result.geometry.location;
                  let deliveryDistance = this.haversineDistance(
                    storeLocation,
                    deliveryLocation
                  );
                  let deliveryRadius = +this.serviceAreas.radius;

                  console.log(deliveryDistance, deliveryRadius);

                  if (deliveryDistance <= deliveryRadius) {
                    this.deliveryAddress = result.formatted_address;
                    this.occasionDetail.delivery_address =
                      result.formatted_address;
                    this.occasionDetail.location_selected = true;
                    this.occasionForm.controls.delivery_address.setValue(
                      result.formatted_address
                    );
                    this.orderService
                      .updateDeliveryFee(
                        this.orderWithPayment.bot_order.id,
                        this.orderWithPayment.store.delivery_charge
                      )
                      .subscribe((data) => {
                        if (this.previoudOrderOverview) {
                          const payload = {
                            "current_order": this.orderWithPayment.bot_order.order_hash,
                            "previous_order": this.previoudOrderOverview.bot_order.order_hash,
                            "delivery_address": this.deliveryAddress,
                            "order_name": this.occasionForm.controls.order_name.value
                          }
                          this.orderService.posDeliveryReorder(this.store.id, payload).subscribe((data: any) => {
                            this.posSummaryToggleService.toggle('OPEN');
                            this.refresh.emit(true);

                            if (this.store.pos_setting['default_load'] && this.store.pos_setting['default_time'] === 'ASAP') {
                              this.tabGroup.selectedIndex = 2;
                            } else {
                              this.tabGroup.selectedIndex = 1;
                            }
                          });
                        } else {
                          if (this.store.pos_setting['default_load'] && this.store.pos_setting['default_time'] === 'ASAP') {
                            this.tabGroup.selectedIndex = 2;
                          } else {
                            this.tabGroup.selectedIndex = 1;
                          }
                        }
                        this.ref.detectChanges();
                      });
                  } else if (
                    confirm(
                      `The address you entered '${result.formatted_address}' is outside of the current delivery area\n\nWould you like to proceed anyway?`
                    )
                  ) {
                    console.log('after confirm dialog');

                    this.deliveryAddress = result.formatted_address;
                    this.occasionDetail.delivery_address =
                      result.formatted_address;
                    this.occasionDetail.location_selected = true;
                    this.occasionForm.controls.delivery_address.setValue(
                      result.formatted_address
                    );
                    this.orderService
                      .updateDeliveryFee(
                        this.orderWithPayment && this.orderWithPayment.bot_order && this.orderWithPayment.bot_order.id,
                        this.orderWithPayment && this.orderWithPayment.store && this.orderWithPayment.store.delivery_charge
                      )
                      .subscribe((data) => {
                        if (this.previoudOrderOverview) {
                          const payload = {
                            "current_order": this.orderWithPayment.bot_order.order_hash,
                            "previous_order": this.previoudOrderOverview.bot_order.order_hash,
                            "delivery_address": this.deliveryAddress,
                            "order_name": this.occasionForm.controls.order_name.value
                          }
                          this.orderService.posDeliveryReorder(this.store.id, payload).subscribe((data: any) => {
                            this.posSummaryToggleService.toggle('OPEN');
                            this.refresh.emit(true);
                            if (this.store.pos_setting['default_load'] && this.store.pos_setting['default_time'] === 'ASAP') {
                              this.tabGroup.selectedIndex = 2;
                            } else {
                              this.tabGroup.selectedIndex = 1;
                            }
                          });
                        } else {
                          if (this.store.pos_setting['default_load'] && this.store.pos_setting['default_time'] === 'ASAP') {
                            this.tabGroup.selectedIndex = 2;
                          } else {
                            this.tabGroup.selectedIndex = 1;
                          }
                        }
                        this.ref.detectChanges();
                      });
                  } else {
                    this.locationSelected = false;
                    this.deliveryAddress = undefined;
                    this.occasionDetail.delivery_address = undefined;
                    this.occasionDetail.location_selected = false;
                    this.deliveryAddressInput.nativeElement.focus();
                  }
                }
              }
            );
            console.log('onAutocompleteSelected: ', result);
          } else {
            let areas = this.serviceAreas.areas;
            let levels = result.address_components.map((ac) => this.getAc(ac));
            console.log(areas);
            this.occasionDetail.location_selected = false;
            areas.forEach((area) => {
              let matches = area.levels.map((al) => {
                let gLevel = this.getAreaType(al.type, levels);
                return gLevel && gLevel.long_name === al.long_name;
              });
              if (
                matches.every((b) => b) &&
                !this.occasionDetail.location_selected
              ) {
                this.deliveryAddress = result.formatted_address;
                this.occasionDetail.delivery_address = result.formatted_address;
                this.occasionDetail.location_selected = true;
                this.occasionForm.controls.delivery_address.setValue(
                  result.formatted_address
                );
                this.orderService
                  .updateDeliveryFee(
                    this.orderWithPayment.bot_order.id,
                    area.charge
                  )

                  .subscribe((data) => {
                    if (this.previoudOrderOverview) {
                      const payload = {
                        "current_order": this.orderWithPayment.bot_order.order_hash,
                        "previous_order": this.previoudOrderOverview.bot_order.order_hash,
                        "delivery_address": this.deliveryAddress,
                        "order_name": this.occasionForm.controls.order_name.value
                      }
                      this.orderService.posDeliveryReorder(this.store.id, payload).subscribe((data: any) => {
                        this.posSummaryToggleService.toggle('OPEN');
                        this.refresh.emit(true);
                        if (this.store.pos_setting['default_load'] && this.store.pos_setting['default_time'] === 'ASAP') {
                          this.tabGroup.selectedIndex = 2;
                        } else {
                          this.tabGroup.selectedIndex = 1;
                        }
                      });
                    } else {
                      if (this.store.pos_setting['default_load'] && this.store.pos_setting['default_time'] === 'ASAP') {
                        this.tabGroup.selectedIndex = 2;
                      } else {
                        this.tabGroup.selectedIndex = 1;
                      }
                    }
                    this.ref.detectChanges();
                  });
              } else {
                if (!this.occasionDetail.location_selected) {
                  this.dialog.closeAll();
                  this.dialogConfig = new MatDialogConfig();
                  this.dialogConfig.disableClose = true;
                  this.dialogConfig.autoFocus = true;
                  this.dialogConfig.width = '364px'
                  this.dialogConfig.data = {
                    title: result.formatted_address
                  }
                  const dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
                  dialogRef.afterClosed().subscribe((data) => {
                    if (data != undefined) {
                      if (data) {
                        this.deliveryAddress = result.formatted_address;
                        this.occasionDetail.delivery_address = result.formatted_address;
                        this.occasionDetail.location_selected = true;
                        this.occasionForm.controls.delivery_address.setValue(
                          result.formatted_address
                        );
                        this.orderService
                          .updateDeliveryFee(
                            this.orderWithPayment.bot_order.id,
                            area.charge
                          )
                          .subscribe((data) => {
                            if (this.previoudOrderOverview) {
                              const payload = {
                                "current_order": this.orderWithPayment.bot_order.order_hash,
                                "previous_order": this.previoudOrderOverview.bot_order.order_hash,
                                "delivery_address": this.deliveryAddress,
                                "order_name": this.occasionForm.controls.order_name.value
                              }
                              this.orderService.posDeliveryReorder(this.store.id, payload).subscribe((data: any) => {
                                this.posSummaryToggleService.toggle('OPEN');
                                this.refresh.emit(true);
                                if (this.store.pos_setting['default_load'] && this.store.pos_setting['default_time'] === 'ASAP') {
                                  this.tabGroup.selectedIndex = 2;
                                } else {
                                  this.tabGroup.selectedIndex = 1;
                                }
                              });
                            } else {
                              if (this.store.pos_setting['default_load'] && this.store.pos_setting['default_time'] === 'ASAP') {
                                this.tabGroup.selectedIndex = 2;
                              } else {
                                this.tabGroup.selectedIndex = 1;
                              }
                            }
                            this.ref.detectChanges();
                          });
                      } else {
                        this.locationSelected = false;
                        this.deliveryAddress = undefined;
                        this.occasionDetail.delivery_address = undefined;
                        this.occasionDetail.location_selected = false;
                      }
                    }
                  })
                }
              }
            });
          }
        }
      } else {
        this.deliveryAddress = result.formatted_address;
        this.occasionDetail.delivery_address = result.formatted_address;
        this.snackBar.open('Please enter Customer Name', 'OK', { duration: 2000, verticalPosition: 'top' });
      }
    }
  }


  onLocationSelected(location: Location) {
    if (this.validUserData) {
      this.latitude = location.latitude;
      this.longitude = location.longitude;
    }
  }

  updateHoursBasedOnSelectedDate(picker?: NgxMatDatepickerInputEvent<string>) {

    this.checkScheduleDate = picker ? moment(picker.value) : moment();
    // const date = picker ? moment(picker.value) : moment();
    let todayDateNumber: number = this.checkScheduleDate.day() - 1;
    if (todayDateNumber < 0) todayDateNumber = 6;
    const todaySchedule = this.workingHours[todayDateNumber];
    console.log(this.workingHours, todayDateNumber);

    this.hours = [];
    if (todaySchedule.open_type === 'OPEN') {
      // console.log('hours: ', this.workingHours);
      this.hours = this.formUtils.buildTimeOptions();
    }
    if (this.occasionForm.controls.schedule_date.value
      && this.occasionForm.controls.schedule_time.value) {
      this.tabGroup.selectedIndex = 2;
    }
  }

  get12HourString(hour: number, min: number) {
    const minString = pad(min, 2);
    if (hour === 12) {
      return `${hour}:${minString} pm`;
    } else if (hour > 12) {
      return `${hour - 12}:${minString} pm`;
    } else if (hour === 0) {
      return `${hour + 12}:${minString} am`;
    } else {
      return `${hour}:${minString} am`;
    }
  }

  moveToNextStep() {
    this.stepper.next();
    this.stepNumber++;
  }

  onMenuGroupSelect(menuGroup: MenuGroup) {
    this.moveToNextStep();
    this.toggleVisibility();
    this.currentMenuTitle = '';
    this.form.controls.menuGroupId.setValue(menuGroup.id);
  }

  public selectedSearchFiltering(menuGroupItem: MenuGroupItem): void {
    this.moveToNextStep();
    this.currentMenuTitle = '';
    this.form.controls.menuGroupId.setValue(menuGroupItem.menu_group_id);
    this.form.controls.menuGroupItemId.setValue(menuGroupItem.id);
  }

  onMenuItemGroupSelect(menuItemGroup: MenuItemGroup) {
    if (this.loading) {
      return false;
    }
    this.previousMenuTitle = this.currentMenuTitle;
    this.currentMenuTitle = menuItemGroup.name;
    this.form.controls.menuGroupItemId.setValue(menuItemGroup.id);
  }

  onSelectModifier(modifier: ModifierOption) {
    if (
      this.modifierGroup$.max_mods === 2 &&
      this.form.controls.modifiers.value.length === 2
    ) {
      this.snackBar.open(
        `Item can have ${this.modifierGroup$.max_mods
        } '${this.modifierGroup$.name.toLowerCase()}' only!`,
        'OK',
        {
          duration: 2000,
          verticalPosition: 'top',
        }
      );
      return;
    } else if (
      this.modifierGroup$.max_mods === 1 &&
      this.form.controls.modifiers.value.length === 1
    ) {
      this.snackBar.open(
        `Item can have ${this.modifierGroup$.max_mods
        } '${this.modifierGroup$.name.toLowerCase()}' only!`,
        'OK',
        {
          duration: 2000,
          verticalPosition: 'top',
        }
      );
      return;
    }

    const currentModifiers: number[] = [...this.form.controls.modifiers.value];

    const price: number = this.totalPrice;
    let calculatedPrice: number;
    if (currentModifiers.indexOf(modifier.id) > -1) {
      const countedPrice =
        modifier.upcharge * this.modifierQuantities[modifier.id];
      currentModifiers.splice(currentModifiers.indexOf(modifier.id), 1);
      calculatedPrice = +price - countedPrice;
      if (this.modifierDirections && this.modifierDirections[modifier.id]) {
        delete this.modifierDirections[modifier.id];
      }

      if (this.modifierQuantities && this.modifierQuantities[modifier.id]) {
        delete this.modifierQuantities[modifier.id];
      }
    } else {
      currentModifiers.push(modifier.id);
      calculatedPrice = +price + +modifier.upcharge;

      if (modifier.has_quantity) {
        if (!this.modifierQuantities) {
          this.modifierQuantities = { [modifier.id]: 1 };
        } else if (!this.modifierQuantities[modifier.id]) {
          this.modifierQuantities[modifier.id] = 1;
        }
      }
    }

    this.totalPrice = calculatedPrice;

    this.form.controls.modifiers.setValue([...currentModifiers]);
  }

  setModifierDirection(event: MatRadioChange, modifierId: number) {
    if (this.modifierDirections) {
      this.modifierDirections[modifierId] = event.value;
    } else {
      this.modifierDirections = { [modifierId]: event.value };
    }
  }

  setModifierQuantities(
    event: Event,
    modifier: ModifierOption,
    operation: '-' | '+'
  ) {
    event.stopImmediatePropagation();

    if (operation === '+') {
      this.modifierQuantities[modifier.id]++;
      this.totalPrice += +modifier.upcharge;
    } else if (this.modifierQuantities[modifier.id] !== 1) {
      this.modifierQuantities[modifier.id]--;
      this.totalPrice -= +modifier.upcharge;
    }
  }

  backMenuStep(ev: Event) {
    ev.stopImmediatePropagation();
    this.stepper.previous();
    if (this.stepNumber > 1) {
      if (this.stepNumber === 3) {
        this.currentMenuTitle = this.previousMenuTitle;
        this.form.controls.menuItemId.setValue(null);
        this.form.controls.modifiers.setValue([]);
        this.modifierDirections = null;
        this.modifierQuantities = null;
      } else if (this.stepNumber === 2) {
        this.currentMenuTitle = '';
        this.form.controls.menuGroupItemId.setValue(null);
      }

      this.previousMenuTitle = '';
      this.stepNumber--;
    }
  }

  resetSteps() {
    this.stepNumber = 1;
    this.form.controls.menuGroupId.setValue(null);
    this.form.controls.menuGroupItemId.setValue(null);
    this.form.controls.menuItemId.setValue(null);
    this.form.controls.modifiers.setValue([]);

    if (this.modifierGroup$) {
      this.modifierGroup$.options = [];
    }

    this.currentMenuTitle = '';
    this.previousMenuTitle = '';
    this.stepper.selectedIndex = 0;
  }

  handleEditSubmit() {
    const { menuGroupItemId, menuItemId, modifiers } = this.form.value;
    const modifierItems = modifiers.map((modifierId: number) => {
      const modifierOption: ModifierOption = this.modifierGroup$.options.find(
        (mg) => mg.id === modifierId
      );

      return {
        ...modifierOption,
        direction: this.modifierDirections,
        hasQuantity: this.modifierQuantities,
      };
    });

    const item: IOrderItem = {
      ...this.editItem,
      menuGroupItemId,
      name: this.menuItems$.find(
        (menuItem: MenuItem) => menuItem.id === menuItemId
      ).name,
      // base_price: this.menuItems$.find((menuItem: MenuItem) => menuItem.id === menuItemId).base_price,
      price: this.totalPrice,
      modifiers: modifierItems,
    };
    console.log('item', item);
    // this.a = this.menuItems$.find((menuItem: MenuItem) => menuItem.id === menuItemId).base_price;
    // console.log('aaaaa', this.a)

    this.orderService.editSubmitSubject.next(item);
    this.editItem = null;
    this.resetSteps();
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    console.log(this.form.value);

    if (!this.form.invalid) {
      const { menuGroupItemId, menuItemId, modifiers } = this.form.value;

      const modifierItems = modifiers.map((modifierId: number) => {
        const modifierOption: ModifierOption = this.modifierGroup$.options.find(
          (mg) => mg.id === modifierId
        );

        return {
          ...modifierOption,
          direction:
            this.modifierDirections && this.modifierDirections[modifierId]
              ? this.modifierDirections[modifierId]
              : modifierOption.has_half_mod
                ? 'WHOLE'
                : null,
          hasQuantity:
            this.modifierQuantities && this.modifierQuantities[modifierId]
              ? this.modifierQuantities[modifierId]
              : modifierOption.has_quantity
                ? this.quantities
                : null,
        };
      });

      const menuItem: MenuItem = this.menuItems$.find(
        (mi) => mi.id === menuItemId
      );
      const orderItem: IOrderItem = {
        ...menuItem,
        menuGroupItemId,
        price: this.totalPrice,
        quantity: 1,
        modifiers: modifierItems,
      };

      let items;
      const currentOrder: IOrder = this.orderService.newOrderSubject.getValue();
      if (currentOrder && currentOrder.items && currentOrder.items.length) {
        items = [...currentOrder.items, orderItem];
      } else {
        items = [orderItem];
      }

      // const order: IOrder = {
      //   counter: this.orderCounter,
      //   occasion,
      //   time,
      //   address,
      //   schedule,
      //   timeSchedule,
      //   tableId,
      //   curbSide,
      //   occasion_schedule: '',
      //   items
      // };

      // order.table = this.tables$.find((table: Table) => table.id === +order.tableId);

      const modifierItemSubmit = modifierItems.map((modVal) => {
        return {
          modifier: {
            id: modVal.id,
          },
          quantity: modVal.hasQuantity ? modVal.hasQuantity : 1,
          mod_type: modVal.direction,
        };
      });

      this.orderService
        .addItemId(this.orderHash, menuItemId, {
          modifiers: modifierItemSubmit,
          quantity: 1,
        })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          () => {
            // this.orderService.newOrderSubject.next(order);

            this.toggleShowSummaryDrawer.next();

            if (this.form.controls.search.value) {
              this.form.controls.search.setValue('');
              return;
            }

            this.resetSteps();
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    if (this.orderTabChangeSub) {
      this.orderTabChangeSub.unsubscribe();
    }
    if (this.currentOrderSub) {
      this.currentOrderSub.unsubscribe();
      localStorage.removeItem('order_hash');
    }
    this.canvasCreated = false;

    if (this.tableStatusTabChange) {
      this.tableStatusTabChange.unsubscribe();
    }

    this.orderService.updateDefaultOccasion.next(false);
    if (this.defaultOccasionUpdate) {
      this.defaultOccasionUpdate.unsubscribe();
    }

  }

  updateOccasionDetails() { }

  capitalize(value) {
    return capitalize(value);
  }

  openQuickEntry() {
    if (this.validUserData) {
      if (!this.disableQuickEntry) {
        this.orderHash = localStorage.getItem('order_hash');
        this.disableQuickEntry = true;
        this.posSummaryToggleService.toggle('OPEN');
        const payload = this.occasionForm.value;
        if (!payload.occasion) {
          payload.occasion = 'PICKUP';
        }

        if (!payload.time) {
          payload.time = 'NOW';
        }

        if (!payload.occasion_schedule) {
          payload.occasion_schedule = moment();
        }

        this.orderService
          .updateOccasionSchedule(this.orderHash, payload)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((data) => {
            console.log(data);
            this.orderService
              .getOrder(this.orderHash)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe((result) => {
                this.orderWithPayment =
                  OrderHelpers.parseOrderwithPayment(result);
                const isMobile = window.innerWidth <= 470;
                const dialogRef = this.dialog.open(CalculatorComponent, {
                  // width: '600px',
                  // height: '660px',
                  width: isMobile ? '100vw' : '600px',
                  height: isMobile ? 'auto' : '690px',
                  maxWidth: isMobile ? '100vw' : '',
                  position: !isMobile ? { right: '150px' } : null,
                  data: {
                    order_hash: this.orderWithPayment.bot_order.order_hash,
                  },
                  panelClass: 'cash-card-checkout'
                });

                dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                  this.posSummaryToggleService.toggle('OPEN');
                  this.refreshService.refreshModule(Modules.POS_ORDER_SUMMARY);
                  this.currentOrderService.changeCurrentOrder(this.orderWithPayment);
                  this.disableQuickEntry = false;
                });
              });
          });
      }
    }
  }

  quickDiscount() {
    if (!this.disableDiscountEntry) {
      this.orderHash = localStorage.getItem("order_hash");
      this.disableDiscountEntry = true;
      this.posSummaryToggleService.toggle("OPEN");
      let payload = this.occasionForm.value;
      if (!payload["occasion"]) {
        payload["occasion"] = "PICKUP";
      }

      if (!payload["time"]) {
        payload["time"] = "NOW";
      }

      if (!payload["occasion_schedule"]) {
        payload["occasion_schedule"] = moment();
      }

      this.orderService
        .getOrder(this.orderHash)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((result) => {
          this.orderWithPayment =
            OrderHelpers.parseOrderwithPayment(result);
          const isMobile = window.innerWidth <= 470;
          let dialogRef = this.dialog.open(CalculatorComponent, {
            width: isMobile ? '100vw' : '600px',
            height: isMobile ? 'auto' : '690px',
            maxWidth: isMobile ? '100vw' : '',
            position: !isMobile ? { right: '150px' } : null,
            data: {
              order_hash: this.orderWithPayment.bot_order.order_hash,
              key_id: 1,
            },
            panelClass: 'cash-card-checkout'
          });

          dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.posSummaryToggleService.toggle("OPEN");
            this.refreshService.refreshModule(Modules.POS_ORDER_SUMMARY);
            this.currentOrderService.changeCurrentOrder(this.orderWithPayment);
            this.disableDiscountEntry = false;
          });
        });
    }
  }

  userPinPermission(e) {
    const dialogRef = this.dialog.open(PinConfirmComponent, {
      width: '500px',
      // height: '410px',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.pinPermission = data.permission;
      if (this.pinPermission) {
        if (e == 'discount') {
          if (this.pinPermission.hasPermission('ORDERS', 'INSTANT_DISCOUNT')) {
            this.quickDiscount();
          } else {
            const msg = 'This User/Manager does not have the required permission to perform this operation!'
            this.alertPopup('', msg)
          }
        } else if (e == 'drawer') {
          if (this.pinPermission.hasPermission('POS_FUNCTIONS', 'OPEN_DRAWER')) {
            this.openDrawer();
          } else {
            const msg = 'This User/Manager does not have the required permission to perform this operation!'
            this.alertPopup('', msg)
          }
        } else if (e == 'cash_drawer') {
          if (this.pinPermission.hasPermission('POS_FUNCTIONS', 'MANAGE_DRAWER')) {
            this.manageDrawer();
          } else {
            const msg = 'This User/Manager does not have the required permission to perform this operation!'
            this.alertPopup('', msg)
          }
        }
      }


    });
  }

  openDiscount() {
    if (this.validUserData) {
      if (this.permission.hasPermission('ORDERS', 'INSTANT_DISCOUNT')) {
        this.quickDiscount();
      } else {
        this.userPinPermission('discount');
      }
    }
  }

  openDrawerPermissiion() {
    if (this.validUserData) {
      if (this.permission.hasPermission('POS_FUNCTIONS', 'OPEN_DRAWER')) {
        this.openDrawer();
      } else {
        this.userPinPermission('drawer');
      }
    }
  }

  openDrawer() {
    if (this.validUserData) {
      const selectedTerminal = localStorage.getItem('selectedTerminal');
      this.posService.openCashDrawer(selectedTerminal).pipe(takeUntil(this.unsubscribe)).subscribe(
        (data) => {
          console.log('drawer opened');
          this.orderService.errorMessage('Cash drawer opened successfully', 'OK', 1000);
        },
        (error) => {
          this.orderService.errorMessage('Unable to open cash drawer', 'OK', 2000);
        }
      );
    }
  }

  loadBotOrders(storeId: number, filter = '',
    startDate = null, endDate = null,
    sortField = 'id', sortOrder = 'desc',
    pageNumber = 0, pageSize = 10, cashierId = '') {
    this.businessService.findBotOrders(storeId, filter, startDate, endDate, sortField,
      sortOrder, pageNumber, pageSize, cashierId).pipe(takeUntil(this.unsubscribe)).subscribe(botOrders => {
        this.lastOrderHash = botOrders.orders[0].order_hash
        console.log('bot-orders', botOrders, this.lastOrderHash);
      });
  }

  rePrintLastReceipt() {
    const filter = ''
    const startDate = null
    const endDate = null
    const sortField = 'id'
    const sortOrder = 'desc'
    const pageNumber = 0
    const pageSize = 10
    const cashierId = ''
    this.businessService.findBotOrders(this.idStore, filter, startDate, endDate, sortField,
      sortOrder, pageNumber, pageSize, cashierId).pipe(takeUntil(this.unsubscribe)).subscribe(botOrders => {
        this.lastOrderHash = botOrders.orders[0].order_hash
        const terminalName = localStorage.getItem('selectedTerminalName') ? localStorage.getItem('selectedTerminalName') : 'NA';
        this.orderService.printOrder(this.lastOrderHash, terminalName).pipe(takeUntil(this.unsubscribe)).subscribe(
          data => {
            this.snackBar.open('Order printed successfully', 'OK', { duration: 2000 });
          },
          error => {
            this.snackBar.open('Unable to print order', 'OK', { duration: 2000 });
          })
      });

  }

  openBuildOrder() { }

  getParserMenuItems(items) {
    this.parserMenuItems.emit(items);
  }


  updatePhoneNumber(phoneNumber: string, orderName: string = '') {
    let ph = phoneNumber.replace(/[\s()-]/g, '');
    const aa = '+1' + ph.substring(0, 11);
    if (aa.length <= 12) {
      if (Utils.getPlainPhoneNumberFromString(phoneNumber).length === 12) {
        this.normalNumber = phoneNumber.replace(/[^a-zA-Z0-9+]/g, "");
        const plainPhoneNumber = Utils.getPlainPhoneNumberFromString(phoneNumber);
        const finalOrderName = this.occasionForm.value.order_name;
        this.orderService.updatePhoneNumber(this.orderHash, plainPhoneNumber, finalOrderName, true).subscribe((data: any) => {
          this.occasionForm.get('order_name').enable();
          if (data.name) {
            this.occasionForm.get('order_name').setValue(data.name);
            const inputEvent = new Event('input', { bubbles: true });
            this.orderNameInput.nativeElement.dispatchEvent(inputEvent);
            this.snackBar.open('Please enter Delivery Address', 'OK', { duration: 2000 });
          } else {
            this.snackBar.open('Please enter Customer Name and Delivery Address', 'OK', { duration: 2000 });
            this.occasionForm.get('order_name').reset();
          }

          this.refreshOrder();
        })
        this.updateAddressLIst(this.normalNumber)
      }
    }
  }
  updateAddressLIst(numberType) {
    this.idStore = localStorage.getItem('currentStore')
    this.orderService.filterDeliveryAddress(this.idStore, numberType).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      this.showDiv = true;
      this.addressList = data.data;
      this.previousOrderList = data.order_list;
    });
  }

  getAutoAddress(element) {
    const adddress = element;
    this.occasionDetail.delivery_address = adddress;
    this.retrievedAddress = adddress
    this.openConfirmDialog(this.retrievedAddress);
  }

  afterConfirmAddress(eeeee) {
    const addListAdd = eeeee
    if (addListAdd == true) {
      this.deliveryAddress = this.retrievedAddress;
      this.occasionDetail.delivery_address =
        this.retrievedAddress;
      this.occasionDetail.location_selected = true;
      this.occasionForm.controls.delivery_address.setValue(
        this.retrievedAddress
      );
      this.orderService
        .updateDeliveryFee(
          this.orderWithPayment && this.orderWithPayment.bot_order && this.orderWithPayment.bot_order.id,
          this.orderWithPayment && this.orderWithPayment.store && this.orderWithPayment.store.delivery_charge
        ).pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          if (this.previoudOrderOverview) {
            const payload = {
              "current_order": this.orderWithPayment.bot_order.order_hash,
              "previous_order": this.previoudOrderOverview.bot_order.order_hash,
              "delivery_address": this.deliveryAddress,
              "order_name": this.occasionForm.controls.order_name.value
            }
            this.orderService.posDeliveryReorder(this.store.id, payload).subscribe((data: any) => {
              this.posSummaryToggleService.toggle('OPEN');
              this.refresh.emit(true);
              if (this.store.pos_setting['default_load'] && this.store.pos_setting['default_time'] === 'ASAP') {
                this.tabGroup.selectedIndex = 2;
              } else {
                this.tabGroup.selectedIndex = 1;
              }
            });
          } else {
            if (this.store.pos_setting['default_load'] && this.store.pos_setting['default_time'] === 'ASAP') {
              this.tabGroup.selectedIndex = 2;
            } else {
              this.tabGroup.selectedIndex = 1;
            }
          }

          this.ref.detectChanges();
        });
    }
    else {

      this.locationSelected = false;
      this.deliveryAddress = undefined;
      this.occasionDetail.delivery_address = undefined;
      this.occasionDetail.location_selected = false;
      this.previoudOrderOverview = null;
      this.occasionForm.get('phone_number').reset();
      this.occasionForm.get('order_name').reset();
      this.keyboard.clearInput();
      this.numericKeyboard.clearInput();
    }
    this.showDiv = false;
  }
  updateOrderName() { }

  onResize(event) {
    // this.breakpoint = (event.target.innerWidth <= 400) ? 2 : 5;

    if (event.target.innerWidth <= 400) {
      this.breakpoint = 4;
    } else if (event.target.innerWidth >= 401 && event.target.innerWidth <= 599) {
      this.breakpoint = 4;
    } else if (event.target.innerWidth >= 600) {
      this.breakpoint = 4;
    }
  }

  getPreviousOrder(order_hash) {
    this.orderService.getOrder(order_hash).subscribe(data => {
      this.previoudOrderOverview = Object.assign(new OrdersWithPayment(), data, {
        bot_order: Object.assign(new BotOrder(), data.bot_order, {
          occasion_schedule: moment(data.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate(),
          created_at: moment(data.bot_order.created_at, 'hh:mm A MMM DD, YYYY').toDate(),
          updated_at: moment(data.bot_order.updated_at, 'hh:mm A MMM DD, YYYY').toDate(),
          submitted_at: moment(data.bot_order.submitted_at, 'hh:mm A MMM DD, YYYY').toDate()
        }),
        store: Object.assign(new Store(), data.store),
        items: data.items.map(o => Object.assign(new Order(), o, {
          modifiers: o.modifiers.map(x => Object.assign(new Modifier(), x))
        })),
        payment: OrderHelpers.parsePayment(data.payment)
      })
    });
  }
  clearDeliveryInput() {
    this.previoudOrderOverview = null;
    this.deliveryAddress = undefined;
    this.previousOrderList = null;
    this.addressList = null;
    this.occasionDetail.delivery_address = null;
    this.occasionForm.get('phone_number').reset();
    this.occasionForm.get('order_name').reset();
    this.occasionForm.get('delivery_address').reset();
    this.keyboard.clearInput();
    this.numericKeyboard.clearInput();
  }
  /* KEYBOARD IMPLEMENTATION */
  createVirtualKeyboards() {
    if (!this.keyboardCreated) {
      /* Alphabet Keyboard */
      try {
        this.keyboard = new Keyboard({
          debug: true,
          display: {
            '{backspace}': 'Backspace',
            '{space}': 'Space',
            '{enter}': 'Enter',
            '{shift}': 'Shift'
          },
          theme: "hg-theme-default",
          layoutName: "default",
          layout: this.customLayoutWithoutNumbers,
          inputName: this.inputName,
          onChange: input => this.onChange(input),
          onKeyPress: button => this.onKeyPress(button),
          preventMouseDownDefault: true
        });

        /* NUmber Keyboard */
        this.numericKeyboard = new Keyboard(".myFavouriteClass", {
          theme: "hg-theme-default",
          debug: true,
          display: {
            '{backspace}': 'Backspace',
          },
          layoutName: "default",
          layout: this.customNumberLayout,
          inputName: this.inputNumberName,
          onChange: input => this.onNumberChange(input),
          onKeyPress: button => this.onNumberKeyPress(button),
          preventMouseDownDefault: true,
          maxLength: 10
        });
        this.keyboardCreated = true;
        this.orderService.keyboardValueSet.next(true);
      } catch (error) {
        this.keyboardCreated = false;
      }
    }
  }
  onInputFocus = (event: any) => {
    this.inputName = event.target.id;
    this.keyboard.setOptions({
      inputName: event.target.id
    });
  };
  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value, event.target.id);
    this.onChange(event.target.value);
  };

  onChange = (input: string) => {
    /* PREVIOUS */
    if (this.orderNameInput.nativeElement === document.activeElement) {
      this.value = input;
      this.occasionForm.get('order_name').setValue(input);
    } else if (this.inputDeliveryAddress.nativeElement === document.activeElement) {
      this.inputDeliveryAddress.nativeElement.value = input;
      const inputEvent = new Event('input', { bubbles: true });
      this.inputDeliveryAddress.nativeElement.dispatchEvent(inputEvent);
    }
    /* NEW */
    // const a = document.getElementById(this.inputName);
    // for (var i = 0; i < a.attributes.length; i++) {
    //   var attribute = a.attributes[i];
    //   if (attribute.value == "order_name") {
    //     this.value = input;
    //     this.occasionForm.get('order_name').setValue(input);
    //     this.orderNameInput.nativeElement.focus();
    //   } else if (attribute.value == "delivery_address") {
    //     this.inputDeliveryAddress.nativeElement.value = input;
    //     const inputEvent = new Event('input', { bubbles: true });
    //     this.inputDeliveryAddress.nativeElement.dispatchEvent(inputEvent);
    //     this.inputDeliveryAddress.nativeElement.focus();
    //   }
    // }
  };
  onKeyPress = (button: string) => {
    const buttonElement = document.querySelector(`[data-skbtn='${button}']`);
    buttonElement.addEventListener('click', event => {
      event.preventDefault();
    });
    if (button === "{shift}" || button === "{lock}") this.handleShift();

    if (button == "{enter}") {
      if (this.occasionForm.controls.phone_number.valid && this.occasionForm.controls.order_name.valid) {
        this.getAutoAddress(this.occasionForm.get('delivery_address')?.value);
      }
    }
  };
  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";
    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };
  preventInputBlur(event: Event) {
    // Prevent the default behavior that causes the input field to lose focus
    event.preventDefault();
  }
  /* Number Keyboard */
  onInputNumberFocus = (event: any) => {
    this.inputNumberName = event.target.id;
    this.numericKeyboard.setOptions({
      inputName: event.target.id
    });
  };
  onNumberChange = (input: string) => {
    this.numberKeyData = input.replace(/^(\+1)(\d{0,3})(\d{0,3})(\d{0,4})/, '($2) $3-$4');
    this.occasionForm.get('phone_number').setValue(input);
    this.updatePhoneNumber(this.numberKeyData, this.value);
  };
  onNumberKeyPress = (button: string) => {
    const buttonElement = document.querySelector(`[data-skbtn='${button}']`);
    buttonElement.addEventListener('click', event => {
      event.preventDefault();
    });
    if (button === "{shift}" || button === "{lock}") this.handleNumberShift();
  };
  onNumberInputChange = (event: any) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    this.numericKeyboard.setInput(numericValue, event.target.id);
    this.onNumberChange(numericValue);
  };
  handleNumberShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";
    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };

  alertPopup(name, msg) {
    const dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      if (result) {
        if (name == 'format_address') {
          this.locationSelected = false;
          this.deliveryAddress = undefined;
          this.occasionDetail.delivery_address = undefined;
          this.occasionDetail.location_selected = false;
          this.deliveryAddressInput.nativeElement.focus();
        }
      }
    });
  }

  choiceOccsionUpdate() {
    const payload = this.occasionForm.value;
    this.orderService
      .updateOccasionSchedule(this.orderHash, payload).pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.refresh.emit(true);
      });
  }

  calculateDivHeight(): void {
    // Calculate the height based on the number of orders or any other criteria
    const numOfOrders = this.orders.length;
    // Example: Calculate the height as 100px per order + some extra padding
    this.divHeight = numOfOrders * 100 + 'px';
  }

  checkManageDrawerPer() {
    if (this.permission.hasPermission('POS_FUNCTIONS', 'MANAGE_DRAWER')) {
      this.manageDrawer();
    } else {
      this.userPinPermission('cash_drawer');
    }
  }

  manageDrawer() {
    const userId = localStorage.getItem('pinUser');
    const terminal = localStorage.getItem('terminalId');
    const selectedTerminalName = localStorage.getItem('selectedTerminalName');
    this.posService.getManageDrawerStatus(this.store.id, terminal, userId).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      const popupData = {
        'store_id': this.store.id,
        'terminal_id': terminal,
        'terminal_name': selectedTerminalName,
        'user_id': userId,
        'denomination_detail': data,
        'status': true,
        'valid_user': this.validUserData
      }
      this.openCashDrawerPopup(popupData);
    },
      (error: any) => {
        const popupData = {
          'store_id': this.store.id,
          'terminal_id': terminal,
          'terminal_name': selectedTerminalName,
          'user_id': userId,
          'status': false
        }
        this.openCashDrawerPopup(popupData);
      });

  }

  openCashDrawerPopup(e) {
    this.openDrawer();
    const dialogRef = this.dialog.open(CashDrawerManagementComponent, {
      width: '94vw',
      maxWidth: '100vw',
      data: e
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // if (result) {
      //   this.dialogRef.close();
      // }
    });
  }

  startScanningItem() {
    const isMobile = window.innerWidth <= 470;
    const dialogWidth = isMobile ? '90vw' : '41vw';
    const dialogHeight = isMobile ? '40.8vh' : '61.1vh';
    this.posSummaryToggleService.toggle('OPEN');
    const dialogRef = this.dialog.open(BarCodeScannerComponent, {
      width: dialogWidth,
      height: dialogHeight,
      maxHeight: '70vh',
      position: !isMobile ? { right: '80px' } : { bottom: '5px' },
      disableClose: true
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      // Handle dialog close actions
    });
  }

  addItemToTheBasket(itemCode: string) {
    let cuObs = null;
    let botOrder;
    const orderHash = localStorage.getItem('order_hash');
    this.orderService.getOrderByHash(orderHash).pipe(takeUntil(this.unsubscribe)).subscribe((resultOrder) => {
      botOrder = resultOrder;
      cuObs = this.orderService.addItemId(orderHash, itemCode, {
        modifiers: [],
        quantity: 1,
        special_request: null,
      });
      cuObs.pipe(takeUntil(this.unsubscribe)).subscribe(
        (retV) => {
          this.refreshService.refreshModule("POS_ORDER_SUMMARY");
        },
        (err) => {
          this.snackBar.open('Item not found', 'OK', { duration: 2000 })
        }
      );
    });
  }


  /* DISPLAYING TABLES ON CANVAS */

  createFloorCanvas() {
    console.log('create canvas', )
    if (this.canvasCreated) {
      return; // Don't create a new canvas if it already exists
    }
    this.floorPlanService.getFlooPlanlist(Number(this.store.id)).subscribe((data: any) => {
      if (data) {
        this.floorPlanData = data;
        if (this.floorPlanData) {
          setTimeout(() => {
            this.initializeKonvaForAllFloors();
          }, 200);
        }
      }
    })
  }

  initializeKonvaForAllFloors(): void {
    const isSingleFloor = this.floorPlanData.length === 1;
    this.canvasCreated = true;
    const gridSize = 10; // You can adjust this value to define grid cell size
  
    this.floorPlanData.forEach((floorData, index) => {
      if (!floorData.tables || floorData.tables.length === 0) {
        return;
      }
  
      const floorWidth = floorData.width;
      const floorHeight = floorData.height;
  
      const floorWrapperDiv = document.createElement('div');
      floorWrapperDiv.className = isSingleFloor 
        ? 'floor-wrapper flex flex-col items-center justify-center mx-auto' 
        : 'floor-wrapper flex flex-col items-center';
  
      const floorNameHeading = document.createElement('h2');
      floorNameHeading.className = 'text-center text-lg font-bold';
      floorNameHeading.innerText = floorData.floor_name || `Floor ${index + 1}`;
  
      const floorDiv = document.createElement('div');
      floorDiv.className = 'floor-canvas border border-gray-300';
      floorDiv.style.width = `${floorWidth}px`;
      floorDiv.style.height = `${floorHeight}px`;
  
      floorWrapperDiv.appendChild(floorNameHeading);
      floorWrapperDiv.appendChild(floorDiv);
  
      this.stageContainer.nativeElement.appendChild(floorWrapperDiv);
  
      const stage = new Konva.Stage({
        container: floorDiv,
        width: floorWidth,
        height: floorHeight,
      });
  
      this.floorPlanService.floorCreatedUpdate.next(true);
      const layer = new Konva.Layer();
      stage.add(layer);
  
      const transformer = new Konva.Transformer();
      layer.add(transformer);
  
      // Optional: Draw the grid lines on the canvas
      this.drawGrid(layer, floorWidth, floorHeight, gridSize);
  
      this.renderObjects(layer, floorData.objects, gridSize);
      this.renderTables(layer, floorData.tables, gridSize);
    });
  }
  
  // Optional method to draw grid lines
  drawGrid(layer: Konva.Layer, width: number, height: number, gridSize: number): Konva.Group {
    const gridGroup = new Konva.Group({
      visible: false, // Hide the grid by default
    });
  
    for (let i = 0; i < width / gridSize; i++) {
      gridGroup.add(new Konva.Line({
        points: [i * gridSize, 0, i * gridSize, height],
        stroke: '#ddd',
        strokeWidth: 1,
      }));
    }
  
    for (let j = 0; j < height / gridSize; j++) {
      gridGroup.add(new Konva.Line({
        points: [0, j * gridSize, width, j * gridSize],
        stroke: '#ddd',
        strokeWidth: 1,
      }));
    }
  
    layer.add(gridGroup); // Add the grid group to the layer
    layer.draw();
    return gridGroup; // Return the group in case needed later
  }
  
  renderObjects(layer: Konva.Layer, objects: any, gridSize: number): void {
    const canvasWidth = layer.getStage().width();
    const canvasHeight = layer.getStage().height();
  
    objects.forEach((obj) => {
      const { width, height, object_name, x_position, y_position, color } = obj;
      const fillColor = color || '#ccc';
  
      // Snap to grid
      let xPos = Math.round((x_position || 0) / gridSize) * gridSize;
      let yPos = Math.round((y_position || 0) / gridSize) * gridSize;
  
      // Ensure objects don't go outside canvas bounds
      xPos = Math.max(0, Math.min(xPos, canvasWidth - width));
      yPos = Math.max(0, Math.min(yPos, canvasHeight - height));
  
      const shape = new Konva.Rect({
        x: xPos,
        y: yPos,
        width: width,
        height: height,
        fill: fillColor,
        stroke: 'black',
        strokeWidth: 2,
      });
  
      const text = new Konva.Text({
        text: `${object_name}`,
        fontSize: 14,
        fontFamily: 'Calibri',
        fill: 'black',
        width: width,
        align: 'center',
      });
  
      text.x(xPos + (width / 2) - (text.width() / 2));
      text.y(yPos + (height / 2) - (text.height() / 2));
  
      layer.add(shape);
      layer.add(text);
    });
  
    layer.draw();
  }
  
  renderTables(layer: Konva.Layer, tables: any, gridSize: number): void {
    const canvasWidth = layer.getStage().width();
    const canvasHeight = layer.getStage().height();
  
    tables.forEach((table) => {
      const {
        width,
        height,
        shape: tableShape,
        table_name,
        seating_capacity,
        x_position,
        y_position,
        color,
        occupied
      } = table;
  
      const fillColor = occupied ? '#ff6666' : (color || '#ccc');
  
      // Snap to grid
      let xPos = Math.round((x_position || 0) / gridSize) * gridSize;
      let yPos = Math.round((y_position || 0) / gridSize) * gridSize;
  
      xPos = Math.max(0, Math.min(xPos, canvasWidth - width));
      yPos = Math.max(0, Math.min(yPos, canvasHeight - height));
  
      let shape;
  
      if (tableShape === 'square' || tableShape === 'rectangle') {
        shape = new Konva.Rect({
          x: xPos,
          y: yPos,
          width: width,
          height: height,
          fill: fillColor,
          stroke: 'black',
          strokeWidth: 2,
        });
      } else if (tableShape === 'round') {
        shape = new Konva.Circle({
          x: xPos + width / 2,
          y: yPos + width / 2,
          radius: width / 2,
          fill: fillColor,
          stroke: 'black',
          strokeWidth: 2,
        });
      }
  
      const text = new Konva.Text({
        text: `${table_name} (${seating_capacity})`,
        fontSize: 14,
        fontFamily: 'Calibri',
        fill: 'black',
        width: width,
        align: 'center',
      });
  
      if (tableShape === 'round') {
        text.x(xPos + (width / 2) - (text.width() / 2));
        text.y(yPos + (width / 2) - (text.height() / 2));
      } else {
        text.x(xPos + (width / 2) - (text.width() / 2));
        text.y(yPos + (height / 2) - (text.height() / 2));
      }
  
      shape.on('click touchstart', () => {
        if (!occupied) {
          this.onTableSelect(table_name);
        }
      });
  
      text.on('click touchstart', () => {
        if (!occupied) {
          this.onTableSelect(table_name);
        }
      });
  
      layer.add(shape);
      layer.add(text);
    });
  
    layer.draw();
  }

}
