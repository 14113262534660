import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';

@Component({
  selector: 'app-all-store-edit-master-category-dialog',
  templateUrl: './all-store-edit-master-category-dialog.component.html',
  styleUrls: ['./all-store-edit-master-category-dialog.component.scss']
})
export class AllStoreEditMasterCategoryDialogComponent implements OnInit {
  isLoading = false;
  submitted = false;
  editMasterCategoryForm: UntypedFormGroup;
  getId: any;
  newScreen: boolean;
  hideCategory: boolean;
  storeId: any;
  category_list_data: any;
  searchEmoji;
  // categorys = new FormControl('');
  showSelete: any = [];
  showModifierGroupSelete: any = [];
  emojiLists: any;
  subcategory_list_data: any;
  searchModifierGroup:any;
  searchSubCategory:any;
  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<AllStoreEditMasterCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log("@@@@@@@", data);
  }


  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    // this.categoryList();
    this.subcategoryListAll();
    this.ModifierGroupListAll();

    this.cat()
    this.ModifierGroup()
    this.getId = this.data.id;
    this.newScreen = this.data.new_screen;
    this.hideCategory = this.data.hide_mastercategory;
    this.editMasterCategoryForm = this.fb.group({
      name: [this.data.name],
      priority: [this.data.priority],
      new_screen: [this.data.new_screen],
      hide_mastercategory: [this.hideCategory],
      subcategorys: [this.showSelete],
      font_color: [this.data.font_color],
      emoji: [this.data.emoji],
      modifiergroups: [this.showModifierGroupSelete],

      store: [this.storeId],
      emoji_search: [],
      modifierGroup_search:[],
      SubCategory_search:[],
      font_size: [this.data.font_size],
      alignment: [this.data.alignment],
      modifiergroups_subcat_alignment: [this.data.modifiergroups_subcat_alignment],
      single_pricing_alignment: [this.data.single_pricing_alignment],
      price_format: [this.data.price_format],
      modifiergroups_subcat_font_size: [this.data.modifiergroups_subcat_font_size],
      modifiergroups_subcat_font_color: [this.data.modifiergroups_subcat_font_color],
      modifiergroups_subcat_background_color: [this.data.modifiergroups_subcat_background_color]
    })
    this.emojiList();
    console.log("111111111", this.data);
    

  }

  onNoClick() {
    this.dialogRef.close();
  }

  cat() {

    for (let j = 0; j < this.data.categorys.length; j++) {
      const element = this.data.categorys[j];
      this.showSelete.push(element)
      console.log('sssssssss', this.showSelete);
    }
  }
  ModifierGroup() {

    for (let j = 0; j < this.data.modifiergroups.length; j++) {
      const element = this.data.modifiergroups[j];
      this.showModifierGroupSelete.push(element)
      console.log('sssssssss', this.showModifierGroupSelete);
    }
  }


  subcategoryListAll() {
    this.digitalDisplayService
      .SubCategoriesOtionListMaster(this.storeId)
      .subscribe((data) => {
        this.showSelete = data.data;

      });
  }
  ModifierGroupListAll() {
    this.digitalDisplayService
      .ModifierGroupOtionListMaster(this.storeId)
      .subscribe((data) => {
        this.showModifierGroupSelete = data.data;
        console.log("11111111111", this.showSelete);

      });
  }
  newScreenCheck(a) {
    this.editMasterCategoryForm.value.new_screen = a.checked;
  }
  hideCategoryCheck(a) {
    this.editMasterCategoryForm.value.hide_mastercategory = a.checked;
  }
  editMasterCategoryFormSubmit() {
    this.submitted = true;
    if (this.editMasterCategoryForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.digitalDisplayService.editMasterCategory(this.getId, this.editMasterCategoryForm.value).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
      this.isLoading = false;
      this.dialogRef.close(resData);
    })
  }
  // categoryList() {
  //   this.digitalDisplayService.SubCategoriesOtionListMaster(this.storeId).subscribe(data => {
  //     this.category_list_data = data.data
  //   })
  // }

  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emojiLists = data.data;

    });
  }
}
