<div class="login-body">
    <mat-toolbar class="mat-elevation-z7" style="z-index:2; background-color: white">
        <a [routerLink]="['/home/dashboard']" routerLinkActive="is-active">
          <picture height="50px">
            <source srcset="../../assets/logo_sm.jpg" media="(min-width: 300px) and (max-width: 576px)">
            <source srcset="../../assets/logo.jpg" media="(min-width: 576px)">
            <img srcset="../../assets/logo.jpg" alt="Labrador AI" height="50px">
          </picture>
        </a>
      </mat-toolbar>
    <div class="login-form">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group" *ngIf="!submitted">
          <input type="text" formControlName="email" class="custom-input" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                placeholder="Email">
        </div>
        <div *ngIf="submitted" style="text-align: justify; padding:10px">
          <p>We've emailed you instructions for setting your password, if an account exists with the email you entered. You should receive them shortly.</p>
          <p>If you don't receive an email, please make sure you've entered the address you registered with, and check your spam folder.</p>
        </div>
        <div *ngIf="error" class="alert alert-danger">Invalid Email</div>


        <div class="form-group" style="padding:10px;">
          <button type="submit" color="primary" *ngIf="!submitted">Submit</button>
          <a mat-button href='/login/'>Back</a>
        </div>

  </form>
  </div>
